import { actions } from './../globals/services/authentication/authentication-actions.js';

const initialState = {
    isAdmin: false,
    hasSubmitted: false,
    failedSubmit: false
};

const adminReducer = (state, action) => {
    if (typeof state === 'undefined') {
        return initialState;
    }

    let newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case actions.LOGIN_ADMIN_INIT:
            newState.hasSubmitted = true;
            return newState;
        case actions.LOGIN_ADMIN_SUCCESS:
            newState.isAdmin = true;
            newState.failedSubmit = false;
            return newState;
        case actions.LOGIN_ADMIN_ERROR:
            newState.failedSubmit = true;
            return newState;
        default:
            return newState;
    }
};

export default adminReducer;