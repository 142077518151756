import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import PropTypes from 'prop-types';

import './EmailAll.scss';

import Modal from '../modal/Modal.jsx';
import { getRecipientEmails }
    from '../../../manage-meeting/manage-meeting-reducer.js';
import { updateMeetingEmailList }
    from '../../../manage-meeting/manage-meeting-actions.js';
import EmailAllOptions from './EmailAllOptions.jsx';

class EmailAll extends React.Component {
    constructor(props) {
        super(props);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getPromptContent = this.getPromptContent.bind(this);
        this.createAppleLink = this.createAppleLink.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.getEmailCheckboxes = this.getEmailCheckboxes.bind(this);

        this.state = {
            isOpen: false,
            emailRecipients: "Contact", //defaulting to primary contact
            isParentChecked: true,
            isStudentChecked: false,
        };
    }

    openModal() {
        if (!this.state.isOpen) {
            this.setState({
                isOpen: true
            });
        }
    }

    closeModal() {
        if (this.state.isOpen) {
            this.setState({
                isOpen: false
            });
        }
    }

    createAppleLink() {
        let link = 'mailto:';
        let recipients = this.props.contactEmails;
        link += recipients.join(',');
        return link;
    }

    getPromptContent() {
        if (window.innerWidth <= 576) {
            return (
                <a
                    className={this.props.className}
                    href={this.createAppleLink()}
                >
                    {this.props.children}
                </a>
            );

        } else {
            return (
                <a
                    className={this.props.className}
                    onClick={this.openModal}
                >
                    {this.props.children}
                </a>
            );
        }
    }

    handleCheckboxChange(e) {
        let { value } = e.currentTarget;
        let actionCallback = () => {
            return this.props.updateMeetingEmailList(
                this.state.isParentChecked,
                this.state.isStudentChecked
            );
        }

        this.setState({
            [value]: !this.state[value], actionCallback
        });
    }

    getEmailCheckboxes() {
        return (
            <div className="emailAll-checkboxes">
                <input type="checkbox"
                    name="parentCheckbox"
                    id="parentCheckbox"
                    className="emailAll-checkbox"
                    onChange={this.handleCheckboxChange}
                    checked={this.state.isParentChecked}
                    value="isParentChecked"
                />
                <label
                    htmlFor='parentCheckbox'
                    className={
                        'emailAll-checkboxLabel'
                    }>
                    {this.props.content.parentEmail}
                </label>
                <input type="checkbox"
                    name="studentCheckbox"
                    id="studentCheckbox"
                    className="emailAll-checkbox"
                    onChange={this.handleCheckboxChange}
                    checked={this.state.isStudentChecked}
                    value="isStudentChecked"
                />
                <label
                    htmlFor='studentCheckbox'
                    className={
                        'emailAll-checkboxLabel'
                    }>
                    {this.props.content.studentEmail}
                </label>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                {this.getPromptContent()}

                <Modal
                    isOpen={this.state.isOpen}
                    shouldCloseOnOverlayClick={true}
                    timeout={300}
                    classNames={"rsvp-emailAll-modal"}
                    onRequestClose={this.closeModal}
                    modalID="email"
                    header={this.props.content.emailTitle}
                >
                    {
                        this.props.content.displayValues.hasParentEmails &&
                        this.props.content.displayValues.hasStudentEmails &&
                        this.getEmailCheckboxes()
                    }
                    <EmailAllOptions
                        parentFlag={this.state.isParentChecked}
                        studentFlag={this.state.isStudentChecked} />
                </Modal>
            </React.Fragment>
        );
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.globals.emailAll'),
            contactEmails: getRecipientEmails(state, true, false),
            activePanel: objectPath.get(state, 'manageMeeting.activePanel'),
        };
    }

}
const mapDispatchToProps = {
    updateMeetingEmailList
}

EmailAll.propTypes = {
    content: PropTypes.shape({
        emailTitle: PropTypes.string.isRequired,
        parentEmail: PropTypes.string.isRequired,
        studentEmail: PropTypes.string.isRequired,
        displayValues: PropTypes.shape({
            hasParentEmails: PropTypes.bool.isRequired,
            hasStudentEmails: PropTypes.bool.isRequired
        })
    }),
    className: PropTypes.string,
    contactEmails: PropTypes.arrayOf(PropTypes.string),
    activePanel: PropTypes.string,
};

export default connect(EmailAll.mapStateToProps, mapDispatchToProps)(EmailAll);