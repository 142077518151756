import React from 'react';
import objectPath from 'object-path';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import arrowSvg from '../../../../static/img/arrow_000.svg';

import { getSecureSiteOrigin } from '../../utils/url-util.js';

class BackToOverview extends React.Component {
    constructor(props) {
        super(props);

        this.getBackURL = this.getBackURL.bind(this);
    }

    getBackURL(){
        let secureTripUrl;

        if (this.props.hasSecureSiteIntegration){
            secureTripUrl = getSecureSiteOrigin() + '/personal/my-tours/' + this.props.groupTripId + '/overview';
        }

        return secureTripUrl;
    }

    render() {
        const backButtonStyle = this.props.hasSecureSiteIntegration ? 'visible' : 'hidden';
        const backToTourContent = this.props.windowWidth >= 400 ? this.props.content.backLinkText : <strong> {this.props.content.backLinkTextMobile} </strong>;

        return (
            <a
                className="actionBar-backToTour"
                href={this.getBackURL() }
                style={{visibility: backButtonStyle}}
            >
                <img src={arrowSvg} />&nbsp;
                { backToTourContent }
            </a>
        );
    }

    static mapStateToProps(state) {
        return {
            hasSecureSiteIntegration: objectPath.get(state, 'product.hasSecureSiteIntegration'),
            windowWidth: state.window.width,
            content: objectPath.get(state, 'product.content.globals.backToOverview'),
        };
    }
}

BackToOverview.propTypes = {
    groupTripId: PropTypes.string,
    hasSecureSiteIntegration: PropTypes.bool,
    content: PropTypes.shape({
        backLinkText: PropTypes.string.isRequired,
        backLinkTextMobile: PropTypes.string.isRequired
    })
};

export default connect(BackToOverview.mapStateToProps)(BackToOverview);