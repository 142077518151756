import deepMerge from 'deepmerge';

import defaultContent from './default.json';
import etusCustomContent from './etus.json';
import eausCustomContent from './eaus.json';
import etcaCustomContent from './etca.json';
import etfcCustomContent from './etfc.json';
import cstCustomContent from './cst.json';
import gstCustomContent from './gst.json';

import {actions as productActions} from './product-actions.js';
import {actions as publicInviteActions} from '../../../public-invite/public-invite-actions';
import {actions as manageMeetingActions} from '../../../manage-meeting/manage-meeting-actions';

const initialState = {
    siteCode: 'ETUS',
    content: defaultContent,
    locale: 'en-us',
    languageCode: 'en',
    hasLoadedContent: false,
    hasSecureSiteIntegration: false
};

const customContentMap = {
    ETUS: etusCustomContent,
    EAUS: eausCustomContent,
    ETCA: etcaCustomContent,
    ETFC: etfcCustomContent,
    CST: cstCustomContent,
    GST: gstCustomContent
};

const ALLOWED_SITE_CODES = ['ETUS', 'EAUS', 'ETCA', 'ETFC', 'CST', 'GST'];

const productReducer = (state, action) => {
    if (typeof state === 'undefined') {
        return initialState;
    }
    
    let newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case publicInviteActions.LOAD_PUBLIC_MEETING_SUCCESS:
            newState.siteCode = action.publicMeeting.siteCode || initialState.siteCode;
            newState.content = buildCustomContent(state, newState.siteCode);
            newState.locale = newState.content.appSettings.locale;
            newState.languageCode = newState.content.appSettings.languageCode;
            newState.hasLoadedContent = true;
            return newState;

        case manageMeetingActions.LOAD_EDITABLE_MEETING_SUCCESS:
            newState.siteCode = action.editableMeeting.siteCode || initialState.siteCode;
            newState.content = buildCustomContent(state, newState.siteCode);
            newState.locale = newState.content.appSettings.locale;
            newState.languageCode = newState.content.appSettings.languageCode;
            newState.hasLoadedContent = true;
            return newState;

        case productActions.LOAD_CUSTOM_CONTENT: {
            newState.siteCode = action.siteCode || 'ETUS';
            newState.hasSecureSiteIntegration = hasSecureSiteIntegration(newState.siteCode);
            newState.content = buildCustomContent(state, newState.siteCode);
            newState.locale = newState.content.appSettings.locale;
            newState.languageCode = newState.content.appSettings.languageCode;
            newState.hasLoadedContent = true;
            return newState;
        }

        default:
            return state;
    }
};

const buildCustomContent = (state, siteCode) => {
    let productContentOverrides, customContent;

    // Skip if the requested content is already loaded
    if (!state.hasLoadedContent || state.siteCode !== siteCode) {
        productContentOverrides = customContentMap[siteCode];
        const overwriteMerge = (destinationArray, sourceArray) => sourceArray;

        customContent = productContentOverrides ? deepMerge(defaultContent, productContentOverrides,
            { arrayMerge: overwriteMerge }) : defaultContent;
    } else {
        customContent = state.content;
    }

    return customContent || defaultContent;
};

const hasSecureSiteIntegration = (siteCode) => {
    return ALLOWED_SITE_CODES.indexOf(siteCode) > -1;
};

export default productReducer;