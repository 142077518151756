import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { createFocusTrap } from 'focus-trap';
import { CSSTransition } from 'react-transition-group';
import { connect } from  'react-redux';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import xSvg from '../../../../static/img/x_B0B0A1.svg';
import './Modal.scss';

const modalRoot = document.getElementById('RsvpModalRoot');

class Modal extends React.Component {
    constructor(props) {
        super(props);

        this.onModalEnter = this.onModalEnter.bind(this);
        this.onModalExit = this.onModalExit.bind(this);
        this.setTopOffset = this.setTopOffset.bind(this);
        this.getModalHeaderID = this.getModalHeaderID.bind(this);
        this.getModalDescriptionID = this.getModalDescriptionID.bind(this);
        this.getModalCoppaDescriptionID = this.getModalCoppaDescriptionID.bind(this);
        this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
        this.handleOverlayClick = this.handleOverlayClick.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.destroy = this.destroy.bind(this);

        this.state = {
            topOffset: 0,
            focusTrap: null
        };
    }

    UNSAFE_componentWillUpdate(nextProps) {
        // adjust top offset
        if (nextProps.windowHeight !== this.props.windowHeight) {
            this.setTopOffset();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.children !== this.props.children){
            this.setTopOffset();
        }
    }

    componentWillUnmount() {
        this.destroy();
    }

    onModalEnter() {
        this.setTopOffset();
        disableBodyScroll(this.overlayEl, {reserveScrollBarGap: true});

        let createTrapOptions = {};

        if (this.props.initialFocusSelector) {
            createTrapOptions.initialFocus = this.props.initialFocusSelector;
        }

        if (!this.props.disableFocusLock){
            let focusTrap = createFocusTrap(this.overlayEl, createTrapOptions);

            this.setState({
                focusTrap
            });

            focusTrap.activate();
        }
    }

    onModalExit() {
        this.destroy();
    }

    setTopOffset() {
        if (this.modalElement) {
            // design requires modal to be higher than vertical middle, so determine
            // the necessary offset from middle
            let spaceAroundModal,
                topOffset = 0,
                windowHeight = this.props.windowHeight,
                modalHeight = this.modalElement.clientHeight || 0;

            spaceAroundModal = windowHeight - modalHeight;

            if (spaceAroundModal > 0) {
                topOffset = spaceAroundModal * .35;
            }

            this.setState({
                topOffset
            });
        }
    }

    getModalHeaderID() {
        return 'modalHeader-' + this.props.modalID;
    }

    getModalDescriptionID() {
        return 'modalDescription-' + this.props.modalID;
    }

    getModalCoppaDescriptionID() {
        return 'modalCoppaDescription-' + this.props.modalID;
    }

    handleCloseButtonClick(event) {
        //eslint-disable-next-line
        dataLayer.push({'modalClose': this.props.modalID, 'event':'modalClose'});

        this.props.onRequestClose(event);
    }

    handleOverlayClick(event) {
        //eslint-disable-next-line
        dataLayer.push({'modalClose': 'overlayClick', 'event': 'modalClose'});

        //eslint-disable-next-line
        dataLayer.push({'modalClose': this.props.modalID, 'event':'modalClose'});

        if (this.props.shouldCloseOnOverlayClick) {
            this.props.onRequestClose(event);
        }
    }

    handleKeyDown(event) {
        if (event.keyCode === 27 && this.props.shouldCloseOnOverlayClick) {
            this.handleCloseButtonClick(event);
        }
    }

    destroy() {
        clearAllBodyScrollLocks();

        if (this.state.focusTrap !== null) {
            this.state.focusTrap.deactivate();
        }
    }

    render() {
        const renderModal = () => { return (
            <CSSTransition
                in={this.props.isOpen}
                timeout={this.props.timeout || 300}
                classNames={'rsvp-modal'}
                onEnter={this.onModalEnter}
                onExit={this.onModalExit}
                unmountOnExit
            >
                {() =>
                    <div
                        className="rsvp-modal-overlay"
                        onClick={this.handleOverlayClick}
                        ref={(overlayEl) => this.overlayEl = overlayEl}
                    >
                        <div
                            id={'rsvpModal-' + this.props.modalID}
                            className={
                                'rsvp-modal' +
                                (this.props.classNames ? (' ' + this.props.classNames) : '')
                            }
                            style={{
                                top: this.state.topOffset
                            }}
                            onClick={(event)=>event.stopPropagation()}
                            ref={(modal) => {this.modalElement = modal}}
                            role="dialog"
                            aria-modal="true"
                            aria-labelledby={this.getModalHeaderID()}
                            aria-describedby={(this.props.description ? this.getModalDescriptionID() : '')}
                            onKeyDown={this.handleKeyDown}
                        >
                            <header>
                                <h2 id={this.getModalHeaderID()}>{this.props.header}</h2>
                                {this.props.description &&
                                    <p id={this.getModalDescriptionID()}>{this.props.description}</p>
                                }
                                {this.props.coppaDescription &&
                                    <p id={this.getModalCoppaDescriptionID()}>{this.props.coppaDescription}</p>}
                            </header>
                            {this.props.children}
                            {
                                !this.props.shouldHideXButton &&
                                <button
                                    className="rsvp-modal-closeButton"
                                    onClick={this.handleCloseButtonClick}
                                    type="button"
                                    tabIndex="-1"
                                >
                                    <img src={xSvg} alt="close" />
                                </button>
                            }
                        </div>
                    </div>
                }
            </CSSTransition>
        );};

        return ReactDOM.createPortal(
            renderModal(),
            modalRoot,
        );
    }

    static mapStateToProps(state) {
        return {
            windowHeight: state.window.height
        }
    }
}

export default connect(Modal.mapStateToProps)(Modal);

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    initialFocusSelector: PropTypes.string,
    shouldCloseOnOverlayClick: PropTypes.bool,
    shouldHideXButton: PropTypes.bool,
    timeout: PropTypes.number,
    classNames: PropTypes.string,
    onRequestClose: PropTypes.func,
    windowHeight: PropTypes.number.isRequired,
    modalID: PropTypes.string.isRequired,
    disableFocusLock: PropTypes.bool
};