import React from 'react';
import TagManager from 'react-gtm-module';

export default class GoogleTagManager extends React.Component {
    componentDidMount() {
        let tagManagerArgs = {
            gtmId: 'GTM-5HP4J38'
        };

        TagManager.initialize(tagManagerArgs);
    }

    render() {
        return null;
    }
}