import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Linkify from 'react-linkify';
import emailSvg from '../../static/img/email_fff.svg';
import exclamationSvg from '../../static/img/exclamation_logo.svg';
import './PublicInvite.scss';

import FatalErrorLayout from './../layouts/FatalErrorLayout.jsx';
import MainLayout from './../layouts/MainLayout.jsx';
import MeetingImage from '../globals/components/meeting-image/MeetingImage.jsx';
import MainPanel from '../globals/components/main-panel/MainPanel.jsx';
import CollectionForm from './CollectionForm.jsx';
import { loadPublicMeeting, setIsAttending, updateViewsOnMeeting } from './public-invite-actions.js';
import { getMeetingImageUrl } from '../globals/utils/url-util.js';
import ContactOrganizer from '../globals/components/contact-organizer/ContactOrganizer.jsx';
import DateLocation from '../globals/components/date-location/DateLocation.jsx';
import SoftBranding from '../globals/components/soft-branding/SoftBranding.jsx';
import PastToursWithinOrg from '../globals/components/past-tours/PastToursWithinOrg.jsx';

class PublicInvite extends React.Component {
    constructor(props) {
        super(props);

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getMeetingMetaDescription = this.getMeetingMetaDescription.bind(this);
        this.handleAttendingClick = this.handleAttendingClick.bind(this);
        this.handleInterestedClick = this.handleInterestedClick.bind(this);
        this.handleInactiveClick = this.handleInactiveClick.bind(this);
        this.updateSubmit = this.updateSubmit.bind(this);

        this.state = {
            hasFatalError: false,
            isCollectionModalOpen: false,
            didSubmit: false,
            isLoading: true
        };
    }

    UNSAFE_componentWillMount() {
        let loadMeetingAction = this.props.dispatch(
            loadPublicMeeting(objectPath.get(this.props, 'match.params.meeting_id'))
        );

        return loadMeetingAction.response
            .then(() => {
                this.props.dispatch(
                    updateViewsOnMeeting(null, null,
                        objectPath.get(this.props, 'publicMeeting.meetingID'))
                );

                this.setState({
                    isLoading: false
                });
            })
            .catch((error) => {
                this.setState({
                    hasFatalError: true,
                    fatalError: error,
                    isLoading: false
                });
            });
    }

    openModal() {
        if (!this.state.isCollectionModalOpen) {
            this.setState({
                isCollectionModalOpen: true
            });
        }
    }

    closeModal() {
        if (this.state.isCollectionModalOpen) {
            this.setState({
                isCollectionModalOpen: false
            });
        }
    }

    getMeetingMetaDescription() {
        return this.props.content.hostedBy + ' ' + this.props.publicMeeting.organizer;
    }

    handleAttendingClick() {
        this.props.dispatch(setIsAttending(true));

        this.openModal();
    }

    handleInterestedClick() {
        this.props.dispatch(setIsAttending(false));

        this.openModal();
    }

    handleInactiveClick() {
        this.props.dispatch(setIsAttending(false));

        this.openModal();
    }

    updateSubmit() {
        this.setState({
            didSubmit: true
        })
    }

    render() {
        if (!this.state.isLoading) {
            return this.renderInvite();
        } else {
            return (
                <Helmet>
                    <title>{this.props.content.pageTitle}</title>
                    <meta property="og:title" content={this.props.content.pageTitle} />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:description" content="" />
                    <meta property="og:image" content={getMeetingImageUrl()} />
                </Helmet>
            );
        }
    }

    renderInvite() {
        const isPast = objectPath.get(this.props, 'publicMeeting.status.isPast', false);

        return this.state.hasFatalError ? (
            <FatalErrorLayout fatalError={this.state.fatalError}>
                <Helmet>
                    <title>{this.props.content.pageTitle}</title>
                    <meta property="og:title" content={this.props.content.pageTitle} />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:description" content="" />
                    <meta property="og:image" content={getMeetingImageUrl()} />
                </Helmet>
                <h1>{this.props.content.errorLoadingMeeting}</h1>
                <p>{this.props.content.errorLoadingMeetingHelp}</p>
            </FatalErrorLayout>
        ) : isPast ? (
            <MainLayout className="publicInvite">
                <Helmet>
                    <title>{this.props.content.pageTitle}</title>
                    <meta property="og:title" content={this.props.content.pageTitle} />
                    <meta
                        property="og:url"
                        content={this.props.publicMeeting.publicInviteUrl}
                    />
                    <meta property="og:description" content="" />
                    <meta property="og:image" content={getMeetingImageUrl()} />
                </Helmet>

                <article>
                    <MeetingImage isPast={true} />

                    <div className="white-background">
                        <MainPanel
                            className={
                                this.props.publicMeeting.status.isActive ||
                                    this.props.publicMeeting.status.isPast
                                    ? "rsvp-mediumContainer"
                                    : "rsvp-mediumContainer disabled"
                            }
                        >
                            <div className="publicInvite-meetingInfo__Expired">
                                <section
                                    className="publicInvite-meetingDescription__Expired"
                                    role="region"
                                    aria-label="meeting description"
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: this.props.content.pastMeetingContent
                                        }}
                                    />
                                </section>
                            </div>
                        </MainPanel>
                    </div>
                    <SoftBranding />
                </article>
            </MainLayout>
        ) : (
            <MainLayout className="publicInvite">
                <Helmet>
                    <title>{this.props.content.pageTitle}</title>
                    <meta property="og:title" content={this.props.content.pageTitle} />
                    <meta
                        property="og:url"
                        content={this.props.publicMeeting.publicInviteUrl}
                    />
                    <meta
                        property="og:description"
                        content={this.getMeetingMetaDescription()}
                    />
                    <meta property="og:image" content={getMeetingImageUrl()} />
                </Helmet>

                <article>
                    {this.renderInviteBody()}
                    <SoftBranding />
                </article>
                <CollectionForm
                    meetingID={this.props.publicMeeting.meetingID}
                    isCollectionModalOpen={this.state.isCollectionModalOpen}
                    onRequestClose={this.closeModal}
                    updateSubmit={this.updateSubmit}
                />
                
            </MainLayout>
        )
    }
    
    renderInviteBody() {
        const showDestinationsInHeader = objectPath.get(this.props.content, 'displayValues.showDestinationsInHeader', false);

        const isPast = objectPath.get(this.props, 'publicMeeting.status.isPast', false);

        return (<>
            <MeetingImage />

            {!this.props.publicMeeting.status.isActive &&
                this.renderInactiveBanner(isPast)}

            <div className="white-background">
                <MainPanel
                    className={
                        this.props.publicMeeting.status.isActive
                            ? "rsvp-mediumContainer"
                            : "rsvp-mediumContainer disabled"
                    }
                >
                    {(showDestinationsInHeader && this.props.publicMeeting.destinations)
                        ? this.renderHeaderWithDestinations()
                        : this.renderHeaderDefault()
                    }

                    <div className="publicInvite-meetingInfo">
                        <div className="publicInvite-leftCol">
                            <section
                                className="publicInvite-meetingDescription"
                                role="region"
                                aria-label="meeting description"
                            >
                                <p>
                                    <Linkify properties={{ target: "_blank" }}>
                                        {this.props.publicMeeting.description}
                                    </Linkify>
                                </p>
                            </section>
                            <ContactOrganizer />
                        </div>
                        <div className="publicInvite-rightCol">
                            <DateLocation />
                            <section
                                className="publicInvite-callsToAction"
                                aria-label="response options"
                            >
                                {this.renderCallsToActionHeader()}
                                <button
                                    className="publicInvite-yesCTA rsvp-filled-button"
                                    onClick={this.handleAttendingClick}
                                    type="button"
                                    tabIndex="0"
                                >
                                    {this.props.content.yesButton}
                                </button>
                                <button
                                    className="publicInvite-interestedCTA rsvp-hollow-button"
                                    onClick={this.handleInterestedClick}
                                    type="button"
                                    tabIndex="0"
                                >
                                    {this.props.content.noButton}
                                </button>
                            </section>
                        </div>
                    </div>

                    <PastToursWithinOrg />

                </MainPanel>
            </div>
        </>);
    }

    renderHeaderDefault() {
        return (<>
            <header
                className="publicInvite-header"
                role="header"
                aria-label="meeting title"
            >
                <p className="publicInvite-preheader--desktop">
                    {this.props.content.hostedBy +
                        " " +
                        this.props.publicMeeting.organizer}
                </p>
                <p className="publicInvite-preheader--mobile">
                    {this.props.content.preTitleMobile}
                </p>
                <h1 id="meetingTitle">{this.props.publicMeeting.title}</h1>
                <p className="publicInvite-subheader--mobile">
                    {this.props.publicMeeting.organizer}
                </p>
            </header>
        </>);
    }
    
    renderHeaderWithDestinations() {
        return (<>
            <header
                className="publicInvite-header"
                role="header"
                aria-label="meeting title"
            >
                <p className="publicInvite-preheader--mobile">
                    {this.props.content.preTitleMobile}
                </p>
                <h1 id="meetingTitle">{this.props.publicMeeting.title}</h1>

                {this.props.publicMeeting.destinations && <>
                    <p className="publicInvite-subheader--desktop">
                        {this.props.publicMeeting.destinations}
                    </p>
                    <p className="publicInvite-subheader--mobile">
                        {this.props.publicMeeting.destinations}
                    </p>
                </>}
            </header>
        </>);
    }
    
    renderInactiveBanner() {
        return (
            <div>
                <div className="publicInvite-inactiveMeetingPrompt-wrapper">
                    <div
                        id="inactiveMeeting"
                        className="publicInvite-inactiveMeetingPrompt">

                        <div className="text">
                            <img src={exclamationSvg} />&nbsp;
                            <span>
                                {
                                    this.props.publicMeeting.status.isPast ?
                                        this.props.content.pastMeeting :
                                        this.props.content.cancelledMeeting
                                }
                            </span>
                        </div>

                        <div
                            className="text"
                            id="publicInvite-inactiveMeetingPrompt-emailOrganizer"
                        >
                            <img src={emailSvg} />&nbsp;
                            <span>
                                {this.props.content.questions}&nbsp;
                                <a
                                    className="emailOrganizer"
                                    href={"mailto:" + this.props.publicMeeting.organizerEmail}>
                                    {this.props.content.contactOrganizer}
                                </a>
                            </span>
                        </div>
                    </div>
                </div>

                <div className="publicInvite-inactiveMeetingInterestedBanner-wrapper">
                    <div
                        className="publicInvite-inactiveMeetingInterestedBanner">
                        {this.renderSubmitButton()}
                    </div>
                </div>
            </div>
        );
    }

    renderSubmitButton() {
        if (this.state.didSubmit) {
            return (
                <button
                    type="button"
                    id="inactiveReplyButton"
                    className='rsvp-hollow-button submitted'
                    disabled={true}
                    tabIndex="0">
                    {this.props.content.inactiveButtonSubmitted}
                </button>
            );
        } else {
            return (
                <button
                    type="button"
                    className='rsvp-hollow-button'
                    onClick={this.handleInactiveClick}
                    tabIndex="0">
                    {this.props.content.inactiveButton}
                </button>
            );
        }
    }

    renderCallsToActionHeader() {
        if (objectPath.get(this.props.content, 'displayValues.shouldShowCallsToActionHeader')) {
            return (
                <header className="publicInvite-callsToAction-header" role="header">
                    <h2>{this.props.content.callsToActionHeader}</h2>
                </header>
            );
        } else {
            return;
        }
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.publicInvite'),
            publicMeeting: state.publicInvite.publicMeeting,
            siteCode: objectPath.get(state, 'product.siteCode')
        };
    }
}

PublicInvite.propTypes = {
    content: PropTypes.shape({
        pageTitle: PropTypes.string.isRequired,
        errorLoadingMeeting: PropTypes.string.isRequired,
        errorLoadingMeetingHelp: PropTypes.string.isRequired,
        preTitleMobile: PropTypes.string.isRequired,
        hostedBy: PropTypes.string.isRequired,
        yesButton: PropTypes.string.isRequired,
        noButton: PropTypes.string.isRequired,
        inactiveButton: PropTypes.string.isRequired,
        inactiveButtonSubmitted: PropTypes.string.isRequired,
        cancelledMeeting: PropTypes.string.isRequired,
        pastMeeting: PropTypes.string.isRequired,
        pastMeetingContent: PropTypes.string.isRequired,
        questions: PropTypes.string.isRequired,
        contactOrganizer: PropTypes.string.isRequired
    })
};

export default connect(PublicInvite.mapStateToProps)(PublicInvite);
