import React from 'react';

import efLogo from '../../../static/img/ef_logo_2023_cst_191919.svg';

const TextingTermsCST = () =>
    <React.Fragment>
        <div className="rsvp-wideContainer rsvp-staticPage-header">
            <img className="rsvp-staticPage-efLogo"
                 src={efLogo}
                 alt="EF logo" />
        </div>
        <div className="rsvp-mediumContainer rendering-content">
            <h1>EF Text Message Terms and Conditions</h1>
            <p>
                By enrolling to receive SMS or MMS text messages from EF Institute for Cultural
                Exchange, Inc. (dba EF Study Abroad), you represent that you are 15 years
                of age or older and the owner, subscriber or customary user of the phone number
                you provided to EF Study Abroad.  You (or your parent or legal guardian
                if you are under the age of 18 or a minor under any other applicable law)
                expressly consent to the following terms and conditions.
            </p>
            <h3>Consent to Receive Text Messages</h3>
            <p>
                You consent to receive approximately four SMS or MMS text messages per month
                from EF Study Abroad or its affiliates to the phone number that you
                provide.  You acknowledge and understand that these text messages may or may
                not contain marketing content and may be sent or generated using an automatic
                telephone dialing system (also known as an “auto dialer”) or other technology.
                Message and data rates may apply. You understand that your consent to receive
                text messages is not required to make any purchase from EF Study Abroad.
            </p>
            <h3>Opt-Out</h3>
            <p>
                You may opt-out and withdraw your consent at any time by replying STOP to any
                EF Study Abroad text message or by contacting EF by the phone number
                provided below.  In the event that you are no longer the subscriber to or
                otherwise stop using the provided phone number, you agree to either
                (i) opt-out by replying “STOP” to an EF Study Abroad text message; or
                (ii) notify EF Study Abroad by calling 877-485-4184.
            </p>
            <h3>Supported Carriers</h3>
            <p>
                Supported carriers may change from time to time, but currently include
                AT&T, Verizon Wireless, Sprint, MetroPCS, T-Mobile (T-Mobile is not liable for
                delayed or undelivered messages).
            </p>
            <h3>Dispute Resolution</h3>
            <p>
                Any dispute or claim arising out of or relating in any way to SMS or MMS text
                messages sent by EF Study Abroad or its affiliates shall be exclusively
                resolved by final and binding arbitration under the JAMS Streamlined or
                Comprehensive Arbitration Rules and Procedures. THE PARTIES FURTHER AGREE THAT
                ALL COVERED DISPUTES BROUGHT AGAINST EACH OTHER WILL BE ARBITRATED EXCLUSIVELY
                ON AN INDIVIDUAL BASIS ONLY AND NOT IN A CLASS ACTION ARBITRATION, A COLLECTIVE
                ACTION ARBITRATION, OR ON A GROUP, REPRESENTATIVE, CONSOLIDATED, OR JOINT BASIS.
                YOUR ARE GIVING UP YOUR RIGHT TO PARTICPATE AS A CLASS REPRESENTATIVE OR CLASS
                MEMBER FOR ANY CLAIM COVERED BY THIS AGREEMENT. THE PARTIES WAIVE ANY RIGHT TO
                A TRIAL BY JURY OF A COVERED DISPUTE OR TO HAVE A COVERED DISPUTE BE DECIDED BY
                A COURT OR JURY.  With the exception of any of the language related to the
                waiver of class and representative actions, if any part of this Dispute
                Resolution section is rendered invalid or unenforceable, the other parts of
                this Dispute Resolution provision shall still apply.
            </p>
            <h3>Terms of Use & Privacy Policy</h3>
            <p>
                You accept and agree to be bound by EF Study Abroad&nbsp;
                <a href="https://www.efstudyabroad.com/legal/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</a>,&nbsp;
                <a href="https://www.efstudyabroad.com/legal/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>,
                and any other applicable terms and agreements.
            </p>
            <h3>Contact Us</h3>
            <p>
                For additional assistance or more information, call EF Study Abroad at 877-485-4184.
            </p>
        </div>
    </React.Fragment>;

    export default TextingTermsCST;