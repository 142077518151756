/**
 * Action Creators
 */

export const actions = {
    LOAD_CUSTOM_CONTENT: 'LOAD_CUSTOM_CONTENT'
};

export const loadCustomContent = (siteCode) => {
    return (dispatch) => {
        return dispatch({
            type: actions.LOAD_CUSTOM_CONTENT,
            siteCode
        });
    };
};