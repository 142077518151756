import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';

import './ThankYou.scss';

import { loadPublicMeeting } from '../public-invite/public-invite-actions.js';
import MainLayout from '../layouts/MainLayout.jsx';
import MeetingImage from '../globals/components/meeting-image/MeetingImage.jsx';
import MainPanel from '../globals/components/main-panel/MainPanel.jsx';
import ContactOrganizer from '../globals/components/contact-organizer/ContactOrganizer.jsx';
import DateLocation from '../globals/components/date-location/DateLocation.jsx';
import ShareOptions from '../globals/components/share-options/ShareOptions.jsx';
import SoftBranding from '../globals/components/soft-branding/SoftBranding.jsx';

class ThankYou extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        let meetingID = this.props.match.params.meeting_id;

        this.props.loadPublicMeeting(meetingID).response
            .then(() => {
                let query = queryString.parse(this.props.location.search),
                    isAttending = query.isAttending;

                this.setState({
                    isAttending,
                    isLoading: false
                });

                // Reset focus to main element to have a less confusing screen reader UX
                // TODO: screen readers do not handle client-side navigation well
                //       so we should implement a more robust solution such as
                //       https://oaf-project.github.io/oaf-react-router/
                //eslint-disable-next-line
                const node = ReactDOM.findDOMNode(this);
                const main = node.querySelector('main');
                main.focus();
            });
    }



    render() {
        if (this.props.publicMeeting.meetingID) {
            const shortLink = this.props.publicMeeting.shortLink && this.props.publicMeeting.shortLink.link ? this.props.publicMeeting.shortLink.link : null;

            const renderThankYou = () =>
                <MainLayout className="thankYou">
                    <Helmet>
                        <title>{this.props.content.pageTitle}</title>
                        <meta property="og:title" content={this.props.content.pageTitle} />
                    </Helmet>
                    <MeetingImage />
                    <MainPanel className="rsvp-mediumContainer">
                        {
                            this.state.isAttending &&
                            renderAttendingInfo()
                        }
                        {
                            !this.state.isAttending &&
                            renderInterestedInfo()
                        }
                    </MainPanel>
                    <SoftBranding />
                </MainLayout>;

            const renderAttendingInfo = () =>
                <React.Fragment>
                    <header className="thankYou-header">
                        <h1 role="alert">{this.props.content.thankYouAttendingHeader}</h1>
                    </header>
                    <div className="thankYou-attending">
                        <div className="thankYou-attending-left">
                            <section className="thankYou-share" role="region" aria-labelledby="shareMeetingHeader">
                                <header>
                                    <h2 id="shareMeetingHeader">{this.props.content.shareMeetingHeader}</h2>
                                </header>
                                <menu className="thankYou-share-menu">
                                    {(!this.state.isLoading || shortLink) &&
                                        <ShareOptions
                                            meetingID={this.props.publicMeeting.meetingID}
                                            meetingTitle={this.props.publicMeeting.title || ''}
                                            sharedBy={'public'}
                                            showIconsOnly={true}
                                            shortLink={shortLink}
                                        />
                                    }
                                </menu>
                            </section>
                            <ContactOrganizer />
                        </div>
                        <div className="thankYou-attending-right">
                            <DateLocation canAddToCalendar={true} />
                        </div>
                    </div>
                </React.Fragment>;

            const renderInterestedInfo = () =>
                <React.Fragment>
                    <header className="thankYou-header">
                        <h1 role="alert">{this.props.content.thankYouInterestedHeader}</h1>
                    </header>
                    <div className="thankYou-interested">
                        <section className="thankYou-share" role="region" aria-labelledby="shareMeetingHeader">
                            <header>
                                <h2 id="shareMeetingHeader">{this.props.content.shareMeetingHeader}</h2>
                            </header>
                            <menu className="thankYou-share-menu">
                                {(!this.state.isLoading || shortLink) &&
                                    <ShareOptions
                                        meetingID={this.props.publicMeeting.meetingID}
                                        meetingTitle={this.props.publicMeeting.title || ''}
                                        sharedBy={'public'}
                                        shortLink={shortLink}
                                    />
                                }
                            </menu>
                        </section>
                    </div>
                </React.Fragment>;

            return renderThankYou();
        } else {
            return null;
        }
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.thankYou'),
            publicMeeting: state.publicInvite.publicMeeting || {}
        };
    }
}

const mapDispatchToProps = {
    loadPublicMeeting
};

ThankYou.propTypes = {
    isAttending: PropTypes.bool,
    content: PropTypes.shape({
        pageTitle: PropTypes.string.isRequired,
        thankYouAttendingHeader: PropTypes.string.isRequired,
        thankYouInterestedHeader: PropTypes.string.isRequired,
        shareMeetingHeader: PropTypes.string.isRequired
    })
};

export default connect(ThankYou.mapStateToProps, mapDispatchToProps)(ThankYou);
