import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import './../public-invite/CollectionForm.scss'; //TODO: standardize our form styles
import './AdminPanel.scss';

import MainLayout from './../layouts/MainLayout.jsx';
import MainPanel from './../globals/components/main-panel/MainPanel.jsx';
import { logInAdmin } from './../globals/services/authentication/authentication-actions.js';
import loginAdminSchema from './login-admin-schema.js';

class AdminPanel extends React.Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    render() {
        return (
            <MainLayout className="admin">
                <MainPanel className="rsvp-narrowContainer rsvp-adminPanel">
                    <Helmet>
                        <title>{this.props.content.pageTitle}</title>
                        <meta property="og:title" content={this.props.content.pageTitle} />
                    </Helmet>
                    <h1>Admin</h1>
                    {!this.props.isAdmin ? this.renderLogin() : this.renderLoggedIn()}
                    {!this.props.failedSubmit || this.renderFailureMessage()}
                </MainPanel>
            </MainLayout>
        );
    }

    renderLogin() {
        return (
            <section className="rsvp-adminPanel-loginView">
                <Formik
                    onSubmit={this.handleSubmit}
                    initialValues={{
                        username: '',
                        password: ''
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        values,
                        errors
                    }) => (
                        <form onSubmit={handleSubmit} className="loginForm">
                            <div className="fieldset">
                                <input type="text"
                                    placeholder=" "
                                    className={'rsvp-textInput' + this.getFieldErrorClass('username', errors)}
                                    value={values.username}
                                    onChange={handleChange}
                                    id="username" />
                                <label htmlFor="username">
                                    {this.props.content.login.usernameLabel}
                                </label>
                            </div>
                            <div className="fieldset">
                                <input type="password"
                                    placeholder=" "
                                    className={'rsvp-textInput' + this.getFieldErrorClass('password', errors)}
                                    value={values.password}
                                    onChange={handleChange}
                                    id="password" />
                                <label htmlFor="password">
                                    {this.props.content.login.passwordLabel}
                                </label>
                            </div>
                            <input type="submit" value={this.props.content.login.loginButton} />
                        </form>
                    )}
                </Formik>
            </section>
        );
    }

    handleSubmit(values, { setErrors }) {
        loginAdminSchema.validate(values, { abortEarly: false })
            .then(() => {
                setErrors({});
                this.props.dispatch(logInAdmin(values.username, values.password));
            }, (validationError) => {
                let errorObj = {};

                setErrors({});

                validationError.inner.forEach((error) => {
                    errorObj[error.path] = typeof error.type === 'undefined' ? 'invalid' : error.type;
                });

                setErrors(errorObj);
            });
    }

    getFieldErrorClass(fieldName, errors) {
        return errors[fieldName] ?
            ' ' + errors[fieldName] : '';
    }

    renderLoggedIn() {
        return (
            <section className="rsvp-adminPanel-loggedInView" id="loginSuccess">
                <h4>You are logged in as an admin</h4>
                <p>Navigate to any meeting page to access it.</p>
            </section>
        );
    }

    renderFailureMessage() {
        return (
            <p className="login-failure-message">Login Attempt Failed</p>
        );
    }

    static mapStateTopProps(state) {
        return {
            content: objectPath.get(state, 'product.content.admin'),
            isAdmin: objectPath.get(state, 'admin.isAdmin'),
            hasSubmitted: objectPath.get(state, 'admin.hasSubmitted'),
            failedSubmit: objectPath.get(state, 'admin.failedSubmit')
        };
    }
}

AdminPanel.propTypes = {
    isAdmin: PropTypes.bool,
    failedSubmit: PropTypes.bool,
    hasSubmitted: PropTypes.bool,
    content: PropTypes.shape({
        pageTitle: PropTypes.string.isRequired,
        login: PropTypes.shape({
            usernameLabel: PropTypes.string,
            passwordLabel: PropTypes.string,
            loginButton: PropTypes.string
        })
    })
};

export default connect(AdminPanel.mapStateTopProps)(AdminPanel);