import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { logErrorToServer, displayGlobalErrorModal } from '../services/global-error/global-error-actions.js';
import PageLoadingSpinner from './page-loading-spinner/PageLoadingSpinner.jsx';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });

        let moduleId = this.props.name,
            componentStack = info.componentStack;

        if (!this.props.shouldFailSilently) {
            this.props.displayGlobalErrorModal();
        }

        this.props.logErrorToServer(error, moduleId, componentStack);
    }

    render() {
        if (this.state.hasError && !this.props.shouldFailSilently) {
            return <PageLoadingSpinner />;
        }
        return this.props.children || null;
    }
}

ErrorBoundary.propTypes = {
    name: PropTypes.string.isRequired,
    shouldFailSilently: PropTypes.bool
};

const mapDispatchToProps = {
    logErrorToServer, displayGlobalErrorModal
};

export default connect(null, mapDispatchToProps)(ErrorBoundary);