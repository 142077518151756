import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import PropTypes from 'prop-types';

import './ContactOrganizer.scss';

export class ContactOrganizer extends React.Component {

    render() {
        return (
            <section className="rsvp-contactOrganizer" role="region" aria-labelledby="organizerHeader">
                <header>
                    <h2 id="organizerHeader">{this.props.content.organizerHeader}</h2>
                    <p>{this.props.publicMeeting.organizer}</p>
                </header>
                <a href={'mailto:' + this.props.publicMeeting.organizerEmail}>
                    {this.props.content.contact}
                </a>
            </section>
        );
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.globals.contactOrganizer'),
            publicMeeting: state.publicInvite.publicMeeting
        };
    }
}

ContactOrganizer.propTypes = {
    content: PropTypes.shape({
        organizerHeader: PropTypes.string.isRequired,
        contact: PropTypes.string.isRequired
    })
};

export default connect(ContactOrganizer.mapStateToProps)(ContactOrganizer);