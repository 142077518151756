import { combineReducers } from 'redux';

import productReducer from './../globals/services/product/product-reducer.js';
import publicInviteReducer from './../public-invite/public-invite-reducer.js';
import manageMeetingReducer from './../manage-meeting/manage-meeting-reducer.js';
import viewAllReducer from './../view-all/view-all-reducer.js';
import globalErrorReducer from './../globals/services/global-error/global-error-reducer.js';
import windowReducer from './../globals/services/window/window-reducer.js';
import adminReducer from './../admin/admin-reducer.js';
import userReducer from './../globals/services/user/user-reducer.js';
import shortLinkReducer from './../globals/services/shortLink/shortLink-reducer.js'; 
import { connectRouter } from 'connected-react-router'

const mainReducer = (history) => combineReducers({
    router: connectRouter(history),
    product: productReducer,
    publicInvite: publicInviteReducer,
    manageMeeting: manageMeetingReducer,
    viewAll: viewAllReducer,
    globalError: globalErrorReducer,
    window: windowReducer,
    admin: adminReducer,
    user: userReducer,
    shortLinkService: shortLinkReducer
});

export default mainReducer;
