import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import PropTypes from 'prop-types';

import './ActionBar.scss';

import Modal from '../globals/components/modal/Modal.jsx';
import shareSvg from '../../static/img/export_FFF.svg';
import ShareOptions from '../globals/components/share-options/ShareOptions.jsx';
import BackToOverview from '../globals/components/back-to-overview/BackToOverview.jsx';

class ActionBar extends React.Component {
    constructor(props) {
        super(props);

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleShareButtonClick = this.handleShareButtonClick.bind(this);
        this.state = {
            isShareModalOpen: false,
        };
    }

    openModal() {
        if (!this.state.isShareModalOpen) {
            this.setState({
                isShareModalOpen: true
            });
        }
    }

    closeModal() {
        if (this.state.isShareModalOpen) {
            this.setState({
                isShareModalOpen: false
            });
        }
    }

    handleShareButtonClick() {
        this.openModal();
    }
    
    viewAllURL() {
        return '/manage-meeting/' + this.props.editableMeeting.groupID + '/view-all';
    }
    
    render() {
        const viewAllLinkContent = this.props.content.viewAllLink;
        const shortLink = this.props.editableMeeting.shortLink && this.props.editableMeeting.shortLink.link
            ? this.props.editableMeeting.shortLink.link
            : null;
        
        const renderActionBar = () =>
        <div className="actionBar rsvp-wideContainer" role="menu">
            <BackToOverview groupTripId={this.props.groupTripId} />
            <div>
                <a className="actionBar-viewAll" href={this.viewAllURL()}>
                    {viewAllLinkContent}
                </a>
                <button
                    className="actionBar-shareButton"
                    id="shareButtonForOrganizer"
                    onClick={this.handleShareButtonClick}
                    type="button"
                    tabIndex="0"
                >
                    <img src={shareSvg} />&nbsp;
                    {this.props.content.shareInviteButton}
                </button>
            </div>
            <Modal
                isOpen={this.state.isShareModalOpen}
                shouldCloseOnOverlayClick={true}
                timeout={300}
                classNames="shareModal"
                onRequestClose={this.closeModal}
                modalID="shareModal"
                header={
                    (this.props.isInviteFormDirty ?
                        this.props.content.unsavedChanges : this.props.content.shareModalHeader)
                }
                disableFocusLock
            >
                {
                    this.props.isInviteFormDirty ?
                        renderSaveReminder() : renderShareOptions()
                }
            </Modal>
        </div>;

        const renderSaveReminder = () =>
            <React.Fragment>
                <button
                    type="button"
                    className="shareModal-closeButton rsvp-filled-button"
                    id="shareModalReminderCloseButton"
                    onClick={this.closeModal}
                    tabIndex="0"
                >
                    {this.props.content.returnToPage}
                </button>
            </React.Fragment>;

        const renderShareOptions = () =>
            <React.Fragment>
                <menu className="shareModal-menu">
                    <ShareOptions
                        meetingID={this.props.meetingID}
                        meetingTitle={this.props.editableMeeting.title || ''}
                        sharedBy={'GL'}
                        shortLink={shortLink}
                    />
                </menu>
            </React.Fragment>;

        return renderActionBar();
    }

    static mapStateToProps(state) {
        return {
            isInviteFormDirty: objectPath.get(state, 'manageMeeting.isInviteFormDirty'),
            content: objectPath.get(state, 'product.content.manageMeeting.actionBar'),
            editableMeeting: objectPath.get(state, 'manageMeeting.editableMeeting')
        }
    }
}

export default connect(ActionBar.mapStateToProps)(ActionBar);

ActionBar.propTypes = {
    meetingID: PropTypes.string.isRequired,
    groupTripId: PropTypes.string,
    content: PropTypes.shape({
        shareInviteButton: PropTypes.string.isRequired,
        shareModalHeader: PropTypes.string.isRequired,
        unsavedChanges: PropTypes.string.isRequired,
        returnToPage: PropTypes.string.isRequired
    })
};