/**
 * Action Types
 */
export const globalErrorActions = {
    GLOBAL_ERROR_MODAL_OPEN: 'GLOBAL_ERROR_MODAL_OPEN',
    GLOBAL_ERROR_CLEAR_ALL: 'GLOBAL_ERROR_CLEAR_ALL',
    GLOBAL_ERROR_CLEAR_ONE: 'GLOBAL_ERROR_CLEAR_ONE',
    GLOBAL_ERROR_LOG: 'GLOBAL_ERROR_LOG'
};

/**
 * Action Creators
 */
export function displayGlobalErrorModal(error, customErrorMessage, shouldRedirectToLogin) {
    return {
        type: globalErrorActions.GLOBAL_ERROR_MODAL_OPEN,
        error,
        customErrorMessage,
        shouldRedirectToLogin
    };
}

export function clearAllGlobalErrors() {
    return {
        type: globalErrorActions.GLOBAL_ERROR_CLEAR_ALL
    };
}

export function clearGlobalError() {
    return {
        type: globalErrorActions.GLOBAL_ERROR_CLEAR_ONE
    };
}

export function logErrorToServer(error, moduleId, componentStackTrace) {
    return () => {
        /*
        error - an actual javascript Error, which should at least have error.message
        moduleId - used to identify which body of code failed. Could be the name of the
                react Component or file that threw the error.
        componentStackTrace - the ComponentDidError() lifecycle method provides a special stack trace
                which we should include. Otherwise we will try to use error.stack
        */

        /*
        Rollbar excepts the following params (order does not matter):

        message: String - The message to send to Rollbar.
        err: Exception - The exception object to send.
        custom: Object - The custom payload data to send to Rollbar.
        callback: Function - The function to call once the message has been sent to Rollbar.

        https://docs.rollbar.com/v1.0.0/docs/rollbarjs-configuration-reference#section-rollbar-log-
        */

        let errorDetails = {
            moduleId: moduleId,
            stack: componentStackTrace || error.stack || null,
            clientRequest: getClientRequestData()
        };

        if (
            typeof process.env.NODE_ENV === "undefined" ||
            process.env.NODE_ENV === "dev" || 
            typeof Rollbar === 'undefined'
        ) {
            console.dir("error!", error, errorDetails);
        } else {
            if (error instanceof Error) {
                //eslint-disable-next-line
                Rollbar.error(error.message, error, errorDetails);
            } else {
                // This really shouldn't happen, but if the error isn't type 'Error'
                // we need to make sure our parameters are still valid.
                errorDetails.errorObj = error;
                //eslint-disable-next-line
                Rollbar.error(error.message, errorDetails);
            }
        }
    }
}

function getClientRequestData() {
    let navigator = window.navigator || {};
    return {
        url: window.location.href,
        userAgent: navigator.userAgent,
        browserLanguage: navigator.language,
        platform: navigator.platform,
        cookieEnabled: navigator.cookieEnabled,
        app: 'rsvp'
    };
}
