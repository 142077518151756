import React from 'react';
import objectPath from 'object-path';
import { connect } from 'react-redux';

import './StaticPage.scss';

import TextingTermsETUS from './renderings/texting-terms-etus.jsx';
import TextingTermsCST from './renderings/texting-terms-cst.jsx';
import TextingTermsGST from './renderings/texting-terms-gst.jsx';
import TextingTermsEAUS from './renderings/texting-terms-eaus.jsx';

class TextingTerms extends React.Component {
    render() {
        switch(this.props.siteCode) {
            case 'ETUS':
                return this.renderETUS();
            case 'CST':
                return this.renderCST();
            case 'GST':
                return this.renderGST();
            case 'EAUS':
                return this.renderEAUS();
            default:
                return null;
        }
    }

    renderETUS() {
        return <TextingTermsETUS/>;
    }

    renderCST() {
        return <TextingTermsCST/>;
    }

    renderGST() {
        return <TextingTermsGST/>;
    }

    renderEAUS() {
        return <TextingTermsEAUS/>;
    }

    static mapStateToProps(state) {
        return {
            siteCode: objectPath.get(state, 'product.siteCode')
        };
    }
}

export default connect(TextingTerms.mapStateToProps)(TextingTerms);