import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import objectPath from 'object-path';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

class PastToursWithinOrg extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const pastTours = this.props.publicMeeting.pastToursWithinOrg;

        if (!pastTours || !Array.isArray(pastTours) || pastTours.length < 1) {
            return null;
        }
        return (
            <Grid container spacing={2} mt={4}>
                <Grid item xs={12}>
                    <Typography variant='h5' component='h3' gutterBottom>
                        { this.props.content.pastToursHeader }
                    </Typography>
                </Grid>
                {pastTours.map((pastTour, index) =>
                    <Grid item xs={12} md={4} key={index}>
                        <Card>
                            <CardActionArea>
                                <CardMedia
                                    sx={{
                                        height: { xs: 200, md: 300},
                                    }}
                                    image={pastTour.imageSrc}
                                >
                                    <Link
                                        href={pastTour.pdfUrl}
                                        target="_blank"
                                        sx={{
                                            height: '100%',
                                            width: '100%',
                                            p: 2,
                                            color: 'common.white',
                                            textDecoration: 'none',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'end',
                                            background:
                                                'linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 32%, rgba(0, 0, 0, 0.65) 75%)',
                                            ['&:hover']: {
                                                color: 'common.white',
                                            }
                                        }}
                                    >
                                        <Typography variant='h5' component='span' gutterBottom>
                                            {pastTour.name} in {moment.utc(pastTour.date).year()}
                                        </Typography>

                                        <Stack direction='row' justifyContent='space-between'>
                                            <Typography variant='h6' component='span'>
                                                { this.props.content.downloadItinerary }
                                            </Typography>
                                            <ArrowForwardIcon />
                                        </Stack>
                                    </Link>
                                </CardMedia>
                            </CardActionArea>
                        </Card>
                    </Grid>
                )}
            </Grid>
        );
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.globals.pastToursWithinOrg'),
            publicMeeting: state.publicInvite.publicMeeting
        };
    }
}


PastToursWithinOrg.propTypes = {
    pastTours: PropTypes.arrayOf(
        PropTypes.shape({
            groupTripId: PropTypes.number,
            name: PropTypes.string,
            date: PropTypes.string,
            imageSrc: PropTypes.string,
            pdfUrl: PropTypes.string
        })
    ),
    content: PropTypes.shape({
        pastToursHeader: PropTypes.string,
        downloadItinerary: PropTypes.string
    })
};

export default connect(PastToursWithinOrg.mapStateToProps)(PastToursWithinOrg);