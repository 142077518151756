import { logErrorToServer } from '../global-error/global-error-actions.js';
import api from '../../services/api-request.js';

/**
 * Action Types
 */
export const actions = {
    SHORTEN_LINK_INIT:    'SHORTEN_LINK_INIT',
    SHORTEN_LINK_SUCCESS: 'SHORTEN_LINK_SUCCESS',
    SHORTEN_LINK_ERROR:   'SHORTEN_LINK_ERROR'
};


/**
 * Action Creators
 */
export const shortenLink = (meetingID, link) => {
    return (dispatch) => {
        return dispatch({
            type: actions.SHORTEN_LINK_INIT,
            response: api
                .post('/meetings/shortenLink', { meetingID, link })
                .then((res) => {
                    // If the api responds with an error, dispatch to update state and trigger display of the non-shortened rsvp url. 
                    if ( res.error ) {
                        dispatch({ type: actions.SHORTEN_LINK_ERROR });
                        return Promise.reject();
                    } else {
                        dispatch({
                            type: actions.SHORTEN_LINK_SUCCESS,
                            id: res.data.id,
                            link: res.data.shortLink
                        });
                    }
                }, (error) => {
                    // dispatch(displayGlobalErrorModal(error));
                    dispatch({ type: actions.SHORTEN_LINK_ERROR });

                    // Log error to rollbar
                    logErrorToServer(error, 'shortenLink');

                    return Promise.reject();
                })
        });
    };
};
