//Global Constants
const rsvpGlobal = {
    api: {
        BASE_URL: 'https://rsvp.eftours.com/api'
    },
    cookies: {
        JWT_COOKIE: 'RSVP-JWT',
        EF_USER: 'efuser'
    },
    ENVIRONMENT: 'prod',
    manageMeeting: {
        panelNames: {
            INVITE: 'invite-panel',
            ATTENDING: 'attending-panel',
            INTERESTED: 'interested-panel'
        }
    }
};

export default rsvpGlobal;