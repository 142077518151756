import axios from 'axios';

import rsvpGlobal from '../utils/global-constants.js';

/**
 * Create an instance of Axios for API requests
 */

const api = axios.create({
    baseURL: rsvpGlobal.api.BASE_URL,
    withCredentials: true
});

export default api;