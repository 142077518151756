import * as yup from 'yup';

import regex from '../globals/utils/regular-expressions.js';

// We store all user-facing text, including errors, in redux. Therefore we need to pass that
// product object into this module, so that the Schema can initialize with the correct
// error text.
export const createEditableMeetingSchema = (content) => {
    return yup.object().shape({
        title: yup.string().required(content.requiredErrorLabel),
        description: yup.string().required(content.requiredErrorLabel),
        organizer: yup.string().required(content.requiredErrorLabel),
        organizerEmail: yup.string().matches(regex.EMAIL, content.invalidErrorLabel)
            .required(content.requiredErrorLabel),
        venue: yup.string().nullable(),
        street1: yup.string().nullable(),
        city: yup.string().nullable(),
        state: yup.string().min(2, content.minErrorLabel).max(2, content.maxErrorLabel).nullable(),
        postalCode: yup.string().nullable(),
        onlineMeetingUrl: yup.string().nullable()
    });
};