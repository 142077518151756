import React from 'react';
import objectPath from 'object-path';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './MeetingOptions.scss';

import { cancelMeeting } from './manage-meeting-actions.js';
import Modal from '../globals/components/modal/Modal.jsx';
import xSvg from '../../static/img/x_000.svg';
import arrowSvg from '../../static/img/arrow_269DDA.svg';
import PageLoadingSpinner from '../globals/components/page-loading-spinner/PageLoadingSpinner.jsx';
import EmailAllOptions from '../globals/components/email-all/EmailAllOptions.jsx';

class MeetingOptions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isCancelModalOpen: false,
            isCancelRequestPending: false,
            selectedCancelModalRadio: '',
            selectedNotifyModalRadio: '',
            shouldShowEmailModal: false
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.updateCancelRadios = this.updateCancelRadios.bind(this);
        this.handleCancelMeeting = this.handleCancelMeeting.bind(this);
        this.updateNotifyRadios = this.updateNotifyRadios.bind(this);
        this.handleNotifyModalDoneClick = this.handleNotifyModalDoneClick.bind(this);
    }

    componentDidUpdate(prevProps) {

        if (prevProps.isMeetingCancelled !== this.props.isMeetingCancelled) {
            this.closeModal();

            this.setState({
                isCancelRequestPending: false
            });
        }
    }

    openModal() {
        if (!this.state.isCancelModalOpen) {
            this.setState({
                isCancelModalOpen: true
            });
        }
    }

    closeModal() {
        if (this.state.isCancelModalOpen) {
            this.setState({
                selectedCancelModalRadio: '',
                selectedNotifyModalRadio: '',
                shouldShowEmailModal: false,
                isCancelModalOpen: false
            });
        }
    }

    updateCancelRadios(e) {
        this.setState({
            selectedCancelModalRadio: e.target.value
        });
    }

    handleCancelMeeting() {
        this.props.cancelMeeting(this.props.meetingID);

        this.setState({
            isCancelRequestPending: true
        });
    }

    updateNotifyRadios(e) {
        this.setState({
            selectedNotifyModalRadio: e.target.value
        });
    }

    handleNotifyModalDoneClick() {
        this.closeModal();
    }

    render() {
        if (this.props.meetingID) { return (
            <section className="meetingOptions rsvp-wideContainer">
                { !this.props.isMeetingCancelled &&
                    <button
                        type="button"
                        onClick={this.openModal}
                        id="cancelMeetingButton"
                        className="meetingOptions-cancelButton"
                        tabIndex="0"
                    >
                        <img src={xSvg} />&nbsp;
                        <span>{this.props.content.cancelMeetingButton}</span>
                    </button>
                }
                { this.props.isMeetingCancelled &&
                    <span>{this.props.content.meetingWasCancelled}&nbsp;</span>
                }

                <Modal
                    isOpen={this.state.isCancelModalOpen}
                    timeout={300}
                    classNames={'meetingOptions-cancelModal'}
                    onRequestClose={this.closeModal}
                    modalID="meetingOptions"
                    shouldCloseOnOverlayClick={true}
                    initialFocusSelector="#cancelMeetingNo"
                    header={
                        (!this.props.isMeetingCancelled ?
                            this.props.content.cancelModalHeader: this.props.emailModalContent.emailTitle)
                    }
                >
                    { !this.props.isMeetingCancelled &&
                        <React.Fragment>
                            <section className="meetingOptions-cancelModal-yesCancel">
                                <h3>{this.props.content.cancelModalYes}</h3>
                                <hr/>
                                <p className="meetingOptions-cancelModal-yesCancelHelp">
                                    {this.props.content.cancelModalHelpYes}
                                </p>
                                <button
                                    type="button"
                                    id="cancelMeetingYes"
                                    className="meetingOptions-cancelModal-yesCancelSubmit"
                                    onClick={this.handleCancelMeeting}
                                    tabIndex="0"
                                >
                                    { this.state.isCancelRequestPending
                                        ? <PageLoadingSpinner
                                            containerHeight={'initial'}
                                            spinnerWidth="25px"
                                        />
                                        : this.props.content.cancelModalDoneButton
                                    }
                                </button>
                            </section>
                            <hr />
                            <section className="meetingOptions-cancelModal-noCancel">
                                <h3>{this.props.content.cancelModalNo}</h3>
                                <hr/>
                                <p className="meetingOptions-cancelModal-noCancelHelp">
                                    {this.props.content.cancelModalHelpNo}
                                </p>
                                <span
                                    id="cancelMeetingNo"
                                    className="meetingOptions-cancelModal-noCancelBack"
                                    onClick={this.closeModal}
                                    tabIndex="0"
                                >
                                    {this.props.content.cancelModalBackButton}&nbsp;
                                    <img src={arrowSvg} />
                                </span>
                            </section>
                        </React.Fragment>
                    }

                    { (this.props.isMeetingCancelled && this.state.shouldShowEmailModal)  &&
                        <React.Fragment>
                            <EmailAllOptions />
                        </React.Fragment>
                    }
                </Modal>
            </section>
        );} else {
            return null;
        }
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.manageMeeting.meetingOptions'),
            emailModalContent: objectPath.get(state, 'product.content.globals.emailAll'),
            meetingID: objectPath.get(state, 'manageMeeting.editableMeeting.meetingID'),
            isMeetingCancelled: !!(objectPath.get(state, 'manageMeeting.editableMeeting.status.isCancelled'))
        };
    }
}

const mapDispatchToProps = {
    cancelMeeting
};

MeetingOptions.propTypes = {
    content: PropTypes.shape({
        cancelMeetingButton: PropTypes.string.isRequired,
        meetingWasCancelled: PropTypes.string.isRequired,
        cancelModalHeader: PropTypes.string.isRequired,
        cancelModalYes: PropTypes.string.isRequired,
        cancelModalHelpYes: PropTypes.string.isRequired,
        cancelModalNo: PropTypes.string.isRequired,
        cancelModalHelpNo: PropTypes.string.isRequired,
        cancelModalBackButton: PropTypes.string.isRequired,
        cancelModalDoneButton: PropTypes.string.isRequired,
        wantToNotify: PropTypes.string.isRequired,
        notifyModalYes: PropTypes.string.isRequired,
        notifyModalNo: PropTypes.string.isRequired
    }),
    meetingID: PropTypes.string,
    isMeetingCancelled: PropTypes.bool
};

export default connect(MeetingOptions.mapStateToProps, mapDispatchToProps)(MeetingOptions);