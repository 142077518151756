import objectPath from 'object-path';
import jwtDecode from 'jwt-decode';
import store from '../../../main/main-store.js';

import globals from './../../utils/global-constants.js';
import api from './../api-request.js';
import { setCookie, getCookie, deleteCookie } from './../../utils/cookie-util.js';
import { redirectToEvaLogin, redirectToEvaLogout } from '../../utils/url-util.js';
import { displayGlobalErrorModal } from '../global-error/global-error-actions';

/**
 * Action Types
 */
export const actions = {
    LOGIN_EF_USER_INIT: 'LOGIN_EF_USER_INIT',
    LOGIN_EF_USER_SUCCESS: 'LOGIN_EF_USER_SUCCESS',
    LOGIN_ADMIN_INIT: 'LOGIN_ADMIN_INIT',
    LOGIN_ADMIN_SUCCESS: 'LOGIN_ADMIN_SUCCESS',
    LOGIN_ADMIN_ERROR: 'LOGIN_ADMIN_ERROR',
    LOGOUT_EF_USER: 'LOGOUT_EF_USER'
};

/**
 * Async Actions
 */

export const checkAuthentication = () => {
    let jwtCookie = getJwtCookie(),
        efCookie = getEFUserCookie(),
        isInternal = getIsInternal(),
        isAdmin = getIsAdmin(),
        authenticationPromise;

    if (!!jwtCookie) {
        if (isInternal) {
            /* Yes, we're logging in even though we are already logged in.
            This allows a tour consultant to easily switch between
            user accounts by impersonating another user, without having
            to log out first. */
            authenticationPromise = store.dispatch(logInEFUser()).response;
        } else if (isAdmin) {
            authenticationPromise = Promise.resolve(store.dispatch({
                type: actions.LOGIN_ADMIN_SUCCESS,
                jwt: jwtCookie
            }));
        } else {
            authenticationPromise = Promise.resolve(store.dispatch({
                type: actions.LOGIN_EF_USER_SUCCESS,
                jwt: jwtCookie
            }));
        }
    } else {
        if (!!efCookie) {
            authenticationPromise = store.dispatch(logInEFUser()).response;
        } else {
            authenticationPromise = Promise.resolve(redirectToEvaLogin());
        }
    }

    return authenticationPromise;
};

/**
 * Calls the API with user data, and will dispatch actions that will end up saving the jwt, without
 * the efUser object, but with an isAdmin flag, to the global state.
 *
 * @param username
 * @param password
 */
export const logInAdmin = (username, password) => {
    return (dispatch) => {
        return dispatch({
            type: actions.LOGIN_ADMIN_INIT,
            response: api.post('/users/log-in', {username, password})
                .then((res) => {
                    let jwt = res.data.jwt;

                    setCookie(globals.cookies.JWT_COOKIE, jwt, {
                        //one hour
                        maxAge: 60 * 60,
                        path: '/'
                    });

                    return dispatch({
                        type: actions.LOGIN_ADMIN_SUCCESS,
                        jwt
                    });
                }, (error) => {
                    return dispatch({
                        type: actions.LOGIN_ADMIN_ERROR,
                        statusCode: objectPath.get(error, 'response.status'),
                        message: objectPath.get(error, 'response.data.message')
                    });
                })
        });
    }
};

/**
 * Takes the values from the efUser cookie and attempts to login via the API. If successful, a new
 * cookie will be saved containing the returns JWT data from the API, which will be used for future
 * authentication / authorization checks
 */
export const logInEFUser = () => {
    return (dispatch) => {
        let efUserCookieValue = getCookie(globals.cookies.EF_USER),
            reqBody = {
                efUserCookieValue
            };

        return dispatch({
            type: actions.LOGIN_EF_USER_INIT,
            response: api.post('/user/login-efuser', reqBody)
                .then((res) => {
                    let jwt = res.data.jwt;

                    setCookie(globals.cookies.JWT_COOKIE, jwt, {
                        //one hour
                        maxAge: 60 * 60,
                        path: '/'
                    });

                    return dispatch({
                        type: actions.LOGIN_EF_USER_SUCCESS,
                        jwt
                    });
                }, (error) => {
                    dispatch(displayGlobalErrorModal(error));
                    return Promise.reject();
                })
        });
    };
};

export const logoutEfuser = () => {
    deleteCookie(globals.cookies.JWT_COOKIE);

    redirectToEvaLogout();

    return {
        type: actions.LOGOUT_EF_USER
    }
};

/**
 * Helper Functions
 */
export const getIsAuthenticated = () => {
    let jwtCookie = getCookie(globals.cookies.JWT_COOKIE);

    return !!(jwtCookie);
};

export const getIsInternal = () => {
    let jwtCookie = getJwtCookie();

    return !!(jwtCookie) ? !!(objectPath.get(jwtDecode(jwtCookie), 'isInternal')) : false;
};

export const getIsAdmin = () => {
    let jwtCookie = getJwtCookie();

    return !!(jwtCookie) && !!(objectPath.has(jwtDecode(jwtCookie), 'adminUser'));
};

export const getJwtCookie = () => {
    return getCookie(globals.cookies.JWT_COOKIE);
};

export const getEFUserCookie = () => {
    return getCookie(globals.cookies.EF_USER);
};

export const getAuthorizationHeaders = () => {
    let jwtCookie = getJwtCookie();
    let decodedCookie = jwtDecode(jwtCookie);

    return {
        'Authorization': 'Bearer ' + jwtCookie,
        'X-CSRF-TOKEN': objectPath.get(decodedCookie, 'xCsrfToken')
    };
};