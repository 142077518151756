import React from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';

import PublicInvite from '../public-invite/PublicInvite.jsx';
import ThankYou from '../thank-you/ThankYou.jsx';
import AdminPanel from '../admin/AdminPanel.jsx';
import ViewAll from '../view-all/ViewAll.jsx';
import ManageMeeting from '../manage-meeting/ManageMeeting.jsx';
import TextingTerms from '../static-pages/TextingTerms.jsx';
import Root404 from '../root-404/Root404.jsx';

class RouteConfig extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <Switch>
                <Route
                    exact path="/admin"
                    component={AdminPanel}
                />
                <Route
                    exact path="/texting-terms"
                    component={TextingTerms}/>
                <Route
                    exact path="/:meeting_id"
                    component={PublicInvite}
                />
                <Redirect
                    exact from="/manage-meeting/:group_trip_id"
                    to='/manage-meeting/:group_trip_id/view-all'
                />
                <Route
                    exact path="/manage-meeting/:group_trip_id/view-all"
                    render={(props) =>
                        <ViewAll
                            groupTripId={props.match.params.group_trip_id}
                            history={props.history}
                        />
                    }
                />
                <Route
                    exact path="/manage-meeting/:group_trip_id/:meeting_id"
                    render={(props) =>
                        <ManageMeeting
                            meetingId={props.match.params.meeting_id}
                            groupTripId={props.match.params.group_trip_id}
                        />
                    }
                />
                <Route
                    exact path="/:meeting_id/thank-you"
                    component={ThankYou}
                />
                <Route
                    exact path="/rsvp/:path_to_route"
                    render={(props) =>
                        <Redirect to={`/${props.match.params.path_to_route}`} />
                    }
                />
                <Route
                    component={Root404}
                />
            </Switch>
        );
    }
}

export default withRouter(RouteConfig);