import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import objectPath from 'object-path';
import { ConnectedRouter } from 'connected-react-router';

import { browserHistory } from './main-store.js';

import './../globals/scss/style.scss';

import mainStore from './main-store.js';
import { getSiteCodeFromHostname } from './../globals/utils/url-util.js';
import { loadCustomContent } from './../globals/services/product/product-actions.js';
import GoogleTagManager from '../globals/components/GoogleTagManager.jsx';
import RouteConfig from './RouteConfig.jsx';
import MomentConfig from './MomentConfig.jsx';
import { ThemeProvider } from '@mui/material';
import { lightTheme } from '@eftours/material-ui-gud';

class RSVP extends React.Component {
    componentDidMount() {
        let state = mainStore.getState(),
            hasLoadedContent = objectPath.get(state, 'product.hasLoadedContent');

        if (!hasLoadedContent) {
            let siteCode = getSiteCodeFromHostname();
            mainStore.dispatch(loadCustomContent(siteCode));
        }
    }

    render() {
        return (
            <React.Fragment>
                <GoogleTagManager />
                <Provider store={mainStore}>
                    <ThemeProvider theme={lightTheme}>
                        <MomentConfig />
                        <ConnectedRouter history={browserHistory}>
                            <RouteConfig />
                        </ConnectedRouter>
                    </ThemeProvider>
                </Provider>
            </React.Fragment>
        );
    }
}

ReactDOM.render(<RSVP />, document.getElementById('RsvpAppRoot'));

// 2021-06-01 This comment can be edited to test Github Actions (only triggers on code change).