import jwtDecode from 'jwt-decode';
import objectPath from 'object-path';
import { actions } from '../authentication/authentication-actions.js';

const initialState = {
    jwt: null
};

const userReducer = (state, action) => {
    if (typeof state === 'undefined') {
        return initialState;
    }

    let newState = JSON.parse(JSON.stringify(state)),
        decodedJwt;

    switch (action.type) {
        case actions.LOGIN_EF_USER_SUCCESS:
            decodedJwt = jwtDecode(action.jwt);

            newState.jwt = action.jwt;
            newState.xCsrfToken = objectPath.get(decodedJwt, 'xCsrfToken');
            newState.isAdmin = false;
            newState.customerId = objectPath.get(decodedJwt, 'efUser.CustomerID');
            newState.individualId = objectPath.get(decodedJwt, 'efUser.IndividualId');
            newState.role = objectPath.get(decodedJwt, 'efUser.Type');
            newState.isInternal = objectPath.get(decodedJwt, 'efUser.Internal');
            newState.username = objectPath.get(decodedJwt, 'efUser.username');

            return newState;
        case actions.LOGIN_ADMIN_SUCCESS:
            decodedJwt = jwtDecode(action.jwt);

            newState.jwt = action.jwt;
            newState.xCsrfToken = objectPath.get(decodedJwt, 'xCsrfToken');
            newState.isAdmin = true;
            newState.username = objectPath.get(decodedJwt, 'adminUser.username');

            return newState;
        case actions.LOGOUT_EF_USER:
            newState = initialState;
            return newState;
        default:
            return state;
    }
};

export default userReducer;