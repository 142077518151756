import React from 'react';
import PropTypes from 'prop-types';

import './MainPanel.scss';

import ErrorBoundary from '../../components/ErrorBoundary.jsx';

export default class MainPanel extends React.Component {
    render() {
        return (
            <main className={'rsvp-mainPanel ' + (this.props.className || '')} role="main" tabIndex="-1">
                <ErrorBoundary name="Main Panel Error Boundary">
                    {this.props.children}
                </ErrorBoundary>
            </main>
        );
    }
}

MainPanel.propTypes = {
    className: PropTypes.string
};