import React from 'react';
import PropTypes from 'prop-types';
import objectPath from 'object-path';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import FatalErrorLayout from '../layouts/FatalErrorLayout.jsx';
import { getMeetingImageUrl } from '../globals/utils/url-util.js';

export class Root404 extends React.Component {

    render() {
        return (
            <FatalErrorLayout>
                <Helmet>
                    <title>{this.props.content.pageTitle}</title>
                    <meta property="og:title" content={this.props.content.pageTitle} />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:description" content="" />
                    <meta property="og:image" content={getMeetingImageUrl()} />
                </Helmet>
                <h1>{this.props.content.header}</h1>
                <div dangerouslySetInnerHTML={{__html: this.props.content.helpHtml}} />
            </FatalErrorLayout>
        );
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.root404')
        };
    }
}

Root404.propTypes = {
    content: PropTypes.shape({
        pageTitle: PropTypes.string.isRequired,
        header: PropTypes.string.isRequired,
        helpHtml: PropTypes.string.isRequired
    })
};

export default connect(Root404.mapStateToProps)(Root404);