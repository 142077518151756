import api from './../globals/services/api-request.js';
import { getAuthorizationHeaders } from './../globals/services/authentication/authentication-actions.js';
import { displayGlobalErrorModal } from '../globals/services/global-error/global-error-actions';

/**
 * Action Types
 */

export const actions = {
    LOAD_ALL_MEETINGS_INIT: 'LOAD_ALL_MEETINGS_INIT',
    LOAD_ALL_MEETINGS_SUCCESS: 'LOAD_ALL_MEETINGS_SUCCESS',
    CREATE_MEETING_INIT: 'CREATE_MEETING_INIT',
    CREATE_MEETING_SUCCESS: 'CREATE_MEETING_SUCCESS'
};

/**
 * Async Actions
 */

export const loadAllMeetings = (groupID) => {
    return (dispatch, getState) => {
        let state = getState(),
            headers = getAuthorizationHeaders(state);

        return dispatch({
            type: actions.LOAD_ALL_MEETINGS_INIT,
            response: api.get('/meetings/by-group-id/' + groupID, {headers})
                .then((res) => {
                    if (Array.isArray(res.data)) {
                        return dispatch({
                            type: actions.LOAD_ALL_MEETINGS_SUCCESS,
                            meetings: res.data
                        });
                    } else {
                        dispatch(displayGlobalErrorModal());
                        return Promise.reject();
                    }
                }, (error) => {
                    dispatch(displayGlobalErrorModal(error));
                    return Promise.reject();
                })
        });
    };
};

export const createMeeting = (groupID, meetingPurpose) => {
    return (dispatch, getState) => {
        let state = getState(),
            headers = getAuthorizationHeaders(state);

        return dispatch({
            type: actions.CREATE_MEETING_INIT,
            response: api.post('/meetings/by-group-id/' + groupID + '/create', {
                meetingOptions: {
                    purpose: meetingPurpose
                }
            }, {headers})
                .then((res) => {
                    if (res.data.meetingID) {
                        return dispatch({
                            type: actions.CREATE_MEETING_SUCCESS,
                            createdMeeting: res.data
                        });
                    } else {
                        dispatch(displayGlobalErrorModal());
                        return Promise.reject();
                    }
                }, (error) => {
                    dispatch(displayGlobalErrorModal(error));
                    return Promise.reject();
                })
        });
    };
};