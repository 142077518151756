import { actions } from './shortLink-actions.js';

const initialState = {
    isLoading: false,
    hasLoadFailure: false,
};

const shortLinkReducer = (state, action) => {
    if (typeof state === 'undefined') {
        return initialState;
    }

    switch (action.type) {
        case actions.SHORTEN_LINK_INIT:
            return {
                isLoading: true,
                hasLoadFailure: false
            };

        case actions.SHORTEN_LINK_SUCCESS:
            return {
                isLoading: false,
                hasLoadFailure: false
            };

        case actions.SHORTEN_LINK_ERROR:
            return {
                isLoading: false,
                hasLoadFailure: true
            };

        default:
            return state;
    }
};

export default shortLinkReducer;
