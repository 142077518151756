import objectPath from 'object-path';

import api from './../globals/services/api-request.js';
import { getAuthorizationHeaders } from './../globals/services/authentication/authentication-actions.js';
import { displayGlobalErrorModal } from '../globals/services/global-error/global-error-actions';

/**
 * Action Types
 */

export const actions = {
    SWITCH_ACTIVE_PANEL: 'SWITCH_ACTIVE_PANEL',
    LOAD_EDITABLE_MEETING_INIT: 'LOAD_EDITABLE_MEETING_INIT',
    LOAD_EDITABLE_MEETING_SUCCESS: 'LOAD_EDITABLE_MEETING_SUCCESS',
    LOAD_EDITABLE_MEETING_ERROR: 'LOAD_EDITABLE_MEETING_ERROR',
    GET_LATEST_MEETING_INIT: 'LOAD_LATEST_MEETING_INIT',
    GET_LATEST_MEETING_SUCCESS: 'LOAD_LATEST_MEETING_SUCCESS',
    GET_LATEST_MEETING_ERROR: 'LOAD_LATEST_MEETING_ERROR',
    UPDATE_EDITABLE_MEETING_INIT: 'UPDATE_EDITABLE_MEETING_INIT',
    UPDATE_EDITABLE_MEETING_SUCCESS: 'UPDATE_EDITABLE_MEETING_SUCCESS',
    UPDATE_EDITABLE_MEETING_ERROR: 'UPDATE_EDITABLE_MEETING_ERROR',
    CANCEL_MEETING_INIT: 'CANCEL_MEETING_INIT',
    CANCEL_MEETING_SUCCESS: 'CANCEL_MEETING_SUCCESS',
    INVITE_PANEL_FORM_SOILED: 'INVITE_PANEL_FORM_SOILED',
    INVITE_PANEL_FORM_CLEANED: 'INVITE_PANEL_FORM_CLEANED',
    INVITE_PANEL_EMAIL_PROMPT_SHOWN: 'INVITE_PANEL_EMAIL_PROMPT_SHOWN',
    INVITE_PANEL_EMAIL_PROMPT_HIDDEN: 'INVITE_PANEL_EMAIL_PROMPT_HIDDEN',
    INVITE_PANEL_URL_PROMPT_SHOWN: 'INVITE_PANEL_URL_PROMPT_SHOWN',
    INVITE_PANEL_URL_PROMPT_HIDDEN: 'INVITE_PANEL_URL_PROMPT_HIDDEN',
    UPDATE_MEETING_PREFERENCES_INIT: 'UPDATE_MEETING_PREFERENCES_INIT',
    UPDATE_MEETING_PREFERENCES_SUCCESS: 'UPDATE_MEETING_PREFERENCES_SUCCESS',
    UPDATED_MEETING_EMAIL_LIST: 'UPDATED_MEETING_EMAIL_LIST'
};

/**
 * Action Creators
 */

/**
 * On the manage meeting page, this action is called when the various tabs are selected, which
 * which component is being rendered
 *
 * @param newPanelName - string that is tied in the manage meeting component to display certain
 * components based on its value
 */
export function switchActivePanel(newPanelName) {
    return {
        type: actions.SWITCH_ACTIVE_PANEL,
        newPanelName
    };
}


/**
 * Async Actions
 */

/**
 * Requests the API for the meeting doc via meetingID. This is the private version of this doc, and will
 * be called editableMeeting, as it can be updated by the signed in user. This differs from the
 * public projection of the meeting doc, as this will have some sensitive fields.
 *
 * @param meetingID
 */
export const loadEditableMeeting = (meetingID) => {
    return (dispatch, getState) => {
        let state = getState(),
            headers = getAuthorizationHeaders(state);

        return dispatch({
            type: actions.LOAD_EDITABLE_MEETING_INIT,
            response: api.get('/meetings/' + meetingID, {headers})
                .then((res) => {
                    if (res.data.meetingID) {
                        return dispatch({
                            type: actions.LOAD_EDITABLE_MEETING_SUCCESS,
                            editableMeeting: res.data
                        });
                    } else {
                        dispatch(displayGlobalErrorModal());
                        return Promise.reject();
                    }
                },
                (error) => {
                    dispatch(displayGlobalErrorModal(error));
                    return Promise.reject(error);
                }
            )
        });
    };
};

/**
 * Requests the API for the latest meeting ID that relates to the give groupTripID. If a meeting
 * for that groupTrip does not exist, then one will be created on the back end as a result of this
 * request. The client does not care if that occurs
 *
 * @param groupTripID
 */
export const getLatestMeeting = (groupTripID) => {
    return (dispatch) => {
        let headers = getAuthorizationHeaders();

        return dispatch({
            type: actions.GET_LATEST_MEETING_INIT,
            response: api.get('/meetings/by-group-id/' + groupTripID + '/latest', { headers })
                .then((res) => {
                    return dispatch({
                        type: actions.GET_LATEST_MEETING_SUCCESS,
                        latestMeetingID: res.data.meetingID,
                        isActive: objectPath.get(res, 'data.status.isActive')
                    });
                },
                (error) => {
                    dispatch(displayGlobalErrorModal(error));
                    return Promise.reject(error);
                }
            )
        });
    }
};


/**
 * Requests the API and passes an updatedEditableMeeting via the request. Expects the API
 * to return the newly saved editableMeeting
 *
 * @param meetingID
 * @param updatedEditableMeeting - explicit name for meeting doc that has been updated on the
 * front end
 */
export const updateEditableMeeting = (meetingID, updatedEditableMeeting) => {
    return (dispatch) => {
        let headers = getAuthorizationHeaders();
        
        return dispatch({
            type: actions.UPDATE_EDITABLE_MEETING_INIT,
            response: api.put('/meetings/' + meetingID, {meeting: updatedEditableMeeting}, {headers})
                .then((res) => {
                        return dispatch({
                            type: actions.UPDATE_EDITABLE_MEETING_SUCCESS,
                            updatedEditableMeeting: res.data
                        });
                    },
                    (error) => {
                        dispatch(displayGlobalErrorModal(error));
                        return Promise.reject(error);
                    }
                )
        });
    }
};

/**
 * Hits the API endpoint for cancelling a meeting. Also contains an optional boolean flag for
 * reversing a meeting cancellation, by tacking on a query string to the req
 *
 * @param meetingID
 * @param shouldUncancel {Boolean} - flag for uncancelling a meeting
 */
export const cancelMeeting = (meetingID, shouldUncancel) => {
    return (dispatch) => {
        let headers = getAuthorizationHeaders(),
            uncancelQuery = !!(shouldUncancel) ? '?uncancel=true' : '';

        return dispatch({
            type: actions.CANCEL_MEETING_INIT,
            response: api.put('/meetings/' + meetingID + '/cancel-meeting' + uncancelQuery, {},
                {headers})
                .then((res) => {
                    return dispatch({
                        type: actions.CANCEL_MEETING_SUCCESS,
                        status: res.data.status
                    });
                }, (error) => {
                    dispatch(displayGlobalErrorModal(error));
                    return Promise.reject(error);
                })
        });
    };
};

export const updateMeetingPreferences = (meetingID, meetingPreferences) => {
    return (dispatch) => {
        let headers = getAuthorizationHeaders();

        return dispatch({
            type: actions.UPDATE_MEETING_PREFERENCES_INIT,
            response: api.put('/meetings/' + meetingID + '/meeting-preferences',
                {meetingPreferences}, {headers})
                .then((res) => {
                    return dispatch({
                        type: actions.UPDATE_MEETING_PREFERENCES_SUCCESS,
                        meetingPreferences: res.data.meetingPreferences
                    })
                }, (error) => {
                    dispatch(displayGlobalErrorModal(error));
                    return Promise.reject(error);
                })
        })
    };
};

export const soilInvitePanelForm = () => {
    return (dispatch) => {
        return dispatch({
            type: actions.INVITE_PANEL_FORM_SOILED
        });
    };
};

export const cleanInvitePanelForm = () => {
    return (dispatch) => {
        return dispatch({
            type: actions.INVITE_PANEL_FORM_CLEANED
        });
    };
};

export const showPromptToEmailRespondees = () => {
    return (dispatch) => {
        return dispatch({
            type: actions.INVITE_PANEL_EMAIL_PROMPT_SHOWN
        });
    };
};

export const hidePromptToEmailRespondees = () => {
    return (dispatch) => {
        return dispatch({
            type: actions.INVITE_PANEL_EMAIL_PROMPT_HIDDEN
        });
    };
};


export const showPromptToShortenUrl = () => {
    return (dispatch) => {
        return dispatch({
            type: actions.INVITE_PANEL_URL_PROMPT_SHOWN
        });
    };
};

export const hidePromptToShortenUrl = () => {
    return (dispatch) => {
        return dispatch({
            type: actions.INVITE_PANEL_URL_PROMPT_HIDDEN
        });
    };
};

export const updateMeetingEmailList = (parentFlag, studentFlag) => {
    return (dispatch) => {
        return dispatch({
            type: actions.UPDATED_MEETING_EMAIL_LIST,
            parentFlag: parentFlag,
            studentFlag: studentFlag
        });
    };
};