import objectPath from 'object-path';
import { createSelector } from 'reselect';

import { globalErrorActions } from './global-error-actions.js';

const initialState = {
    errors: []
};

function globalErrorReducer(state, action) {
    if(typeof state === 'undefined') {
        return initialState;
    }

    let newState = JSON.parse(JSON.stringify(state));

    switch(action.type) {
        case globalErrorActions.GLOBAL_ERROR_MODAL_OPEN: {
            let status = objectPath.get(action, 'error.response.status', null),
                isApiRequest = objectPath.has(action, 'error.request');

            newState.errors.push({
                message: action.customErrorMessage || null,
                shouldRedirectToLogin: action.shouldRedirectToLogin || status === 401 || status === 403,
                status,
                isApiRequest
            });

            break;
        } 
        case globalErrorActions.GLOBAL_ERROR_CLEAR_ONE:
            newState.errors = newState.errors.filter((error, index) => {
                return index !== 0;
            });
            break;
        case globalErrorActions.GLOBAL_ERROR_CLEAR_ALL:
            newState = initialState;
            break;
        default:
            newState = state;
            break;
    }

    return newState;
}

// selector functions
export const getHasError = state => state.globalError.errors.length > 0;
export const getErrors = state => state.globalError.errors;

export const getDisplayedError = createSelector(
    getErrors,
    (errors) => {
        // If any of the errors are going to redirect to login, display that one first
        let priorityError = errors.find((error) => {
            return error.shouldRedirectToLogin;
        });

        // Otherwise show the first error
        return priorityError || errors[0] || {};
    }
);

export default globalErrorReducer;