import React from 'react';
import objectPath from 'object-path';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import './ViewAll.scss';
import emptyStateSvg from '../../static/img/view_all_empty_state.svg';

import { loadAllMeetings } from './view-all-actions.js';
import { checkAuthentication } from './../globals/services/authentication/authentication-actions.js';
import { createMeeting } from './view-all-actions.js';
import MainLayout from './../layouts/MainLayout.jsx';
import UserHeader from './../manage-meeting/UserHeader.jsx';
import PageLoadingSpinner from './../globals/components/page-loading-spinner/PageLoadingSpinner.jsx';
import MeetingCard from './MeetingCard.jsx';
import Modal from '../globals/components/modal/Modal.jsx';
import BackToOverview from '../globals/components/back-to-overview/BackToOverview.jsx';
import FatalErrorLayout from './../layouts/FatalErrorLayout.jsx';

export const meetingPurposes = {
    RECRUITMENT: 'recruitment',
    OTHER: 'other'
};

class ViewAll extends React.Component {
    constructor(props) {
        super(props);

        this.getAllMeetings = this.getAllMeetings.bind(this);
        this.handleNewMeetingClick = this.handleNewMeetingClick.bind(this);
        this.handleCreateMeetingClick = this.handleCreateMeetingClick.bind(this);
        this.handleLoadingError = this.handleLoadingError.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.renderAllMeetings = this.renderAllMeetings.bind(this);
        this.renderNoMeetings = this.renderNoMeetings.bind(this);
        this.renderActiveMeetings = this.renderActiveMeetings.bind(this);
        this.renderNoActive = this.renderNoActive.bind(this);
        this.renderPastMeetings = this.renderPastMeetings.bind(this);
        this.renderNewMeetingButton = this.renderNewMeetingButton.bind(this);
        this.renderFatalError = this.renderFatalError.bind(this);

        this.state = {
            isLoading: true,
            isNewMeetingModalOpen: false
        };
    }

    componentDidMount() {
        this.setState({
            width: window.innerWidth
        });

        checkAuthentication()
            .then(this.getAllMeetings, this.handleLoadingError);
    }

    getAllMeetings() {
        this.setState({
            isLoading: true,
            hasFatalError: false
        });

        return this.props.dispatch(loadAllMeetings(this.props.groupTripId)).response
            .then(() => {
                this.setState({
                    isLoading: false
                });
            }, this.handleLoadingError);
    }

    handleLoadingError(error) {
        this.setState({
            isLoading: false,
            hasFatalError: true,
            errorStatusCode: objectPath.get(error, 'response.status', 500)
        });
    }

    handleNewMeetingClick() {
        this.setState({
            isNewMeetingModalOpen: true
        });
    }

    handleCreateMeetingClick(meetingPurpose) {
        //Don't fire multiple meeting creations
        if (!this.state.isCreatingMeeting) {
            this.setState({
                isCreatingMeeting: true
            });

            return this.props.dispatch(createMeeting(this.props.groupTripId, meetingPurpose)).response
                .then((createdMeetingAction) => {
                    let urlToRedirectTo = '/manage-meeting/' + this.props.groupTripId + '/' +
                        createdMeetingAction.createdMeeting.meetingID;

                    this.setState({
                        isCreatingMeeting: false
                    });
                    this.closeModal();

                    this.props.history.push(urlToRedirectTo);
                });
        }
    }

    closeModal() {
        if (this.state.isNewMeetingModalOpen === true) {
            this.setState({
                isNewMeetingModalOpen: false
            });
        }
    }

    render() {
        return ( this.state.hasFatalError
            ? this.renderFatalError()

            : <MainLayout className="viewAll">
                <Helmet>
                    <title>{this.props.content.pageTitle}</title>
                    <meta property="og:title" content={this.props.content.pageTitle} />
                </Helmet>
                <UserHeader />
                <Modal
                    isOpen={this.state.isNewMeetingModalOpen}
                    shouldCloseOnOverlayClick={true}
                    timeout={300}
                    classNames={'newMeeting-modal'}
                    onRequestClose={this.closeModal}
                    modalID="newMeeting"
                    header={this.props.content.newMeetingModalHeader}
                >
                    <section>
                        <h2>{this.props.content.enrollmentMeeting}</h2>
                        <button
                            type="button"
                            id="createRecruitmentMeetingButton"
                            className="newMeeting-button"
                            onClick={() => this.handleCreateMeetingClick(meetingPurposes.RECRUITMENT)}
                            tabIndex="0"
                        >
                            {this.props.content.enrollmentMeetingButton}
                        </button>
                        <p>{this.props.content.enrollmentMeetingDescription}</p>
                    </section>
                    <section>
                        <h2>{this.props.content.otherMeeting}</h2>
                        <button
                            type="button"
                            id="createOtherMeetingButton"
                            className="newMeeting-button"
                            onClick={() => this.handleCreateMeetingClick(meetingPurposes.OTHER)}
                            tabIndex="0"
                        >
                            {this.props.content.otherMeetingButton}
                        </button>
                        <p>{this.props.content.otherMeetingDescription}</p>
                    </section>
                </Modal>
                <div className="rsvp-wideContainer viewAll-wrapper">
                    <BackToOverview groupTripId={this.props.groupTripId} />
                    {this.state.isLoading ?
                        <PageLoadingSpinner
                            containerHeight={'initial'}
                            spinnerWidth="100px"
                        /> :
                        this.renderAllMeetings()
                    }
                </div>
            </MainLayout>
        );
    }

    renderAllMeetings() {
        const activeMeetings = this.props.meetings.filter((meeting)=>{
            return meeting.status.isActive;
        });

        const pastMeetings = this.props.meetings.filter((meeting)=>{
            return !meeting.status.isActive;
        });

        return (
            <React.Fragment>
                {
                    this.props.meetings.length === 0 &&
                    this.renderNoMeetings()
                }
                {
                    activeMeetings.length > 0 &&
                    this.renderActiveMeetings(activeMeetings)
                }
                {
                    activeMeetings.length === 0 && this.props.meetings.length > 0 &&
                    this.renderNoActive()
                }
                {
                    pastMeetings.length > 0 &&
                    this.renderPastMeetings(pastMeetings)
                }
            </React.Fragment>
        );
    }

    renderNoMeetings() {
        return (
            <section className="viewAll-noMeetings">
                <h1
                    className="viewAll-header"
                    dangerouslySetInnerHTML={{__html: this.props.content.noMeetingsHeaderHtml}}
                />
                <div>
                    { this.renderNewMeetingButton() }
                </div>
                <img className="viewAll-noMeetings-img" src={emptyStateSvg} />
            </section>
        );
    }

    renderActiveMeetings(activeMeetings) {
        let activeMeetingElements = activeMeetings.map((activeMeeting) => {
            return (
                <MeetingCard
                    key={activeMeeting.meetingID}
                    meeting={activeMeeting}
                    groupTripId={this.props.groupTripId} />
            );
        }),
            meetingHeaderText = activeMeetingElements.length > 1 ?
                this.props.content.activeMeetingHeaderPlural :
                    this.props.content.activeMeetingHeader;

        return (
            <section>
                { this.renderNewMeetingButton() }
                <h1 className="viewAll-header">{meetingHeaderText}</h1>
                <div className="viewAll-meetingCard-container">
                    {activeMeetingElements}
                </div>
            </section>
        );
    }

    renderNoActive() {
        return (
            <section>
                { this.renderNewMeetingButton() }
                <h1 className="viewAll-header">{this.props.content.activeMeetingHeader}</h1>
                <div className="viewAll-noActive">
                    <i className="viewAll-noActive-icon" />
                    <p className="viewAll-noActive-text">{ this.props.content.noActiveMeeting }</p>
                </div>
            </section>
        );
    }

    renderPastMeetings(pastMeetings) {
        return (
            <section>
                <h1 className="viewAll-header">{this.props.content.pastMeetingsHeader}</h1>
                <div className="viewAll-meetingCard-container">
                    {pastMeetings.map((meeting, index) => {
                        return <MeetingCard key={'meetingCard' + index} meeting={meeting} groupTripId={this.props.groupTripId} />;
                    })}
                </div>
            </section>
        );
    }

    renderNewMeetingButton() {
        if (Array.isArray(this.props.meetings)) {
            return (
                <button
                    type="button"
                    id="newMeetingButton"
                    className="viewAll-newMeetingButton"
                    onClick={this.handleNewMeetingClick}
                    tabIndex="0"
                >
                    { this.props.content.newMeetingButton }
                </button>
            );
        } else {
            return null;
        }
    }

    renderFatalError() {
        return (
            <FatalErrorLayout fatalError={this.state.fatalError}>
                <Helmet>
                    <title>{this.props.content.pageTitle}</title>
                    <meta property="og:title" content={this.props.content.pageTitle} />
                </Helmet>
                <h1>{this.props.content.errorGroupNotFound}</h1>
                <p>{this.props.content.errorGroupNotFoundHelp}</p>
            </FatalErrorLayout>
        );
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.viewAll'),
            meetings: objectPath.get(state, 'viewAll.meetings')
        };
    }
}

ViewAll.propTypes = {
    groupTripId: PropTypes.string,
    content: PropTypes.shape({
        pageTitle: PropTypes.string.isRequired,
        errorGroupNotFound: PropTypes.string.isRequired,
        errorGroupNotFoundHelp: PropTypes.string.isRequired,
        noMeetingsHeaderHtml: PropTypes.string.isRequired,
        activeMeetingHeader: PropTypes.string.isRequired,
        activeMeetingHeaderPlural: PropTypes.string.isRequired,
        noActiveMeeting: PropTypes.string.isRequired,
        pastMeetingsHeader: PropTypes.string.isRequired,
        newMeetingButton: PropTypes.string.isRequired,
        newMeetingButtonDisabledText: PropTypes.string.isRequired,
        newMeetingModalHeader: PropTypes.string.isRequired,
        enrollmentMeeting: PropTypes.string.isRequired,
        enrollmentMeetingDescription: PropTypes.string.isRequired,
        enrollmentMeetingButton: PropTypes.string.isRequired,
        otherMeeting: PropTypes.string.isRequired,
        otherMeetingDescription: PropTypes.string.isRequired,
        otherMeetingButton: PropTypes.string.isRequired
    })
};

export default connect(ViewAll.mapStateToProps)(ViewAll);