import React from 'react';
import PropTypes from 'prop-types';
import { connect } from  'react-redux';

import './ErrorModal.scss';

import Modal from '../globals/components/modal/Modal.jsx';
import { clearAllGlobalErrors } from '../globals/services/global-error/global-error-actions.js';
import { getHasError, getDisplayedError } from '../globals/services/global-error/global-error-reducer.js';
import { redirectToEvaLogin } from '../globals/utils/url-util';
import objectPath from 'object-path';

class ErrorModal extends React.Component {

    constructor(props) {
        super(props);

        this.getDefaultErrorMessage = this.getDefaultErrorMessage.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);

        this.state = {
            isClientReady: false
        };
    }

    componentDidMount() {
        this.setState({
            isClientReady: true
        });
    }

    getDefaultErrorMessage() {
        let defaultErrorMessage,
            status = this.props.displayedError.status,
            isApiRequest = this.props.displayedError.isApiRequest;

        switch(status) {
            case 401:
                defaultErrorMessage = this.props.content.defaultError401;
                break;
            case 403:
                defaultErrorMessage = this.props.content.defaultError403;
                break;
            case 444:
            case 503:
                defaultErrorMessage = this.props.content.defaultError503;
                break;
            default:
                defaultErrorMessage = this.props.content.defaultErrorGeneric;
                break;
        }

        // If error.request exists, but no error.response, we know this is an api request
        // that failed due to no Internet connectivity
        if (!status && isApiRequest) {
            defaultErrorMessage = this.props.content.defaultErrorConnectivity;
        }

        return defaultErrorMessage;
    }

    closeModal() {
        let shouldRedirectToLogin = this.props.displayedError.shouldRedirectToLogin;

        this.props.clearAllGlobalErrors();

        if (shouldRedirectToLogin) {
            redirectToEvaLogin();
        }
    }

    handleCloseButtonClick() {
        this.closeModal();
    }

    render() {
        return (
            <Modal
                isOpen={(this.props.hasError && this.state.isClientReady)}
                shouldCloseOnOverlayClick={true}
                timeout={300}
                classNames={'global-error-modal'}
                onRequestClose={this.closeModal}
                shouldHideXButton={true}
                modalID="error"
                header={this.props.content.genericErrorHeader}
                description={(
                    this.props.displayedError.message ?
                    this.props.displayedError.message : this.getDefaultErrorMessage()
                )}
            >
                <button
                    className="global-error-modal-button"
                    onClick={this.handleCloseButtonClick}
                    type="button"
                    tabIndex="0"
                >
                    {
                        this.props.displayedError.shouldRedirectToLogin ?
                            this.props.content.loginButton : this.props.content.returnButton
                    }
                </button>
            </Modal>
        );
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.globals.errorModal'),
            hasError: getHasError(state),
            displayedError: getDisplayedError(state),
            errors: state.globalError.errors
        }
    }
}

ErrorModal.propTypes = {
    content: PropTypes.shape({
        genericErrorHeader: PropTypes.string,
        defaultError401: PropTypes.string,
        defaultError403: PropTypes.string,
        defaultError503: PropTypes.string,
        defaultErrorGeneric: PropTypes.string,
        defaultErrorConnectivity: PropTypes.string,
        loginButton: PropTypes.string,
        returnButton: PropTypes.string
    }),
    errors: PropTypes.arrayOf(
        PropTypes.shape({
            message: PropTypes.string,
            shouldRedirectToLogin: PropTypes.bool,
            status: PropTypes.number,
            isApiRequest: PropTypes.bool
        })
    ).isRequired
};

const mapDispatchToProps = {
    clearAllGlobalErrors
};

export default connect(ErrorModal.mapStateToProps, mapDispatchToProps)(ErrorModal);