import React from 'react';
import objectPath from 'object-path';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class CollectionFields extends React.Component {
    constructor(props) {
        super(props);

        this.handleNumAttendeesBlur = this.handleNumAttendeesBlur.bind(this);
        this.handleNumAttendeesKeyDown = this.handleNumAttendeesKeyDown.bind(this);
        this.decrementNumOfAttendees = this.decrementNumOfAttendees.bind(this);
        this.incrementNumOfAttendees = this.incrementNumOfAttendees.bind(this);
        this.getErrorClass = this.getErrorClass.bind(this);
    }

    handleNumAttendeesBlur(values, setFieldValue) {
        let numOfAttendees = parseInt(values['numOfAttendees']);

        if (isNaN(numOfAttendees)){
            setFieldValue('numOfAttendees', 1);
        }
    }

    handleNumAttendeesKeyDown(values, setFieldValue, event) {
        if (event.keyCode === 109 || event.keyCode === 189){
            this.decrementNumOfAttendees(values, setFieldValue);
        }
        else if (event.keyCode === 107 || event.keyCode === 187){
            this.incrementNumOfAttendees(values, setFieldValue);
        }
    }

    decrementNumOfAttendees(values, setFieldValue) {
        let numOfAttendees = parseInt(values['numOfAttendees']);
        let updatedNumOfAttendees =
            numOfAttendees > 1 ? numOfAttendees - 1 : 1;

        setFieldValue('numOfAttendees', updatedNumOfAttendees);
    }

    incrementNumOfAttendees(values, setFieldValue) {
        let numOfAttendees = parseInt(values['numOfAttendees']);
        let updatedNumOfAttendees =
            numOfAttendees < 8 ? numOfAttendees + 1 : 8;

        setFieldValue('numOfAttendees', updatedNumOfAttendees);
    }

    getErrorClass(fieldName) {
        return (this.props.formikBag.touched[fieldName] || this.props.formikBag.submitCount > 0)
            && this.props.formikBag.errors[fieldName]
                ? ' error'
                : '';
    }

    render() {
        const renderCollectionFields = ({
            handleChange,
            handleBlur,
            setFieldValue,
            values,
            errors
        }) => {
            //eslint-ignore next-line
            const renderField = fieldName =>
                <div className={'fieldset ' + fieldName}>
                    <input
                        type="text"
                        id={fieldName}
                        name={fieldName}
                        aria-labelledby={fieldName + 'Label'}
                        aria-required="true"
                        placeholder=" "
                        className={
                            'rsvp-textInput rsvp-textInput--half' +
                            this.getErrorClass(fieldName)
                        }
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    <label
                        htmlFor={fieldName}
                        className={this.getErrorClass(fieldName)}
                        id={fieldName + 'Label'}
                        role={(this.getErrorClass(fieldName) ? 'alert': '')}
                    >
                        {this.props.content[fieldName + 'Placeholder']}&nbsp;
                        {this.getErrorClass(fieldName) ? errors[fieldName] : ''}
                    </label>
                </div>;

            const renderPhoneField = fieldName =>
                <div className={'fieldset ' + fieldName}>
                    <InputMask
                        type="text"
                        id={fieldName}
                        name={fieldName}
                        placeholder=" "
                        className={
                            'rsvp-textInput rsvp-textInput--half' +
                            this.getErrorClass(fieldName)
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        mask="(999) 999-9999"
                        maskChar=" "
                    />
                    <label
                        htmlFor={fieldName}
                        className={this.getErrorClass(fieldName)}
                        id={fieldName + 'Label'}
                        role={(this.getErrorClass(fieldName) ? 'alert': '')}
                    >
                        {this.props.content[fieldName + 'Placeholder']}&nbsp;
                        {this.getErrorClass(fieldName) ? errors[fieldName] : ''}
                    </label>
                </div>;

            let formStructure = Object.keys(this.props.content.displayValues.formFields)
                .map((keyName) => {
                    if (this.props.content.displayValues.formFields[keyName]) {
                        let contentSection = this.props.content.displayValues.formFields[keyName],
                            isAttendingExtension = this.props.isAttending ? 'YesAttending' :
                                'NotAttending',
                            headerElement =
                                <h4>{this.props.content[`${keyName}${isAttendingExtension}Header`]}</h4>,
                            fieldElements = contentSection.fields.map((fieldName) => {
                                let isPhoneField = fieldName.toLowerCase().indexOf('phone') >= 0;

                                return isPhoneField ? (
                                        <React.Fragment key={fieldName}>
                                            {renderPhoneField(fieldName)}
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment key={fieldName}>
                                            {renderField(fieldName)}
                                        </React.Fragment>
                                );
                            });

                        return (
                            <React.Fragment key={keyName}>
                                {contentSection.showHeader && headerElement}
                                {fieldElements}
                            </React.Fragment>
                        );
                    } else {
                        return null;
                    }
                });


            return (
                <React.Fragment>
                    {formStructure}
                    {
                        this.props.isAttending &&
                        !objectPath.get(this.props.content, 'displayValues.shouldHideNumAttending')
                            ? renderNumOfAttendees(values, handleChange, handleBlur, setFieldValue)
                            : null
                    }
                    {
                        objectPath.get(this.props.content, 'displayValues.shouldShowConsentDescription')
                            ? <p id="consentDescription"
                                 className="collectionForm-consentDescription"
                                 dangerouslySetInnerHTML={{__html: this.props.content.consentDescription}}
                                 tabIndex="0">
                            </p>
                            : null
                    }
                </React.Fragment>
            );
        };

        const renderNumOfAttendees = (values, handleChange, handleBlur, setFieldValue) => {
            return (
                <section
                    id="numAttendingSection"
                    className="collectionForm-numOfAttendees"
                >

                    <div className="collectionForm-numOfAttendeesSelector">
                        <span
                            className="collectionForm-changeNumOfAttendees"
                            id="collectionDecrementAttendees"
                            onClick={() => this.decrementNumOfAttendees(values, setFieldValue)}
                            disabled={values['numOfAttendees'] <= 1}
                        >
                        -
                        </span>
                        <InputMask
                            type="text"
                            name="numOfAttendees"
                            id="numOfAttendees"
                            aria-required="true"
                            placeholder="#"
                            className={
                                'collectionForm-numOfAttendeesDisplay' +
                                this.getErrorClass('numOfAttendees')
                            }
                            formatChars={{'8': '[1-8]'}}
                            onChange={handleChange}
                            onBlur={()=>{this.handleNumAttendeesBlur(values, setFieldValue)}}
                            mask="8"
                            maskChar="#"
                            value={values.numOfAttendees}
                            onKeyDown={(event)=>{this.handleNumAttendeesKeyDown(values, setFieldValue, event)}}
                        />
                        <span
                            className="collectionForm-changeNumOfAttendees"
                            id="collectionIncrementAttendees"
                            onClick={() => this.incrementNumOfAttendees(values, setFieldValue)}
                        >
                        +
                        </span>
                    </div>
                    <label htmlFor="numOfAttendees" className="collectionForm-numOfAttendeesDescription">
                        {this.props.content.numOfAttendeesDescription}
                    </label>
                </section>
            );
        };

        return renderCollectionFields(this.props.formikBag);
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.publicInvite.rsvpForm'),
            isAttending: objectPath.get(state, 'publicInvite.isAttending')
        };
    }
}

CollectionFields.propTypes = {
    content: PropTypes.shape({
        studentFieldsYesAttendingHeader: PropTypes.string.isRequired,
        studentFieldsNotAttendingHeader: PropTypes.string.isRequired,
        studentFirstNamePlaceholder: PropTypes.string.isRequired,
        studentLastNamePlaceholder: PropTypes.string.isRequired,
        parentFieldsYesAttendingHeader: PropTypes.string,
        parentFieldsNotAttendingHeader: PropTypes.string,
        parentFirstNamePlaceholder: PropTypes.string.isRequired,
        parentLastNamePlaceholder: PropTypes.string.isRequired,
        contactEmailPlaceholder: PropTypes.string.isRequired,
        contactPhonePlaceholder: PropTypes.string.isRequired,
        numOfAttendeesDescription: PropTypes.string.isRequired,
        consentDescription: PropTypes.string,
        displayValues: PropTypes.object.isRequired
    }),
    formikBag: PropTypes.object
};

export default connect(CollectionFields.mapStateToProps)(CollectionFields);