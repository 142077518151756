import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rsvpGlobal from './../globals/utils/global-constants.js';
import mainReducer from './main-reducer.js';

const loggerMiddleware = createLogger();
export const browserHistory = createBrowserHistory();

// Configure project to allow redux dev tools 
const composeEnhancers = 
    rsvpGlobal.ENVIRONMENT === "dev"
    ? ( window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose )
    : compose;

const store = createStore(
    mainReducer(browserHistory),
    composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware, routerMiddleware(browserHistory)))
);

export default store;
