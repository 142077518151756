import React from 'react';
import { connect } from 'react-redux';
import Textarea from 'react-textarea-autosize';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import objectPath from 'object-path';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import './InvitePanel.scss';

import xSvg from '../../static/img/x_000.svg';
import PromptBeforeUnload from '../globals/components/prompt-before-unload/PromptBeforeUnload.jsx';
import PageLoadingSpinner from '../globals/components/page-loading-spinner/PageLoadingSpinner.jsx';
import {
    soilInvitePanelForm, cleanInvitePanelForm, hidePromptToEmailRespondees, hidePromptToShortenUrl,
    updateMeetingPreferences
} from './manage-meeting-actions.js';
import Modal from '../globals/components/modal/Modal.jsx';
import { getAllContactEmails } from './manage-meeting-reducer.js';
import EmailAll from '../globals/components/email-all/EmailAll.jsx';
import TimePicker from './TimePicker.jsx';

class CustomDateInput extends React.Component {
    /*
    Due to accessibility concerns, <DatePicker /> no longer supports
    readOnly to prevent the mobile keyboard from opening. Despite
    this concern, the component is literally useless without it,
    so we're going to hack it back in with a stateful custom component.
    When the <Datepicker /> mounts, we use the ref to manually add "readOnly",
    thus bypassing <Datepicker />'s own implementation, which would
    normally disable the click handler altogether.
    
    // https://github.com/Hacker0x01/react-datepicker/issues/1480
    */
    render() {
        return <input {...this.props} type="text" ref={el => this.inputRef = el} />
    }
}

class InvitePanel extends React.Component {
    constructor(props) {
        super(props);

        this.getMailtoHref = this.getMailtoHref.bind(this);
        this.onDatepickerRef = this.onDatepickerRef.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleDateBlur = this.handleDateBlur.bind(this);
        this.handleDateClearClick = this.handleDateClearClick.bind(this);
        this.handleUpdateEmailPreference = this.handleUpdateEmailPreference.bind(this);
        this.handleUpdatePhoneTexting = this.handleUpdatePhoneTexting.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.handlePreviewClick = this.handlePreviewClick.bind(this);
        this.openSaveReminderModal = this.openSaveReminderModal.bind(this);
        this.closeSaveReminderModal = this.closeSaveReminderModal.bind(this);

        this.state = {
            isSaveReminderModalOpen: false,
            updateMeetingPreferencesPending: false,
            updatePhoneTextingPending: false,
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        // We need to know if the form has unsaved changes, outside the scope of this component
        if (newProps.formikBag.dirty && !this.props.formikBag.dirty) {
            this.props.soilInvitePanelForm();
        }
        else if (!newProps.formikBag.dirty && this.props.formikBag.dirty) {
            this.props.cleanInvitePanelForm();
        }

        if (newProps.canContactParents !== this.props.canContactParents ||
            newProps.canSendTextMessagesToAttendees !== this.props.canSendTextMessagesToAttendees) {
            window.setTimeout(() => {
                this.setState({
                    updateMeetingPreferencesPending: false,
                    updatePhoneTextingPending: false,
                    recentlyUpdated: true
                });
            }, 1000);
        }
    }

    getMailtoHref() {
        let mailtoHref = 'mailto:';
        let recipients = this.props.allContactEmails;
        //TODO: get real copy mailto fields
        mailtoHref += recipients.join(';');
        mailtoHref += '&subject=Tour%20Meeting%20Updates';

        return mailtoHref;
    }

    onDatepickerRef(el) {
        if (el && el.input) {
            el.input.inputRef.readOnly = true;
        }
    }

    shouldUrlPromptShow() {
        return objectPath.get(this.props.content, 'displayValues.isMeetingUrlWarningEnabled', false) &&
            this.props.isInviteUrlPromptShown;
    }

    handleDateChange(selectedDate) {
        this.props.formikBag.setFieldValue('date', moment.utc(selectedDate));
    }

    handleDateBlur() {
        // The default date is only present in the display, not in the data.
        // But if the user clicks the date picker, does NOT change the date, and then blurs it,
        // the default date needs to save as real data, and thus the time picker becomes enabled.
        // In this case the onChange event will not fire on its own, so we trigger it here.

        if (this.props.formikBag.values.date === this.props.defaultStartDate) {
            this.handleDateChange(this.props.defaultStartDate);
        }
    }

    handleDateClearClick() {
        this.props.formikBag.setFieldValue('date', null);
    }

    handlePreviewClick(event) {
        if (this.props.formikBag.dirty) {
            event.preventDefault();
            this.openSaveReminderModal();
        }
    }

    openSaveReminderModal() {
        if (!this.state.isSaveReminderModalOpen) {
            this.setState({
                isSaveReminderModalOpen: true
            });
        }
    }

    closeSaveReminderModal() {
        if (this.state.isSaveReminderModalOpen) {
            this.setState({
                isSaveReminderModalOpen: false
            });
        }
    }

    viewAllURL() {
        return '/manage-meeting/' + this.props.editableMeeting.groupID + '/view-all';
    }

    handleUpdateEmailPreference(e) {
        e.preventDefault();

        if (this.props.editableMeeting.meetingPreferences &&
            !this.state.updateMeetingPreferencesPending) {
            let updatedMeetingPreferences = {
                canContactParents: !this.props.canContactParents,
                canSendTextMessagesToAttendees: this.props.canSendTextMessagesToAttendees
            };

            this.setState({
                updateMeetingPreferencesPending: true,
                recentlyUpdated: false
            });

            return this.props.updateMeetingPreferences(this.props.editableMeeting.meetingID,
                updatedMeetingPreferences);
        }
    }

    handleUpdatePhoneTexting(e) {
        e.preventDefault();

        if (this.props.editableMeeting.meetingPreferences &&
            !this.state.updatePhoneTextingPending) {
            const updatedMeetingPreferences = {
                canContactParents: this.props.canContactParents,
                canSendTextMessagesToAttendees: !this.props.canSendTextMessagesToAttendees
            };

            this.setState({
                updatePhoneTextingPending: true,
                recentlyUpdated: false
            });

            return this.props.updateMeetingPreferences(this.props.editableMeeting.meetingID,
                updatedMeetingPreferences);
        }
    }

    render() {
        return this.renderForm(this.props.formikBag);
    }

    renderForm({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        isSubmitting,
        status,
        dirty
    }) {
        // Some products show the tour destinations on the public invite using the "showDestinationsInHeader" flag, 
        // and so they need to be able to edit the destinations here in case the data sucks
        const showDestinationsField = objectPath.get(this.props.content, 'displayValues.showDestinationsField', false);

        return (
            <form aria-labelledby="invite-panel-tab">
                <PromptBeforeUnload
                    isUnloadListenerActive={dirty}
                    message="You have unsaved changes. Are you sure you want to leave?"
                />
                <section
                    className={
                        'invitePanel' +
                        (this.props.editableMeeting.status.isActive ?
                            '' : ' invitePanel--disabled') +
                        ' invitePanel--' + this.props.siteCode
                    }
                    role="tabpanel"
                >
                    <CSSTransition
                        in={this.props.isInviteEmailPromptShown}
                        timeout={300}
                        classNames={'invitePanel-warningPrompt'}
                        unmountOnExit
                    >
                        {() =>
                            <div className="invitePanel-warningPrompt-wrapper invitePanel-emailPrompt-wrapper">
                                <div
                                    id="updatedMeetingPrompt"
                                    className="invitePanel-warningPrompt invitePanel-emailPrompt"
                                >
                                    <span>
                                        {this.props.content.updatePrompt1}&nbsp;
                                        <EmailAll className="emailLink">
                                            {this.props.content.updatePromptEmail}
                                        </EmailAll>
                                        &nbsp;
                                        {this.props.content.updatePrompt2}
                                    </span>
                                    <button
                                        className="invitePanel-warningPrompt-close"
                                        onClick={this.props.hidePromptToEmailRespondees}
                                        type="button"
                                    >
                                        <span>{this.props.content.closeButton}</span>
                                    </button>
                                </div>
                            </div>
                        }
                    </CSSTransition>

                    <CSSTransition
                        in={this.shouldUrlPromptShow()}
                        timeout={300}
                        classNames={'invitePanel-warningPrompt'}
                        unmountOnExit
                    >
                        {() =>
                            <div className="invitePanel-warningPrompt-wrapper invitePanel-urlPrompt-wrapper">
                                <div
                                    id="urlPrompt"
                                    className="invitePanel-warningPrompt invitePanel-urlPrompt"
                                >
                                    <span>
                                        {this.props.content.urlPrompt}
                                    </span>
                                    <button
                                        className="invitePanel-warningPrompt-close"
                                        onClick={this.props.hidePromptToShortenUrl}
                                        type="button"
                                    >
                                        <span>{this.props.content.closeButton}</span>
                                    </button>
                                </div>
                            </div>
                        }
                    </CSSTransition>

                    {this.props.editableMeeting.status.isCancelled &&
                        <div id="invitePanelCancelledBanner"
                            className="invitePanel-cancelledBanner-wrapper">
                            <div
                                id="cancelledMeetingPrompt"
                                className="invitePanel-cancelledBanner"
                            >
                                <span>
                                    {this.props.content.cancelledPrompt1}&nbsp;
                                    <EmailAll className="emailLink">
                                        {this.props.content.cancelledPromptEmail}
                                    </EmailAll>
                                    &nbsp;
                                    {this.props.content.cancelledPrompt2}
                                    <a href={this.viewAllURL()}>
                                        {this.props.content.cancelledPrompt3}
                                    </a>
                                </span>
                            </div>
                        </div>
                    }

                    {!this.props.editableMeeting.status.isCancelled &&
                        this.props.editableMeeting.status.isPast &&
                        <div className="invitePanel-pastBanner-wrapper">
                            <div
                                className="invitePanel-pastBanner"
                            >
                                <span>
                                    {this.props.content.pastPrompt1}
                                    &nbsp;
                                    <a href={this.viewAllURL()}>
                                        {this.props.content.pastPrompt2}
                                    </a>
                                </span>
                            </div>
                        </div>
                    }

                    <div className="invitePanel-actions">
                        {status === 'success' &&
                            <span
                                id="changesSavedNotification"
                                className="invitePanel-saved-notification">
                                {this.props.content.saved}
                            </span>
                        }
                        <button
                            type="button"
                            role="submit"
                            onClick={handleSubmit}
                            className="invitePanel-saveButton"
                            id="invitePanelSaveButton"
                            disabled={(
                                !this.props.formikBag.dirty ||
                                !this.props.formikBag.isValid ||
                                this.props.formikBag.isSubmitting ||
                                !this.props.editableMeeting.status.isActive
                            )}
                        >
                            {!isSubmitting && this.props.content.saveButton}
                            {isSubmitting &&
                                <PageLoadingSpinner containerHeight={'initial'} spinnerWidth="20px" />
                            }
                        </button>
                        <a className="invitePanel-previewLink"
                            id="previewPublicMeetingLink"
                            href={'/' + this.props.editableMeeting.meetingID}
                            target="_blank"
                            rel="noreferrer"
                            onClick={this.handlePreviewClick}
                        >
                            <span>{this.props.content.previewButton}</span>
                        </a>
                        <Modal
                            isOpen={this.state.isSaveReminderModalOpen}
                            shouldCloseOnOverlayClick={true}
                            timeout={300}
                            classNames={'saveReminderModal'}
                            onRequestClose={this.closeSaveReminderModal}
                            modalID="saveReminder"
                            header={this.props.content.unsavedChanges}
                            initialFocusSelector="#saveReminderCloseButton"
                        >
                            <button
                                type="button"
                                id="saveReminderCloseButton"
                                className="saveReminderModal-closeButton rsvp-filled-button"
                                onClick={this.closeSaveReminderModal}
                                tabIndex="0"
                            >
                                {this.props.content.returnToPage}
                            </button>
                        </Modal>
                    </div>

                    <div className="invitePanel-flexContainer">
                        <label
                            htmlFor="title"
                            className={errors['title'] ? 'error' : ''}
                            id="titleLabel"
                            role={(errors['title'] ? 'alert' : '')}
                        >
                            {this.props.content.meetingTitleLabel}&nbsp;
                            {errors['title'] || ''}
                        </label>
                        <Textarea
                            type="text"
                            className={
                                'invitePanel-meetingTitle rsvp-textArea' +
                                (errors['title'] ? ' error' : '')
                            }
                            name="title"
                            id="title"
                            aria-labelledby="titleLabel"
                            value={values.title}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            disabled={!this.props.editableMeeting.status.isActive ||
                                objectPath.get(this.props.content, 'displayValues.disableTitle')}
                        />

                        <div className="invitePanel-left">
                            <label
                                htmlFor="description"
                                className={errors['description'] ? 'error' : ''}
                                id="descriptionLabel"
                                role={(errors['description'] ? 'alert' : '')}
                            >
                                {this.props.content.meetingDescriptionLabel}&nbsp;
                                {errors['description'] || ''}
                            </label>
                            <Textarea
                                type="text"
                                className={
                                    'invitePanel-meetingDescription rsvp-textArea' +
                                    (errors['description'] ? ' error' : '')
                                }
                                name="description"
                                id="description"
                                aria-labelledby="descriptionLabel"
                                value={values.description}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={!this.props.editableMeeting.status.isActive ||
                                    objectPath.get(this.props.content,
                                        'displayValues.disableDescription')}
                            />
                            {showDestinationsField && <React.Fragment>
                                <label
                                    htmlFor="destinations"
                                    className={errors['destinations'] ? 'error' : ''}
                                    id="destinationsLabel"
                                    role={(errors['destinations'] ? 'alert' : '')}
                                >
                                    {this.props.content.destinationsLabel}&nbsp;
                                    {errors['destinations'] || ''}
                                </label>
                                <Textarea
                                    type="text"
                                    className={
                                        "invitePanel-destinations rsvp-textArea" +
                                        (errors['destinations'] ? ' error' : '')
                                    }
                                    name="destinations"
                                    id="destinations"
                                    aria-labelledby="destinationsLabel"
                                    value={values.destinations}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    disabled={!this.props.editableMeeting.status.isActive}
                                />
                            </React.Fragment>}
                            <label
                                htmlFor="organizer"
                                className={errors['organizer'] ? 'error' : ''}
                                id="organizerLabel"
                                role={(errors['organizer'] ? 'alert' : '')}
                            >
                                {this.props.content.organizerNameLabel}&nbsp;
                                {errors['organizer'] || ''}
                            </label>
                            <Textarea
                                type="text"
                                className={
                                    "invitePanel-organizerName rsvp-textArea" +
                                    (errors['organizer'] ? ' error' : '')
                                }
                                name="organizer"
                                id="organizer"
                                aria-labelledby="organizerLabel"
                                value={values.organizer}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={!this.props.editableMeeting.status.isActive}
                            />
                            <label
                                htmlFor="organizerEmail"
                                className={errors['organizerEmail'] ? 'error' : ''}
                                id="organizerEmailLabel"
                                role={(errors['organizerEmail'] ? 'alert' : '')}
                            >
                                {this.props.content.organizerEmailLabel}&nbsp;
                                {errors['organizerEmail'] || ''}
                            </label>
                            <Textarea
                                type="text"
                                className={
                                    "invitePanel-organizerEmail rsvp-textArea" +
                                    (errors['organizerEmail'] ? ' error' : '')
                                }
                                name="organizerEmail"
                                id="organizerEmail"
                                aria-labelledby="organizerEmailLabel"
                                value={values.organizerEmail}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={!this.props.editableMeeting.status.isActive}
                            />
                            {
                                //TODO: Remove this once the discount email is ready to go
                                !objectPath.get(this.props.content,
                                    'displayValues.shouldHideMeetingPreferences') &&
                                <React.Fragment>
                                    <label>
                                        {this.props.content.meetingPreferencesLabel}&nbsp;
                                    </label>
                                    <div className="invitePanel-fieldGroup invitePanel-meetingPreferences">
                                        <div>
                                            <input type="checkbox"
                                                name="meetingPreferenceCheckbox"
                                                id="meetingPreferenceCheckbox"
                                                className="invitePanel-meetingPreferencesCheckbox"
                                                onChange={this.handleUpdateEmailPreference}
                                                checked={this.props.canContactParents}
                                            />
                                            <label htmlFor="meetingPreferenceCheckbox"
                                                className={
                                                    'invitePanel-meetingPreferencesCheckboxLabel' +
                                                    (this.state.updateMeetingPreferencesPending ? ' loading' : '')
                                                }
                                            >
                                                <div className="invitePanel-meetingPreferencesText">
                                                    <p className="invitePanel-meetingPreferencesDescription">
                                                        {this.props.content.meetingPreferencesDescription}
                                                    </p>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    {!objectPath.get(this.props.content,
                                        'displayValues.shouldHidePhoneTexting') &&
                                        <div className="invitePanel-fieldGroup invitePanel-meetingPreferences">
                                            <div>
                                                <input type="checkbox"
                                                    name="phoneTextingCheckbox"
                                                    id="phoneTextingCheckbox"
                                                    className="invitePanel-meetingPreferencesCheckbox"
                                                    onChange={this.handleUpdatePhoneTexting}
                                                    checked={this.props.canSendTextMessagesToAttendees}
                                                />
                                                <label htmlFor="phoneTextingCheckbox"
                                                    className={
                                                        'invitePanel-meetingPreferencesCheckboxLabel' +
                                                        (this.state.updatePhoneTextingPending ? ' loading' : '')
                                                    }
                                                >
                                                    <div className="invitePanel-meetingPreferencesText">
                                                        <p className="invitePanel-meetingPreferencesDescription">
                                                            {this.props.content.phoneTextingDescription}
                                                        </p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    }

                                    <p id="invitePanel-meetingPreferencesConfirmation"
                                        className={'invitePanel-meetingPreferencesConfirmation' +
                                            (this.state.recentlyUpdated ? ' invitePanel-meetingPreferencesConfirmation--updated' : '')}>
                                        {this.props.content.meetingPreferencesUpdatedLabel}
                                    </p>
                                </React.Fragment>
                            }
                        </div>
                        <div className="invitePanel-right">
                            <label htmlFor="date">
                                {this.props.content.dateLabel}
                            </label>
                            <div className="invitePanel-dateTime-fieldGroup">
                                <DatePicker
                                    className="rsvp-textInput"
                                    name="date"
                                    id="date"
                                    selected={values.date}
                                    minDate={moment()}
                                    dropdownMode="scroll"
                                    onChange={this.handleDateChange}
                                    onBlur={this.handleDateBlur}
                                    disabled={!this.props.editableMeeting.status.isActive}
                                    placeholderText={this.props.content.datePlaceholderText}
                                    locale={this.props.locale}
                                    ref={el => this.onDatepickerRef(el)}
                                    customInput={
                                        <CustomDateInput />
                                    }
                                />
                                {values.date &&
                                    <button
                                        type="button"
                                        id="clearDateButton"
                                        onClick={this.handleDateClearClick}
                                        className="invitePanel-clearDateButton"
                                    >
                                        <img src={xSvg} />&nbsp;
                                        <span>{this.props.content.clearDateButton}</span>
                                    </button>
                                }
                            </div>
                            <div className="invitePanel-fieldGroup">
                                <TimePicker
                                    values={values}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                />
                            </div>
                            {
                                objectPath.get(this.props.content, 'displayValues' +
                                    '.shouldShowMeetingUrl') &&
                                <React.Fragment>
                                    <label
                                        htmlFor="onlineMeetingUrl"
                                        className={errors['onlineMeetingUrl'] ? 'error' : ''}
                                        id="onlineMeetingUrlLabel"
                                        role={(errors['onlineMeetingUrl'] ? 'alert' : '')}
                                    >
                                        {this.props.content.meetingUrlLabel}&nbsp;
                                        {errors['onlineMeetingUrl'] || ''}
                                    </label>
                                    <Textarea
                                        className={
                                            "invitePanel-meetingUrl rsvp-textArea" +
                                            (errors['onlineMeetingUrl'] ? ' error' : '')
                                        }
                                        name="onlineMeetingUrl"
                                        id="onlineMeetingUrl"
                                        aria-labelledby="onlineMeetingUrlLabel"
                                        value={values.onlineMeetingUrl}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        disabled={!this.props.editableMeeting.status.isActive}
                                    />
                                </React.Fragment>
                            }

                            <label
                                htmlFor="venue"
                                className={errors['venue'] ? 'error' : ''}
                                id="venueLabel"
                                role={(errors['venue'] ? 'alert' : '')}
                            >
                                {this.props.content.meetingVenueLabel}&nbsp;
                                {errors['venue'] || ''}
                            </label>
                            {
                                objectPath.get(this.props.content, 'displayValues' +
                                    '.shouldShowMeetingUrl') &&
                                <p>{this.props.content.meetingUrlVenueSubLabel}</p>
                            }
                            <Textarea
                                className={
                                    "invitePanel-meetingVenue rsvp-textArea" +
                                    (errors['venue'] ? ' error' : '')
                                }
                                name="venue"
                                id="venue"
                                aria-labelledby="venueLabel"
                                value={values.venue}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={!this.props.editableMeeting.status.isActive}
                            />

                            <label
                                htmlFor="street1"
                                className={errors['street1'] ? 'error' : ''}
                                id="street1Label"
                                role={(errors['street1'] ? 'alert' : '')}
                            >
                                {this.props.content.addressLabel}&nbsp;
                                {errors['street1'] || ''}
                            </label>
                            <Textarea
                                className={
                                    "invitePanel-meetingAddress rsvp-textArea" +
                                    (errors['street1'] ? ' error' : '')
                                }
                                name="street1"
                                id="street1"
                                aria-labelledby="street1Label"
                                value={values.street1}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={!this.props.editableMeeting.status.isActive}
                            />

                            <label
                                htmlFor="city"
                                className={errors['city'] ? 'error' : ''}
                                id="cityLabel"
                                role={(errors['city'] ? 'alert' : '')}
                            >
                                {this.props.content.cityLabel}&nbsp;
                                {errors['city'] || ''}
                            </label>
                            <Textarea
                                className={
                                    "invitePanel-meetingCity rsvp-textArea" +
                                    (errors['city'] ? ' error' : '')
                                }
                                name="city"
                                id="city"
                                aria-labelledby="cityLabel"
                                value={values.city}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                disabled={!this.props.editableMeeting.status.isActive}
                            />

                            <div className="invitePanel-fieldGroup">
                                <div>
                                    <label
                                        htmlFor="state"
                                        className={errors['state'] ? 'error' : ''}
                                        id="stateLabel"
                                        role={(errors['state'] ? 'alert' : '')}
                                    >
                                        {this.props.content.stateProvinceLabel}&nbsp;
                                        {errors['state'] || ''}
                                    </label>
                                    <Textarea
                                        className={
                                            "invitePanel-meetingStateProvince rsvp-textArea" +
                                            (errors['state'] ? ' error' : '')
                                        }
                                        name="state"
                                        id="state"
                                        aria-labelledby="stateLabel"
                                        value={values.state}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        disabled={!this.props.editableMeeting.status.isActive}
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="postalCode"
                                        className={errors['postalCode'] ? 'error' : ''}
                                        id="postalCodeLabel"
                                        role={(errors['postalCode'] ? 'alert' : '')}
                                    >
                                        {this.props.content.zipCodeLabel}&nbsp;
                                        {errors['postalCode'] || ''}
                                    </label>
                                    <Textarea
                                        className={
                                            "invitePanel-meetingZip rsvp-textArea" +
                                            (errors['postalCode'] ? ' error' : '')
                                        }
                                        name="postalCode"
                                        id="postalCode"
                                        aria-labelledby="postalCodeLabel"
                                        value={values.postalCode}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        disabled={!this.props.editableMeeting.status.isActive}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </form>
        );
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.manageMeeting.invitePanel'),
            locale: objectPath.get(state, 'product.locale'),
            editableMeeting: objectPath.get(state, 'manageMeeting.editableMeeting'),
            isInviteEmailPromptShown: objectPath.get(state, 'manageMeeting.isInviteEmailPromptShown'),
            isInviteUrlPromptShown: objectPath.get(state, 'manageMeeting.isInviteUrlPromptShown'),
            allContactEmails: getAllContactEmails(state.manageMeeting),
            siteCode: objectPath.get(state, 'product.siteCode'),
            canContactParents: objectPath.get(state, 'manageMeeting.editableMeeting.meetingPreferences' +
                '.canContactParents'),
            canSendTextMessagesToAttendees: objectPath.get(state, 'manageMeeting.editableMeeting.meetingPreferences' +
                '.canSendTextMessagesToAttendees')
        };
    }
}

InvitePanel.propTypes = {
    formikBag: PropTypes.object,
    defaultStartDate: PropTypes.object,
    editableMeeting: PropTypes.shape({
        status: PropTypes.shape({
            isCancelled: PropTypes.bool,
            isPast: PropTypes.bool,
            isActive: PropTypes.bool
        })
    }),
    locale: PropTypes.string.isRequired,
    content: PropTypes.shape({
        updatePrompt1: PropTypes.string.isRequired,
        updatePromptEmail: PropTypes.string.isRequired,
        updatePrompt2: PropTypes.string.isRequired,
        cancelledPrompt1: PropTypes.string.isRequired,
        cancelledPromptEmail: PropTypes.string.isRequired,
        cancelledPrompt2: PropTypes.string.isRequired,
        cancelledPrompt3: PropTypes.string.isRequired,
        closeButton: PropTypes.string.isRequired,
        saveButton: PropTypes.string.isRequired,
        saved: PropTypes.string.isRequired,
        previewButton: PropTypes.string.isRequired,
        meetingTitleLabel: PropTypes.string.isRequired,
        meetingDescriptionLabel: PropTypes.string.isRequired,
        organizerNameLabel: PropTypes.string.isRequired,
        organizerEmailLabel: PropTypes.string.isRequired,
        meetingPreferencesLabel: PropTypes.string.isRequired,
        meetingPreferencesDescription: PropTypes.string.isRequired,
        meetingPreferencesUpdatedLabel: PropTypes.string.isRequired,
        phoneTextingDescription: PropTypes.string.isRequired,
        dateLabel: PropTypes.string.isRequired,
        datePlaceholderText: PropTypes.string.isRequired,
        clearDateButton: PropTypes.string.isRequired,
        startTimeLabel: PropTypes.string.isRequired,
        endTimeLabel: PropTypes.string.isRequired,
        meetingUrlLabel: PropTypes.string.isRequired,
        meetingVenueLabel: PropTypes.string.isRequired,
        meetingUrlVenueSubLabel: PropTypes.string.isRequired,
        addressLabel: PropTypes.string.isRequired,
        cityLabel: PropTypes.string.isRequired,
        stateProvinceLabel: PropTypes.string.isRequired,
        zipCodeLabel: PropTypes.string.isRequired,
        unsavedChanges: PropTypes.string.isRequired,
        returnToPage: PropTypes.string.isRequired,
        pastPrompt1: PropTypes.string.isRequired,
        pastPrompt2: PropTypes.string.isRequired,
        displayValues: PropTypes.shape({
            disableTitle: PropTypes.bool,
            disableDescription: PropTypes.bool,
            shouldShowMeetingUrl: PropTypes.bool,
            isMeetingUrlWarningEnabled: PropTypes.bool,
            shouldHideMeetingPreferences: PropTypes.bool,
            shouldHidePhoneTexting: PropTypes.bool,
            shouldUse24HourTime: PropTypes.bool
        })
    })
};

const mapDispatchToProps = {
    soilInvitePanelForm,
    cleanInvitePanelForm,
    updateMeetingPreferences,
    hidePromptToEmailRespondees,
    hidePromptToShortenUrl
};

export default connect(InvitePanel.mapStateToProps, mapDispatchToProps)(InvitePanel);