import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import objectPath from 'object-path';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import InvitePanel from './InvitePanel.jsx';
import {
    updateEditableMeeting, showPromptToEmailRespondees, showPromptToShortenUrl, hidePromptToShortenUrl
} from './manage-meeting-actions.js';
import { createEditableMeetingSchema } from './editable-meeting-schema.js';

const defaultStartDate = moment.utc().add(2, 'weeks').hours(19).minutes(0).seconds(0);
const defaultEndDate = moment.utc().add(2, 'weeks').hours(20).minutes(0).seconds(0);


class InvitePanelFormik extends React.Component {

    constructor(props) {
        super(props);

        this.getInitialValues = this.getInitialValues.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.buildIsoDateTime = this.buildIsoDateTime.bind(this);
        this.hasEssentialChangesAfterReply = this.hasEssentialChangesAfterReply.bind(this);
    }

    getInitialValues() {
        let use24HourTime = objectPath.get(this.props, 'content.displayValues.shouldUse24HourTime', false);

        let startHours = moment.utc(this.props.editableMeeting.startDate || defaultStartDate).hours();
        let endHours = moment.utc(this.props.editableMeeting.endDate || defaultEndDate).hours();

        return {
            title: this.props.editableMeeting.title || '',
            description: this.props.editableMeeting.description || '',
            organizer: this.props.editableMeeting.organizer || '',
            organizerEmail: this.props.editableMeeting.organizerEmail || '',
            date: this.props.editableMeeting.startDate ?
                moment.utc(this.props.editableMeeting.startDate) :
                null,
            startHours: use24HourTime ? startHours : startHours % 12,
            startMinutes:
                moment.utc(
                    this.props.editableMeeting.startDate ||
                    defaultStartDate
                ).minutes(),
            startMeridiem:
                moment.utc(
                    this.props.editableMeeting.startDate ||
                    defaultStartDate
                ).hours() < 12 ? 'AM' : 'PM',
            endHours: use24HourTime ? endHours : endHours % 12,
            endMinutes:
                moment.utc(
                    this.props.editableMeeting.endDate ||
                    defaultEndDate
                ).minutes(),
            endMeridiem:
                moment.utc(
                    this.props.editableMeeting.endDate ||
                    defaultEndDate
                ).hours() < 12 ? 'AM' : 'PM',
            state: this.props.editableMeeting.state || '',
            postalCode: this.props.editableMeeting.postalCode || '',
            venue: this.props.editableMeeting.venue || '',
            street1: this.props.editableMeeting.street1 || '',
            city: this.props.editableMeeting.city || '',
            onlineMeetingUrl: this.props.editableMeeting.onlineMeetingUrl || '',
            destinations: this.props.editableMeeting.destinations || ''
        };
    }

    handleSubmit(values, {setSubmitting, resetForm, setStatus}) {
        setSubmitting(true);

        let updatedEditableMeeting = JSON.parse(JSON.stringify(this.props.editableMeeting));
        // Date change
        // Time change
        // location change
        // organizer / email change

        let startDate = values.date ?
            this.buildIsoDateTime(
                values.date,
                parseInt(values.startHours),
                parseInt(values.startMinutes),
                values.startMeridiem
            ) : null;

        let endDate = values.date ?
            this.buildIsoDateTime(
                values.date,
                parseInt(values.endHours),
                parseInt(values.endMinutes),
                values.endMeridiem
            ) : null;

        let shouldPromptToEmailRespondees =
            this.hasEssentialChangesAfterReply(values, startDate, endDate);

        let shouldPromptToShortenUrl =
            values.onlineMeetingUrl && values.onlineMeetingUrl.length > 150;

        updatedEditableMeeting = Object.assign(updatedEditableMeeting, {
            title: values.title,
            description: values.description,
            organizer: values.organizer,
            organizerEmail: values.organizerEmail,
            state: values.state,
            postalCode: values.postalCode,
            venue: values.venue,
            street1: values.street1,
            city: values.city,
            onlineMeetingUrl: values.onlineMeetingUrl,
            startDate: values.date ? this.buildIsoDateTime(
                values.date,
                parseInt(values.startHours),
                parseInt(values.startMinutes),
                values.startMeridiem
            ) : null,
            endDate: values.date ? this.buildIsoDateTime(
                values.date,
                parseInt(values.endHours),
                parseInt(values.endMinutes),
                values.endMeridiem
            ) : null,
            destinations: values.destinations
        });

        this.props.updateEditableMeeting(
            this.props.editableMeeting.meetingID,
            updatedEditableMeeting
        )
            .response
            .catch((error) => {
                setStatus('fail');
                setSubmitting(false);
                return Promise.reject(error);
            })
            .then(() => {
                resetForm({ values });
                setStatus('success');
                if (shouldPromptToEmailRespondees) {
                    this.props.showPromptToEmailRespondees();
                }
                if (shouldPromptToShortenUrl) {
                    this.props.showPromptToShortenUrl();
                } else {
                    // In case the prompt was displayed, the user didn't dismiss it, but they
                    // have fixed the field and are now resubmitting. (So now, hide it)
                    this.props.hidePromptToShortenUrl();
                }
            });
    }

    buildIsoDateTime(date, hours, minutes, meridiem) {
        let dateTime = moment.utc(date);
        let is24HourTime = objectPath.get(this.props, 'content.displayValues.shouldUse24HourTime', false);

        // If submitted in 12-hour format, convert to 24-hour
        let militaryHours = (!is24HourTime && meridiem.toLowerCase() === 'pm') ? (hours + 12) : hours;
        dateTime.hours(militaryHours);
        dateTime.minutes(minutes);

        return dateTime.toISOString();
    }

    hasEssentialChangesAfterReply(values, startDate, endDate) {
        let startDateChanged = startDate !== this.props.editableMeeting.startDate;

        let endDateChanged = endDate !== this.props.editableMeeting.endDate;

        let meetingUrlChanged =
            values.onlineMeetingUrl !== null &&
            values.onlineMeetingUrl !== this.props.editableMeeting.onlineMeetingUrl;

        return this.props.editableMeeting.replies.length > 0 &&
            (
                startDateChanged ||
                endDateChanged ||
                meetingUrlChanged ||
                values.venue !== this.props.editableMeeting.venue ||
                values.street1 !== this.props.editableMeeting.street1 ||
                values.city !== this.props.editableMeeting.city ||
                values.state !== this.props.editableMeeting.state ||
                values.postalCode !== this.props.editableMeeting.postalCode ||
                values.organizer !== this.props.editableMeeting.organizer ||
                values.organizerEmail !== this.props.editableMeeting.organizerEmail
            );
    }

    render() {
        return this.props.editableMeeting
            ? <Formik
                initialValues={this.getInitialValues()}
                onSubmit={this.handleSubmit}
                validationSchema={createEditableMeetingSchema(this.props.content)}
                validateOnChange={false}
                validateOnBlur={true}
            >
                {(formikBag) => 
                    <InvitePanel
                        formikBag={formikBag}
                        defaultStartDate={defaultStartDate}
                    />
                }
            </Formik>
            : null;
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.manageMeeting.invitePanel'),
            editableMeeting: objectPath.get(state, 'manageMeeting.editableMeeting')
        };
    }
}

InvitePanelFormik.propTypes = {
    content: PropTypes.shape({
        invalidErrorLabel: PropTypes.string.isRequired,
        requiredErrorLabel: PropTypes.string.isRequired,
        minErrorLabel: PropTypes.string.isRequired,
        maxErrorLabel: PropTypes.string.isRequired,
        displayValues: PropTypes.shape({
            shouldUse24HourTime: PropTypes.bool
        })
    })
};

const mapDispatchToProps = {
    updateEditableMeeting,
    showPromptToEmailRespondees,
    showPromptToShortenUrl,
    hidePromptToShortenUrl
};

export default connect(InvitePanelFormik.mapStateToProps, mapDispatchToProps)(InvitePanelFormik);