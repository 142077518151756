import React from 'react';
import { connect } from 'react-redux';
import { scrollWindow, resizeWindow } from '../globals/services/window/window-actions.js';

class WindowEventListeners extends React.Component {
    constructor(props) {
        super(props);

        this.lastScrollEventDate = null;
        this.scrollEventTimeout = {};
        this.scrollEventDeferTimer = 250;
        this.resizeEventTimeout = {};
        this.resizeEventDeferTimer = 200;

        this.throttleScroll = this.throttleScroll.bind(this);
        this.throttleResize = this.throttleResize.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.throttleAnyEvent = this.throttleAnyEvent.bind(this);
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('scroll', this.throttleScroll);
        window.addEventListener('resize', this.throttleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.throttleScroll);
        window.removeEventListener('resize', this.throttleResize);
    }

    throttleScroll() {
        this.throttleAnyEvent(
            this.scrollEventDeferTimer, this.handleScroll, 'scrollEventTimeout');
    }

    throttleResize() {
        this.throttleAnyEvent(
            this.resizeEventDeferTimer, this.handleResize, 'resizeEventTimeout');
    }

    handleScroll() {
        this.props.scrollWindow(window.scrollY);
    }

    handleResize() {
        this.props.resizeWindow(window.innerWidth, window.innerHeight);
    }

    throttleAnyEvent(delay, eventHandler, timeoutFunctionName) {
        let context = this, now = +new Date();
        if (context.lastScrollEventDate && now < context.lastScrollEventDate + delay) {
            clearTimeout(context[timeoutFunctionName]);
            context[timeoutFunctionName] = setTimeout(function() {
                context.lastScrollEventDate = now;
                eventHandler.apply(context);
            }, delay);
        } else {
            context.lastScrollEventDate = now;
            eventHandler.apply(context);
        }
    }

    render() {
        return null;
    }
}

const mapDispatchToProps = {
    scrollWindow,
    resizeWindow
};

export default connect(null, mapDispatchToProps)(WindowEventListeners);