import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import PropTypes from 'prop-types';

import './DateLocation.scss';

import AddToCalendar from '../add-to-calendar/AddToCalendar.jsx';
import { formatMeetingDate, formatMeetingTime } from '../../utils/format-util.js';

class DateLocation extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="rsvp-dateLocation" role="region" aria-label="meeting date and location">
                {
                    this.renderDateSection()
                }
                {
                    this.renderMeetingLocationSection()
                }
                {
                    this.renderEnrollmentSection()
                }
            </div>
        );
    }

    renderDateSection(){
        let showAddToCalendarButton = !!(this.props.publicMeeting.startDate && this.props.canAddToCalendar);
        return showAddToCalendarButton

            ? <div className="rsvp-dateLocation-group rsvp-dateLocation-group--highlighted">
                <div className="rsvp-meetingDate-wrapper">
                    <section className="rsvp-meetingDate">
                        <div>
                            <em>{formatMeetingDate(this.props.publicMeeting.startDate)}</em>
                            <em>{
                                formatMeetingTime(
                                    this.props.publicMeeting.startDate,
                                    this.props.publicMeeting.endDate
                                )}
                            </em>
                        </div>
                    </section>
                </div>
                <AddToCalendar />
            </div>

            : <div className="rsvp-dateLocation-group">
                <div className="rsvp-meetingDate-wrapper">
                    <section className="rsvp-meetingDate">
                        {
                            this.props.publicMeeting.startDate ?
                                <div>
                                    <em>{formatMeetingDate(this.props.publicMeeting.startDate)}</em>
                                    <em>{
                                        formatMeetingTime(
                                            this.props.publicMeeting.startDate,
                                            this.props.publicMeeting.endDate
                                        )}
                                    </em>
                                </div> :
                                <div>
                                    <em>{this.props.content.tbd}</em>
                                </div>
                        }
                    </section>
                </div>
            </div>;
    }

    renderMeetingLocationSection() {
        if (this.props.publicMeeting.onlineMeetingUrl) {

            return (
                <div className="rsvp-dateLocation-group">
                    <div className="rsvp-meetingLocation-wrapper rsvp-meetingLocation-wrapper--virtual">
                        <section className="rsvp-meetingLocation">
                            <div>
                                <em>{this.props.content.virtualMeeting}</em>
                                <p>
                                    {
                                        this.props.canAddToCalendar

                                        ? this.props.publicMeeting.onlineMeetingUrl

                                        : this.props.content.mustRsvpForMeetingUrl
                                    }
                                </p>
                                {(this.props.publicMeeting.venue || this.props.publicMeeting.street1) &&
                                <React.Fragment>
                                    <em>{this.props.content.inPersonLocation}</em>
                                    <p>{this.props.publicMeeting.venue}</p>
                                    <p>{this.props.publicMeeting.street1}</p>
                                    <p>
                                        {this.props.publicMeeting.city ? this.props.publicMeeting.city + ' ' : ''}
                                        {this.props.publicMeeting.state ? this.props.publicMeeting.state + ' ' : ''}
                                        {this.props.publicMeeting.postalCode || ''}
                                    </p>
                                </React.Fragment>
                                }
                            </div>
                        </section>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="rsvp-dateLocation-group">
                    <div className="rsvp-meetingLocation-wrapper">
                        <section className="rsvp-meetingLocation">
                            {
                                (this.props.publicMeeting.venue || this.props.publicMeeting.street1) &&
                                <div>
                                    <em>{this.props.publicMeeting.venue}</em>
                                    <p>{this.props.publicMeeting.street1}</p>
                                    <p>
                                        {this.props.publicMeeting.city ? this.props.publicMeeting.city + ' ' : ''}
                                        {this.props.publicMeeting.state ? this.props.publicMeeting.state + ' ' : ''}
                                        {this.props.publicMeeting.postalCode || ''}
                                    </p>
                                </div> ||
                                <div>
                                    <em>{this.props.content.tbd}</em>
                                </div>
                            }
                        </section>
                    </div>
                </div>
            );
        }
    }

    renderEnrollmentSection() {
        let shouldRenderSection = this.props.publicMeeting.purpose === 'recruitment';

        return shouldRenderSection
            ? <div className="rsvp-meetingLimit-wrapper">
                { (objectPath.get(this.props.publicMeeting, 'numOfAttendees', 0) > 10) ||
                objectPath.get(this.props.content, 'displayValues.shouldHideLimitedSpots') ?
                    this.renderNumAttending() : this.renderLimitedSpots() }
            </div>
            : null;
    }

    renderNumAttending() {
        return (
            <section className="rsvp-meetingNumAttending">
                <div>
                    <em>{this.props.publicMeeting.numOfAttendees} {this.props.content.peopleAttending}</em>
                    <p>{this.props.content.peopleAttendingEnrollmentInfo}</p>
                </div>
                {/*<a href="#">{this.props.content.inviteFriend}</a>*/}
            </section>
        )
    }

    renderLimitedSpots() {
        return (
            <section className="rsvp-meetingLimit">
                <div>
                    <em>{this.props.content.limitedSpots}</em>
                    <p>{this.props.content.enrollmentInfo}</p>
                </div>
            </section>
        );
    }

    formatMeetingUrl() {
        if (/\/\//g.test(this.props.publicMeeting.onlineMeetingUrl)) {
            return this.props.publicMeeting.onlineMeetingUrl;
        } else {
            return '//' + this.props.publicMeeting.onlineMeetingUrl;
        }
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.globals.dateLocation'),
            publicMeeting: state.publicInvite.publicMeeting
        };
    }
}

DateLocation.propTypes = {
    canAddToCalendar: PropTypes.bool,
    publicMeeting: PropTypes.object,
    content: PropTypes.shape({
        virtualMeeting: PropTypes.string.isRequired,
        inPersonLocation: PropTypes.string.isRequired,
        mustRsvpForMeetingUrl: PropTypes.string.isRequired,
        openMap: PropTypes.string.isRequired,
        limitedSpots: PropTypes.string.isRequired,
        enrollmentInfo: PropTypes.string.isRequired,
        inviteFriend: PropTypes.string.isRequired,
        tbd: PropTypes.string.isRequired,
        peopleAttending: PropTypes.string.isRequired,
        peopleAttendingEnrollmentInfo: PropTypes.string.isRequired
    })
};

export default connect(DateLocation.mapStateToProps)(DateLocation);