import cookie from 'cookie';

import globalConstants from './../utils/global-constants.js';
import { getSecureSiteHostname } from './url-util.js';

export const getCookie = (cname) => {
    let cookieValue = null;

    let cookies = getCookies();

    if (cookies) {
        const name = cname + '=';
        const ca = cookies.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                cookieValue = c.substring(name.length, c.length);
            }
        }
    }

    return cookieValue;
};

const getCookies = () => {
    return document.cookie;
};

export const setCookie = (name, value, options = {}) => {
    document.cookie = cookie.serialize(name, value, options);
};

export const deleteCookie = (name) => {
    if (name === globalConstants.cookies.EF_USER) {
        let secureSiteHostName = getSecureSiteHostname();
        document.cookie = name + '=; expires=-99999999; domain=.' + secureSiteHostName + '; path=/;';
    } else {
        document.cookie = name + '=; expires=-99999999; path=/';
    }
};
