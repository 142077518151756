import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import { CSVLink } from 'react-csv';

import './AttendingPanel.scss';
import printSvg from '../../static/img/print_1B9DDE.svg';
import excelSvg from '../../static/img/excel_1B9DDE.svg';
import emailSvg from '../../static/img/email_1B9DDE.svg';

import PagingTable from '../globals/components/paging-table/PagingTable.jsx';
import { getAttendingReplies, getAttendingIndividualCount, getAttendingContactEmails }
    from './manage-meeting-reducer.js';
import EmailAll from '../globals/components/email-all/EmailAll.jsx'

class AttendingPanel extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount(){
        console.log(this.props.attendingReplies);
    }

    render() {
        const renderPrintStyles = () => {
            return (
                <style dangerouslySetInnerHTML={{__html: `
                  @media print {
                    @page {
                        size: landscape;
                        margin: 1.5cm 1cm;
                    }
                    html,
                    body {
                        min-width: 100%;
                        max-width: 100%;
                        width: 100%;
                        margin: 0;
                        padding: 0;
                    }
                    .rsvp-mainPanel {
                        width: 100%;
                        padding: 0;
                        margin: 0;
                    }
                    .attendingPanel::before {
                        content: 'Attendees – `+ this.props.meetingTitle +`';
                        display: block;
                        margin-bottom: 12pt;
                        font-size: 9pt;
                    }
                    .attendingPanel {
                        width: 100%;
                        margin: 0;
                        padding: 0;
                    }
                    .attendingPanel-topBar {
                        display: none;
                    }
                    thead > tr,
                    tr:nth-child(even) {
                        background-color: #F8F8F8;
                    }
                    .pagingTable-wrapper {
                        width: 100%;
                        overflow-x: visible;
                    }
                    .pagingTable {
                        width: 100%;
                        margin: 0;
                    }
                    .pagingTable thead {
                        display: table-header-group;
                    }
                    .pagingTable-controls {
                        display: none;
                    }
                    .pagingTable .pagingTable-hidden {
                        position: relative;
                        visibility: visible;
                    }
                    .pagingTable td,
                    .pagingTable th {
                        padding: 0.1cm 0.2cm;
                        font-size: 7pt;
                    }
                    .pagingTable td:first-child,
                    .pagingTable th:first-child {
                        padding-left: 16pt;
                    }
                    .pagingTable td:first-child::before {
                        content: '';
                        display: block;
                        width: 8pt;
                        height: 8pt;
                        margin: none;
                        padding: none;
                        top: 0.1cm;
                        left: 3pt;
                        background-color: white;
                        border: 1px solid #AAA;
                    }
                  }
                `}} />
            );
        };

        const renderAttendingPanel = () => {
            return (
                <section
                    className="attendingPanel"
                    role="tabpanel"
                    aria-labelledby="attending-panel-tab"
                >
                    {
                        renderPrintStyles()
                    }
                    {
                        renderTopBar()
                    }
                    {
                        this.props.attendingReplies.length > 0 &&
                        renderTable()
                    }
                </section>
            );
        };

        const renderTopBar = () => {
            return (
                <div className="attendingPanel-topBar">
                    <header>
                        <h2 className="attendingPanel-topBar-total">
                            {this.props.content.totalAttendees}&nbsp;
                            {
                                this.props.attendingIndividualCount
                            }
                        </h2>
                    </header>
                    {
                        this.props.attendingReplies.length > 0 &&
                        <menu className="attendingPanel-topBar-menu">
                            <ReactToPrint
                                trigger={() =>
                                    <button
                                        type="button"
                                        id="attendingGroupPrintButton"
                                        className="groupActionButton"
                                        tabIndex="0"
                                    >
                                        <img src={printSvg}/>&nbsp;
                                        <span>{this.props.content.printButton}</span>
                                    </button>
                                }
                                content={() => this}
                            />
                            <CSVLink
                                id="attendingGroupDownload"
                                className="groupActionButton"
                                data={this.props.attendingReplies}
                                filename={'tour-meeting-attendees.csv'}
                                headers={this.props.content.tableColumns}
                                target="_blank"
                            >
                                <img src={excelSvg}/>&nbsp;
                                <span>{this.props.content.downloadTableButton}</span>
                            </CSVLink>
                            <EmailAll className="groupActionButton">
                                <img src={emailSvg}/>&nbsp;
                                {this.props.content.emailButton}
                            </EmailAll>
                        </menu>
                    }
                </div>
            );
        };

        const renderTable = () => {
            return (
                <PagingTable
                    className="attendingPanel-table"
                    data={this.props.attendingReplies}
                    columnConfig={this.props.content.tableColumns}
                    numberPerPage={20}
                />
            );
        };

        return renderAttendingPanel();
    }

    static mapStateToProps(state) {
        return {
            attendingReplies: getAttendingReplies(state.manageMeeting),
            attendingIndividualCount: getAttendingIndividualCount(state.manageMeeting),
            attendingContactEmails: getAttendingContactEmails(state.manageMeeting),
            content: objectPath.get(state, 'product.content.manageMeeting.attendingPanel')
        };
    }
}

AttendingPanel.propTypes = {
    meetingTitle: PropTypes.string,
    attendingReplies: PropTypes.arrayOf(PropTypes.shape({
        studentName: PropTypes.string,
        studentPhone: PropTypes.string,
        studentEmail: PropTypes.string,
        parentName: PropTypes.string,
        contactPhone: PropTypes.string,
        contactEmail: PropTypes.string,
        numOfAttendees: PropTypes.number
    })),
    attendingReplyCount: PropTypes.number,
    attendingContactEmails: PropTypes.arrayOf(PropTypes.string),
    content: PropTypes.shape({
        totalAttendees: PropTypes.string.isRequired,
        printButton: PropTypes.string.isRequired,
        downloadTableButton: PropTypes.string.isRequired,
        emailButton: PropTypes.string.isRequired,
        tableColumns: PropTypes.arrayOf(PropTypes.object).isRequired
    })
};

export default connect(AttendingPanel.mapStateToProps)(AttendingPanel);