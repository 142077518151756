import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getRecipientEmails, } from '../../../manage-meeting/manage-meeting-reducer';

import './EmailAllOptions.scss';

class EmailAllOptions extends React.Component {

    constructor(props) {
        super(props);
        this.createGmailLink = this.createGmailLink.bind(this);
        this.createWindowsLink = this.createWindowsLink.bind(this);
        this.createYahooLink = this.createYahooLink.bind(this);
        this.createAppleLink = this.createAppleLink.bind(this);

        this.state = {
            isOpen: false,
        };
    }

    createGmailLink() {
        let link = 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=';
        let recipients = this.props.contactEmails;
        link += recipients.join(';');
        return link;
    }

    createWindowsLink() {
        let link = "mailto:";
        let recipients = this.props.contactEmails;
        link += recipients.join(';');
        return link;
    }

    createYahooLink() {
        let link = 'https://compose.mail.yahoo.com/?to=';
        let recipients = this.props.contactEmails;
        link += recipients.join(',');
        return link;
    }

    createAppleLink() {
        let link = 'mailto:';
        let recipients = this.props.contactEmails;
        link += recipients.join(',');
        return link;
    }

    render() {
        return (
            <div className="emailAll-options">
                <a href={this.createGmailLink()}
                    target="_blank"
                    rel="noreferrer">
                    <img src="/static/img/google_logo.png"
                        alt="Google"
                        className="logo" />
                    <span>Gmail</span>
                </a>
                <a href={this.createWindowsLink()}>
                    <img src="/static/img/outlook_logo.png"
                        alt="Outlook"
                        className="outlook-logo" />
                    <span>Windows Mail</span>
                </a>
                <a href={this.createAppleLink()}>
                    <img src="/static/img/apple_mail_logo.jpg"
                        alt="apple"
                        className="logo" />
                    <span>Apple Mail</span>
                </a>
                <a href={this.createYahooLink()}
                    target="_blank"
                    rel="noreferrer">
                    <img src="/static/img/yahoo_logo.png"
                        alt="Yahoo"
                        className="logo" />
                    <span>Yahoo Mail</span>
                </a>
            </div>
        );
    }

    static mapStateToProps(state, ownProps) {
        let contactEmails =
            getRecipientEmails(
                state.manageMeeting,
                ownProps.parentFlag, ownProps.studentFlag);

        return {
            contactEmails
        };
    }
}

EmailAllOptions.propTypes = {
    contactEmails: PropTypes.arrayOf(PropTypes.string),
    recipients: PropTypes.oneOf(['Contact', 'Student', 'All'])
};

export default connect(EmailAllOptions.mapStateToProps)(EmailAllOptions);