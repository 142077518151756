import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import PropTypes from 'prop-types';

class TimePicker extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const shouldUse24HourTime = objectPath.get(this.props.content, 'displayValues.shouldUse24HourTime', false);

        const renderTimePicker = () => {return (
            <React.Fragment>
                <div>
                    <label htmlFor="startHours">
                        {this.props.content.startTimeLabel}
                    </label>
                    <select
                        className="invitePanel-meetingStartHours"
                        name="startHours"
                        value={this.props.values.startHours}
                        onChange={this.props.handleChange}
                        onBlur={this.props.handleBlur}
                        disabled={!this.props.values.date || !this.props.editableMeeting.status.isActive}
                    >
                        {
                            shouldUse24HourTime
                                ? render24HourOptions()
                                : render12HourOptions()
                        }
                    </select>
                    :
                    <select
                        className="invitePanel-meetingStartMinutes"
                        name="startMinutes"
                        value={this.props.values.startMinutes}
                        onChange={this.props.handleChange}
                        onBlur={this.props.handleBlur}
                        disabled={!this.props.values.date || !this.props.editableMeeting.status.isActive}
                    >
                        <option value="00">00</option>
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="45">45</option>
                    </select>
                    {
                        !shouldUse24HourTime
                            && renderStartMeridiemSelect()
                    }
                </div>
                <div>
                    <label htmlFor="invitePanel-meetingEndHours">
                        {this.props.content.endTimeLabel}
                    </label>
                    <select
                        className="invitePanel-meetingEndHours"
                        name="endHours"
                        value={this.props.values.endHours}
                        onChange={this.props.handleChange}
                        onBlur={this.props.handleBlur}
                        disabled={!this.props.values.date || !this.props.editableMeeting.status.isActive}
                    >
                        {
                            shouldUse24HourTime
                                ? render24HourOptions()
                                : render12HourOptions()
                        }
                    </select>
                    :
                    <select
                        className="invitePanel-meetingEndMinutes"
                        name="endMinutes"
                        value={this.props.values.endMinutes}
                        onChange={this.props.handleChange}
                        onBlur={this.props.handleBlur}
                        disabled={!this.props.values.date || !this.props.editableMeeting.status.isActive}
                    >
                        <option value="00">00</option>
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="45">45</option>
                    </select>
                    {
                        !shouldUse24HourTime
                            && renderEndMeridiemSelect()
                    }
                </div>
            </React.Fragment>
        );};

        const render12HourOptions = () => {
            let options = [];
            for (let i = 1; i <= 12; i++) {
                options.push(<option key={i} value={(i !== 12 ? i : 0)}>{i}</option>);
            }
            return options;
        };

        const render24HourOptions = () => {
            let options = [];
            for (let i = 0; i < 24; i++) {
                options.push(<option key={i} value={i}>{i}</option>);
            }
            return options;
        };

        const renderStartMeridiemSelect = () => { return (
            <select
                className="invitePanel-meetingStartMeridiem"
                name="startMeridiem"
                value={this.props.values.startMeridiem}
                onChange={this.props.handleChange}
                onBlur={this.props.handleBlur}
                disabled={!this.props.values.date || !this.props.editableMeeting.status.isActive}
            >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
            </select>
        );};

        const renderEndMeridiemSelect = () => { return (
            <select
                className="invitePanel-meetingEndMeridiem"
                name="endMeridiem"
                value={this.props.values.endMeridiem}
                onChange={this.props.handleChange}
                onBlur={this.props.handleBlur}
                disabled={!this.props.values.date || !this.props.editableMeeting.status.isActive}
            >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
            </select>
        );};

        return renderTimePicker();
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.manageMeeting.invitePanel'),
            editableMeeting: objectPath.get(state, 'manageMeeting.editableMeeting')
        };
    }
}


TimePicker.propTypes = {
    content: PropTypes.shape({
        startTimeLabel: PropTypes.string.isRequired,
        endTimeLabel: PropTypes.string.isRequired,
        displayValues: PropTypes.shape({
            shouldUse24HourTime: PropTypes.bool
        })
    }),
    values: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func
};

export default connect(TimePicker.mapStateToProps)(TimePicker);