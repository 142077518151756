import rsvpGlobal from '../utils/global-constants.js';

export const getSiteCodeFromHostname = () => {
    // Generally we prefer get the siteCode from data, such as a given meeting document
    // or group trip. But in cases where data is not available/applicable, we still need
    // to know which product to load content for. Hence this method.

    let siteCode,
        hostname = window.location.hostname;

    const hostnamesForSiteCodeMap = {
        'ETUS': [
            'rsvp.etus',
            'eftours.com'
        ],
        'EAUS': [
            'rsvp.eaus',
            'efexploreamerica.com'
        ],
        'ETCA': [
            'rsvp.etca',
            'eftours.ca'
        ],
        'ETFC': [
            'rsvp.etfc',
            'efvoyages.ca'
        ],
        'CST': [
            'rsvp.cst',
            'efcollegestudytours.com', // TODO: deprecated, safe to remove when the dust settles from rebranding effort
            'efstudyabroad.com'
        ],
        'GST': [
            'rsvp-tfg',
            'rsvp.gst',
            'rsvp-girltrips',
            'girltrips.eftours.com'
        ]
    };

    Object.keys(hostnamesForSiteCodeMap).forEach((proposedSiteCode)=>{
        hostnamesForSiteCodeMap[proposedSiteCode].forEach((validHostnameFragment) => {
            if (hostname.indexOf(validHostnameFragment) > -1) {
                siteCode = proposedSiteCode;
            }
        });
    });

    return siteCode || 'ETUS';
};

export const getSecureSiteOrigin = () => {
    let secureSiteHostname = getSecureSiteHostname(),
        env = rsvpGlobal.ENVIRONMENT,
        secureSiteOrigin;

    switch (env) {
        case 'dev':
            secureSiteOrigin = 'http://' + secureSiteHostname;
            break;
        case 'qa':
            secureSiteOrigin = 'https://' + secureSiteHostname;
            break;
        case 'prod':
        default:
            if (getSiteCodeFromHostname() === 'GST') {
                secureSiteOrigin = 'https://' + secureSiteHostname;
            } else {
                secureSiteOrigin = 'https://www.' + secureSiteHostname;
            }
            break;
    }

    return secureSiteOrigin;
};

export const getSecureSiteHostname = () => {
    let secureSiteHostname,
        currentHostname;

    // By getting the hostname, we are automatically stripping out the port
    currentHostname = window.location.hostname;

    /* 
    Tours For Girls is different than other products.
    Instead of just adding "rsvp." subdomain to secure site's hostname,
    we remove "girltrips. / gst." altogether and use "rsvp-tfg." subdomain.
    So to get to secure site, must do this in reverse. 
    */
    if (currentHostname.includes('rsvp-girltrips.')) {
        if (currentHostname.includes('eftours.')) {
            secureSiteHostname = currentHostname.replace('rsvp-girltrips.', 'girltrips.');
        } else {
            secureSiteHostname = currentHostname.replace('rsvp-girltrips.', 'gst.');
        }
    }

    // Below "else if" section can be removed after hemeva has been updated to retirect to "rsvp-girltrips." instead of "rsvp-tfg.".
    else if (currentHostname.includes('rsvp-tfg.')) {
        if (currentHostname.includes('eftours.')) {
            secureSiteHostname = currentHostname.replace('rsvp-tfg.', 'girltrips.');
        } else {
            secureSiteHostname = currentHostname.replace('rsvp-tfg.', 'gst.');
        }
    }

    else {
        secureSiteHostname = currentHostname.replace('rsvp.', '');
    }

    return secureSiteHostname;
};

export const redirectToEvaLogin = () => {
    let secureSiteOrigin = getSecureSiteOrigin();

    window.location = secureSiteOrigin + '/account/login';
};

export const redirectToEvaLogout = () => {
    let secureSiteOrigin = getSecureSiteOrigin();

    window.location = secureSiteOrigin + '/action/account/logout?path=%2faccount%2flogin';
};

export const getMeetingImageUrl = () => {
    // TODO: Eventually we plan to have more than one possible image. At that time we should
    // either get the URL from data or from the product.json, instead of building it here.

    let originParts = window.location.href.split('/');
    let origin = originParts[0] + '//' + originParts[2];

    return origin + '/static/img/public-invite-stage-v4.jpg';
};
