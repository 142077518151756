import React from 'react';
import PropTypes from 'prop-types';

export default class PromptBeforeUnload extends React.Component {
    constructor(props) {
        super(props);
        this.handleWindowUnload.bind(this);
    }

    componentDidMount() {
        if (this.props.isUnloadListenerActive) {
            this.createUnloadListener();
        }
    }

    componentWillUnmount() {
        this.destroyUnloadListener();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.isUnloadListenerActive && !this.props.isUnloadListenerActive) {
            this.createUnloadListener();
        }
        else if (!newProps.isUnloadListenerActive && this.props.isUnloadListenerActive) {
            this.destroyUnloadListener();
        }
    }

    createUnloadListener() {
        window.addEventListener('beforeunload', this.handleWindowUnload);
    }

    destroyUnloadListener() {
        window.removeEventListener('beforeunload', this.handleWindowUnload);
    }

    handleWindowUnload(event) {
        event.preventDefault();

        // Officially deprecated.
        // Chrome/Chromium based browsers still need this one.
        // Latest chromium, this.props is sometimes undefined on cypress calling visit() using same page url
        // We'll use a props.message if defined, otherwise we'll assign our own bogus message which allows
        // the page to sucessfully reload instead of throwing an error due to props.message not being defined
        const message = this.props && this.props.message ? this.props.message : '';
 
        if (event.returnValue){
            event.returnValue = message;
        }

        // Not deprecated, however many new browsers do not allow you to set the
        // prompt text at all. You have to settle for their confusing generic text,
        // because malicious developers abused this and fucked it up for everyone.
        return message;
    }

    render() {
        return null;
    }
}

PromptBeforeUnload.propTypes = {
    isUnloadListenerActive: PropTypes.bool,
    message: PropTypes.string
};
