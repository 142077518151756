import { windowActions } from './window-actions';

const initialState = {
    width: 1024,
    height: 768,
    scrollPosition: 0,
    isScrollingDown: true,
    cumulativeDirectionalScroll: 0
};

function windowReducer(state, action) {
    if (typeof state === 'undefined') {
        return initialState;
    }

    let newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case windowActions.WINDOW_SCROLL:
            adjustScrollValues(state, action, newState);
            return newState;
        case windowActions.WINDOW_RESIZE:
            newState.width = action.newWidth;
            newState.height = action.newHeight;
            return newState;
        default:
            return state;
    }
}

function adjustScrollValues(oldState, action, newState) {
    // calculate new scroll values
    let newScrollPosition, newIsScrollingDown, newCumulativeDirectionalScroll,
        currentScrollAmount,
        oldScrollPosition = oldState.scrollPosition,
        oldIsScrollingDown = oldState.isScrollingDown,
        oldCumulativeDirectionalScroll = oldState.cumulativeDirectionalScroll;

    newScrollPosition = action.newScrollPosition;

    currentScrollAmount =
        Math.abs(newScrollPosition - oldScrollPosition);

    newIsScrollingDown =
        newScrollPosition > oldScrollPosition;

    newCumulativeDirectionalScroll = newIsScrollingDown === oldIsScrollingDown ?
        oldCumulativeDirectionalScroll + currentScrollAmount :
        currentScrollAmount;

    // update scroll values on new state
    newState.scrollPosition = newScrollPosition;
    newState.isScrollingDown = newIsScrollingDown;
    newState.cumulativeDirectionalScroll = newCumulativeDirectionalScroll;
}

export default windowReducer;