import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment-timezone';

import './AddToCalendar.scss';

import { formatMeetingCardDate, formatMeetingTime } from '../../utils/format-util';
import Modal from '../modal/Modal.jsx';

const ShareSites = {
    GOOGLE: 'Google',
    ICAL: 'iCal',
    OUTLOOK: 'Outlook',
    YAHOO: 'Yahoo'
};

class AddToCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.getCalendarEventOptions = this.getCalendarEventOptions.bind(this);
        this.buildDetailedMeetingDescription = this.buildDetailedMeetingDescription.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.isMobile = this.isMobile.bind(this);
        this.googleShareUrl = this.googleShareUrl.bind(this);
        this.yahooShareUrl = this.yahooShareUrl.bind(this);
        this.buildShareFile = this.buildShareFile.bind(this);
        this.handleCalendarButtonClick = this.handleCalendarButtonClick.bind(this);
        this.escapeIcsString = this.escapeIcsString.bind(this);

        this.state = {
            isOpen: false,
            outlookCalendarUrl: '',
            googleCalendarUrl: '',
            iCalCalendarUrl: '',
            yahooCalendarUrl: ''
        };
    }

    openModal() {
        if (!this.state.isOpen) {
            let event = this.getCalendarEventOptions();

            let outlookCalendarUrl = this.buildShareUrl(event, ShareSites.OUTLOOK);
            let googleCalendarUrl = this.buildShareUrl(event, ShareSites.GOOGLE);
            let iCalCalendarUrl = this.buildShareUrl(event, ShareSites.ICAL);
            let yahooCalendarUrl = this.buildShareUrl(event, ShareSites.YAHOO);

            this.setState({
                isOpen: true,
                outlookCalendarUrl,
                googleCalendarUrl,
                iCalCalendarUrl,
                yahooCalendarUrl
            });
        }
        
    }

    closeModal() {
        if (this.state.isOpen) {
            this.setState({
                isOpen: false
            });
        }
    }

    getCalendarEventOptions() {
        // "guess()" is only unreliable in old browsers. Modern ones have this figured out.
        let clientTimeZone = moment.tz.guess();

        // When we save to the DB, we pretend that local times are actually UTC,
        // but without changing the time. We do this because we have no idea what time zone
        // the meeting is actually taking place in, and often the tour consultant is creating the
        // meeting remotely, so we cannot simply assume it's the browser's timezone at the time of
        // meeting creation.

        // This presents a serious challenge when expecting an external app to display it correctly.
        // First, we make a risky assumption that when somebody RSVPs, they are doing it near
        // the meeting location. Then we manually replace the saved timezone with the client's
        // actual timezone, and then convert it to actual UTC time because that's what the
        // calendar APIs require.

        let startDateFakeUtc = moment.utc(this.props.publicMeeting.startDate);
        let endDateFakeUtc = moment.utc(this.props.publicMeeting.endDate);
        let duration = startDateFakeUtc.diff(endDateFakeUtc, 'hours').toString();

        let startDateCorrected = startDateFakeUtc.tz(clientTimeZone, true);
        let endDateCorrected = endDateFakeUtc.tz(clientTimeZone, true);

        let startDateRealUtc = startDateCorrected.utc();
        let endDateRealUtc = endDateCorrected.utc();

        let title = this.props.publicMeeting.title;


        // Don't show location if there's both physical and virtual,
        // because it could mislead people (and location details are also in description anyways)
        let location;
        let hasPhysicalLocation = !!this.props.publicMeeting.venue;
        let hasVirtualLocation = !!this.props.publicMeeting.onlineMeetingUrl;
        if (hasPhysicalLocation && !hasVirtualLocation) {
            location = this.props.publicMeeting.venue;
        } else if (hasVirtualLocation && !hasPhysicalLocation) {
            location = this.props.publicMeeting.onlineMeetingUrl;
        }

        let description = this.buildDetailedMeetingDescription(this.props.publicMeeting);

        return {
            title,
            location,
            description,
            startDatetime: startDateRealUtc.format('YYYYMMDDTHHmmssZ'),
            endDatetime: endDateRealUtc.format('YYYYMMDDTHHmmssZ'),
            duration
        }
    }


    /**
     * Concatenates the meeting's description with various other meeting details,
     * to be used as the calendar event description
     */
    buildDetailedMeetingDescription(publicMeeting){
        let description = publicMeeting.description,
            horizontalRule = '—————',
            meetingDetailsLabel = this.props.content.meetingDetailsLabel,
            prettyDate = formatMeetingCardDate(publicMeeting.startDate),
            prettyTime = formatMeetingTime(publicMeeting.startDate, publicMeeting.endDate),
            physicalLocation = publicMeeting.venue,
            virtualLocation = publicMeeting.onlineMeetingUrl,
            organizerName = publicMeeting.organizer,
            organizerEmail = publicMeeting.organizerEmail;

        let descriptionConstituents = [
            description,
            horizontalRule,
            meetingDetailsLabel,
            prettyDate.concat('\n').concat(prettyTime),
            physicalLocation,
            virtualLocation,
            organizerName.concat('\n').concat(organizerEmail)
        ];

        let detailedDescription = '';

        descriptionConstituents.forEach((info) => {
            if (info) {
                if (detailedDescription){
                    detailedDescription = detailedDescription.concat('\n\n')
                }
                detailedDescription = detailedDescription.concat(info);
            }
        });

        return detailedDescription;
    }

    /* ====================================================
    BELOW FUNCTIONS SOURCED FROM NPM
    react-add-to-calendar-hoc: 1.0.5
    written by: jasonleibowitz
    https://www.npmjs.com/package/react-add-to-calendar-hoc
    ==================================================== */

    /**
     * Converts Date String with UTC timezone to date consumable by calendar
     * apps. Changes +00:00 to Z.
     * @param {string} Date in YYYYMMDDTHHmmssZ format
     * @returns {string} Date with +00:00 replaceed with Z
     */
    formatDate(date) {
        return date && date.replace('+00:00', 'Z');
    }

    /**
     * Tests provided UserAgent against Known Mobile User Agents
     * @param {string} userAgent
     * @returns {bool} isMobileDevice
     */
    isMobile() {
        return /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(
            window.navigator.userAgent || window.navigator.vendor || window.opera
        );
    }

    /**
     * Takes an event object and returns a Google Calendar Event URL
     * @param {string} event.description
     * @param {string} event.endDatetime
     * @param {string} event.location
     * @param {string} event.startDatetime
     * @param {string} event.title
     * @returns {string} Google Calendar Event URL
     */
    googleShareUrl(event) {
        let description = event.description,
            endDatetime = event.endDatetime,
            location = event.location,
            startDatetime = event.startDatetime,
            title = event.title;
            
        return "https://calendar.google.com/calendar/render?action=TEMPLATE&dates="
            .concat(startDatetime, "/")
            .concat(endDatetime, "&location=")
            .concat(location, "&text=")
            .concat(title, "&details=")
            .concat(description);
    }

    /**
     * Takes an event object and returns a Yahoo Calendar Event URL
     * @param {string} event.description
     * @param {string} event.duration
     * @param {string} event.location
     * @param {string} event.startDatetime
     * @param {string} event.title
     * @returns {string} Yahoo Calendar Event URL
     */
    yahooShareUrl(event) {
        let description = event.description,
            duration = event.duration,
            location = event.location,
            startDatetime = event.startDatetime,
            title = event.title;

        return "https://calendar.yahoo.com/?v=60&view=d&type=20&title="
            .concat(title, "&st=")
            .concat(startDatetime, "&dur=")
            .concat(duration, "&desc=")
            .concat(description, "&in_loc=")
            .concat(location);
    }

    /**
     * Takes an event object and returns an array to be downloaded as ics file
     * @param {string} event.description
     * @param {string} event.endDatetime
     * @param {string} event.location
     * @param {string} event.startDatetime
     * @param {string} event.title
     * @returns {array} ICS Content
     */
    buildShareFile(event) {
        let description = this.escapeIcsString(event.description),
            endDatetime = event.endDatetime,
            location = this.escapeIcsString(event.location),
            startDatetime = event.startDatetime,
            title = this.escapeIcsString(event.title);

        let content = ['BEGIN:VCALENDAR', 'VERSION:2.0', 'BEGIN:VEVENT', "URL:"
            .concat(document.URL), "DTSTART:"
            .concat(startDatetime), "DTEND:"
            .concat(endDatetime), "SUMMARY:"
            .concat(title), "DESCRIPTION:"
            .concat(description), "LOCATION:"
            .concat(location), 'END:VEVENT', 'END:VCALENDAR'].join('\n');
        return this.isMobile() ?
            encodeURI("data:text/calendar;charset=utf8,".concat(content)) :
            content;
    }

    /**
     * Takes an event object and a type of URL and returns either a calendar event
     * URL or the contents of an ics file.
     * @param {string} event.description
     * @param {string} event.duration
     * @param {string} event.endDatetime
     * @param {string} event.location
     * @param {string} event.startDatetime
     * @param {string} event.title
     * @param {string} type - One of ShareSites constant
     */

    buildShareUrl(event, type) {
        let description = event.description,
            duration = event.duration,
            endDatetime = event.endDatetime,
            location = event.location,
            startDatetime = event.startDatetime,
            title = event.title;
        
        let encodeURI = type !== ShareSites.ICAL && type !== ShareSites.OUTLOOK;

        let data = {
            description: encodeURI ? encodeURIComponent(description) : description,
            duration: duration,
            endDatetime: this.formatDate(endDatetime),
            location: encodeURI ? encodeURIComponent(location) : location,
            startDatetime: this.formatDate(startDatetime),
            title: encodeURI ? encodeURIComponent(title) : title
        };

        switch (type) {
            case ShareSites.GOOGLE:
                return this.googleShareUrl(data);

            case ShareSites.YAHOO:
                return this.yahooShareUrl(data);

            default:
                return this.buildShareFile(data);
        }
    }

    handleCalendarButtonClick(type, e) {
        //eslint-disable-next-line
        dataLayer.push({'calendarDownload': type, 'event':'calendarDownload'});

        e.preventDefault();
        let url = e.currentTarget.getAttribute('href');
        if (url.startsWith('BEGIN')) {
            let filename = 'tour-meeting.ics';
            let blob = new Blob([url], {
                type: 'text/calendar;charset=utf-8'
            });

            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', filename);
            this.hiddenLinkContainer.appendChild(link);
            link.click();
            this.hiddenLinkContainer.removeChild(link);
        } else {
            window.open(url, '_blank');
        }
    }

    escapeIcsString(dataString) {
        return typeof dataString === 'string'
            ? dataString
            .replace(/\n/g, '\\n')
            .replace(/;/g, '\\;')
            .replace(/,/g, '\\,')
            .replace(/"/g, '\\"')
            : '';
    }
    
    render() {
        return (
            <React.Fragment>
                <a
                    onClick={this.openModal}
                    id="addToCalendarButton"
                    className="rsvp-addToCalendar-openButton"
                    tabIndex="0"
                >
                    <i aria-hidden="true">+</i>
                    <span>{this.props.content.addToCalendarButton}</span>
                </a>
                <Modal
                    isOpen={this.state.isOpen}
                    shouldCloseOnOverlayClick={true}
                    timeout={300}
                    classNames={'rsvp-addToCalendar-modal'}
                    onRequestClose={this.closeModal}
                    modalID="calendar"
                    header={this.props.content.modalHeader}
                >
                    <div
                        className="rsvp-addToCalendar-hiddenLink"
                        ref={(el) => this.hiddenLinkContainer = el}
                    />
                    <a href={this.state.outlookCalendarUrl}
                       onClick={(e) => this.handleCalendarButtonClick('outlook', e)}>
                        <img src="/static/img/outlook_logo.png" alt="Outlook" width="60px"/>
                        <span>Outlook</span>
                    </a>
                    <a href={this.state.googleCalendarUrl}
                       onClick={(e) => this.handleCalendarButtonClick('google', e)}
                       target="_blank"
                       rel="noreferrer">
                        <img src="/static/img/google_logo.png"
                             alt="Google Calendar"
                             width="30px"
                             className="google-logo"/>
                        <span>Google</span>
                    </a>
                    <a href={this.state.iCalCalendarUrl}
                       onClick={(e) => this.handleCalendarButtonClick('iCal', e)}>
                        <img src="/static/img/apple_logo.png" alt="Apple Calendar logo" width="30px"
                             className="apple-logo"/>
                        <span>Apple Calendar</span>
                    </a>
                    <a href={this.state.yahooCalendarUrl}
                       onClick={(e) => this.handleCalendarButtonClick('yahoo', e)}
                       target="_blank"
                       rel="noreferrer">
                        <img src="/static/img/yahoo_logo.png" alt="Yahoo" width="30px"
                             className="yahoo-logo"/>
                        <span>Yahoo</span>
                    </a>
                </Modal>
            </React.Fragment>
        );
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.globals.addToCalendar'),
            publicMeeting: state.publicInvite.publicMeeting
        };
    }
}

AddToCalendar.propTypes = {
    content: PropTypes.shape({
        modalHeader: PropTypes.string.isRequired,
        addToCalendarButton: PropTypes.string.isRequired,
        meetingDetailsLabel: PropTypes.string.isRequired
    })
};

export default connect(AddToCalendar.mapStateToProps)(AddToCalendar);