import React from 'react';
import classnames from "classnames";
import './MeetingImage.scss';

export default class MeetingImage extends React.Component {
    render() {
        const rsvpMeetingImageClasses = classnames({
            "rsvp-meetingImage": true,
            "rsvp-meetingImage__Expired": this.props.isPast || false
        });

        return (
            <div className="rsvp-meetingImage-container">
                <div className={rsvpMeetingImageClasses} />
            </div>
        );
    }
}
