import React from 'react';
import objectPath from 'object-path';
import { connect } from 'react-redux';
import Linkify from 'react-linkify';

import './SoftBranding.scss';
import efLogo from '../../../../static/img/ef_logo_96968A.svg';

class SoftBranding extends React.Component {
    render() {
        return (
            <aside className="rsvp-softBrandingFooter rsvp-narrowContainer" role="complementary">
                <img className="rsvp-softBrandingFooter-efLogo"
                     src={efLogo}
                     alt="EF logo" />
                <p className="rsvp-softBrandingFooter-partnerDescription">
                    <Linkify properties={{target: '_blank'}}>
                        {this.props.content.partnerDescription}
                    </Linkify>
                </p>
            </aside>
        );
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.footer')
        };
    }
}

export default connect(SoftBranding.mapStateToProps)(SoftBranding);

