import objectPath from 'object-path';

import api from './../globals/services/api-request.js';
import { displayGlobalErrorModal } from '../globals/services/global-error/global-error-actions';
import { getCookie, setCookie } from './../globals/utils/cookie-util.js';

/**
 * Action Types
 */

export const actions = {
    LOAD_PUBLIC_MEETING_INIT: 'LOAD_PUBLIC_MEETING_INIT',
    LOAD_PUBLIC_MEETING_SUCCESS: 'LOAD_PUBLIC_MEETING_SUCCESS',
    LOAD_PUBLIC_MEETING_ERROR: 'LOAD_PUBLIC_MEETING_ERROR',
    SUBMIT_REPLY_INIT: 'SUBMIT_REPLY_INIT',
    SUBMIT_REPLY_SUCCESS: 'SUBMIT_REPLY_SUCCESS',
    SUBMIT_REPLY_ERROR: 'SUBMIT_REPLY_ERROR',
    SET_IS_ATTENDING: 'SET_IS_ATTENDING',
    UPDATE_MEETING_VIEWS_INIT: 'UPDATE_MEETING_VIEWS_INIT',
    SET_HAS_SEEN_MEETING: 'SET_HAS_SEEN_MEETING',
    UPDATE_MEETING_VIEWS_SUCCESS: 'UPDATE_MEETING_VIEWS_SUCCESS'
};

/**
 * Action Creators
 */

export function setIsAttending(isAttending) {
    return {
        type: actions.SET_IS_ATTENDING,
        isAttending
    };
}

/**
 * Async Actions
 */

/**
 * On the Public Meeting Page, this async action is called to get the public projection of the
 * meeting doc. This will not include a few fields as they contain sensitive data.
 *
 * @param meetingID - corresponding ref to the meeting doc
 */
export function loadPublicMeeting(meetingID) {
    return (dispatch) => {
        return dispatch({
            type: actions.LOAD_PUBLIC_MEETING_INIT,
            response: api.get('/meetings/' + meetingID + '/public')
                .then((res) => {
                    return dispatch({
                        type: actions.LOAD_PUBLIC_MEETING_SUCCESS,
                        publicMeeting: res.data
                    });
                },
                (error) => {
                    dispatch(displayGlobalErrorModal(error));
                    return Promise.reject({
                        error,
                        statusCode: objectPath.get(error, 'response.status')
                    });
                }
            )
        });
    };
}

/**
 * This async action is called on the public meeting page, in the collection form component. A user
 * will submit the form indicating that they wish to either RSVP for the meeting yes or no, and we
 * will store their data on the backend.
 *
 * @param meetingID
 * @param reply - The reply object that we will be sending to the back end and saving
 */
export function submitReply(meetingID, reply) {
    return (dispatch) => {
        return dispatch({
            type: actions.SUBMIT_REPLY_INIT,
            response: api.post('/meetings/' + meetingID + '/reply', {reply})
                .then(() => {
                    return dispatch({
                        type: actions.SUBMIT_REPLY_SUCCESS
                    });
                },
                (error) => {
                    dispatch(displayGlobalErrorModal(error));
                    return Promise.reject();
                }
            )
        });
    };
}

/**
 * Checks the cookies for the current session, and sees if one exists that pertains to the public
 * meeting being looked at. If a cookie has already been set (which will only occur once a public
 * meeting has been loaded), then the view will not be recorded. Otherwise, it will hit the API
 * and increment the views field
 *
 * @param req - needs to be passed in if server side rendered
 * @param res - needs to be passed in if server side rendered
 * @param meetingID
 */
export function updateViewsOnMeeting(req, res, meetingID) {
    let hasSeenMeeting = getCookie('hasSeenMeeting-' + meetingID, req);

    if (!hasSeenMeeting) {
        return (dispatch) => {
            return dispatch({
                type: actions.UPDATE_MEETING_VIEWS_INIT,
                response: api.put('/meetings/' + meetingID + '/views')
                    .then(() => {
                        setCookie('hasSeenMeeting-' + meetingID, true, {
                            //one year
                            maxAge: 365 * 24 * 60 * 60
                        });
                        
                        return dispatch({
                            type: actions.UPDATE_MEETING_VIEWS_SUCCESS
                        });
                    },
                    (error) => {
                        dispatch(displayGlobalErrorModal(error))
                    })
            });
        };
    } else {
        return {
            type: actions.SET_HAS_SEEN_MEETING
        };
    }
}