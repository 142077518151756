import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import './ManageMeeting.scss';

import { checkAuthentication } from './../globals/services/authentication/authentication-actions.js';
import { loadEditableMeeting, switchActivePanel } from './manage-meeting-actions.js';
import UserHeader from './UserHeader.jsx';
import ActionBar from './ActionBar.jsx';
import MainLayout from './../layouts/MainLayout.jsx';
import MainPanel from './../globals/components/main-panel/MainPanel.jsx';
import MeetingImage from './../globals/components/meeting-image/MeetingImage.jsx';
import InvitePanelFormik from './InvitePanelFormik.jsx';
import AttendingPanel from './AttendingPanel.jsx';
import InterestedPanel from './InterestedPanel.jsx';
import globals from './../globals/utils/global-constants.js';
import { getAttendingFamilyCount, getInterestedReplyCount } from './manage-meeting-reducer.js';
import MeetingOptions from './MeetingOptions.jsx';
import FatalErrorLayout from './../layouts/FatalErrorLayout.jsx';

class ManageMeeting extends React.Component {

    constructor(props) {
        super(props);

        this.getEditableMeeting = this.getEditableMeeting.bind(this);
        this.getViewsText = this.getViewsText.bind(this);
        this.getFamiliesText = this.getFamiliesText.bind(this);
        this.renderTabs = this.renderTabs.bind(this);
        this.renderFatalError = this.renderFatalError.bind(this);

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.setState({
            width: window.innerWidth
        });

        checkAuthentication().then(() => {
            this.getEditableMeeting();
        });
    }

    getEditableMeeting() {
        this.setState({
            isLoading: true,
            hasFatalError: false
        });

        let meetingId = this.props.meetingId;

        return this.props.loadEditableMeeting(meetingId).response
            .then(
                () => {
                    this.setState({
                        isLoading: false
                    });
                },
                (error) => {
                    this.setState({
                        isLoading: false,
                        hasFatalError: true,
                        fatalError: error,
                        errorStatusCode: objectPath.get(error, 'response.status', 500)
                    });
                }
            );
    }

    getViewsText() {
        let viewsText,
            viewCount = objectPath.get(this.props, 'editableMeeting.views', 0);

        if (!viewCount || viewCount === 0) {
            viewsText = this.props.content.noViews;
        } else if (viewCount === 1) {
            viewsText = this.props.content.view.replace('^viewCount^', viewCount);
        } else {
            viewsText = this.props.content.views.replace('^viewCount^', viewCount);
        }

        return viewsText;
    }

    getFamiliesText(familyCount) {
        let familiesText;

        if (!familyCount || familyCount === 0) {
            familiesText = this.props.content.noOne;
        } else if (familyCount === 1) {
            familiesText = familyCount + ' ' + this.props.content.family;
        } else {
            familiesText = familyCount + ' ' + this.props.content.families;
        }

        return familiesText;
    }

    render() {
        if (!this.state.isLoading) {
            return ( this.state.hasFatalError
                ? this.renderFatalError()

                : <MainLayout className="manageMeeting">
                    <Helmet>
                        <title>{this.props.content.pageTitle}</title>
                        <meta property="og:title" content={this.props.content.pageTitle} />
                    </Helmet>
                    <UserHeader />
                    <MeetingImage />
                    <ActionBar
                        meetingID={objectPath.get(this.props, 'editableMeeting.meetingID')}
                        groupTripId={this.props.groupTripId}
                    />
                    <MainPanel className="rsvp-wideContainer">
                        {this.renderTabs()}
                        {
                            this.props.activePanel === globals.manageMeeting.panelNames.INVITE &&
                            <InvitePanelFormik />
                        }
                        {
                            this.props.activePanel === globals.manageMeeting.panelNames.ATTENDING &&
                            <AttendingPanel
                                meetingTitle={objectPath.get(this.props, 'editableMeeting.title', '')}
                            />
                        }
                        {
                            this.props.activePanel === globals.manageMeeting.panelNames.INTERESTED &&
                            <InterestedPanel
                                meetingTitle={objectPath.get(this.props, 'editableMeeting.title', '')}
                            />
                        }
                    </MainPanel>
                    <MeetingOptions />
                </MainLayout>
            );
        } else {
            return null;
        }
    }

    renderTabs() {
        let attendingCount = objectPath.get(this.props, 'attendingFamilyCount', 0);
        let interestedCount = objectPath.get(this.props, 'interestedReplyCount', 0);
        let viewsText = this.getViewsText();
        let interestedFamiliesText = this.getFamiliesText(interestedCount);
        let attendingFamiliesText = this.getFamiliesText(attendingCount);

        //On mobile and desktop, we want to display these differently
        let breakType = this.state.width >= 800 ?
                        <span>&nbsp;/&nbsp; </span> : <br/> ;

        return (
            <div className="manageMeeting-panelTabs" role="tablist">
                <button
                    className={
                        'manageMeeting-panelTab' +
                        (this.props.activePanel === globals.manageMeeting.panelNames.INVITE ?
                        ' active' : '')
                    }
                    role="tab"
                    aria-selected={this.props.activePanel === globals.manageMeeting.panelNames.INVITE}
                    onClick={this.togglePanel.bind(this, globals.manageMeeting.panelNames.INVITE)}
                    id="invite-panel-tab"
                    type="button"
                    tabIndex="0"
                >
                    <strong> {this.props.content.inviteTab} {breakType} </strong>&nbsp;{viewsText}
                </button>
                <button
                    className={
                        'manageMeeting-panelTab' +
                        (this.props.activePanel === globals.manageMeeting.panelNames.ATTENDING ?
                        ' active' : '')
                    }
                    role="tab"
                    aria-selected={this.props.activePanel === globals.manageMeeting.panelNames.ATTENDING}
                    onClick={this.togglePanel.bind(this, globals.manageMeeting.panelNames.ATTENDING)}
                    id="attending-panel-tab"
                    type="button"
                    tabIndex="0"
                >
                    <strong>{this.props.content.attendingTab} {breakType} </strong>&nbsp;{attendingFamiliesText}
                </button>
                <button
                    className={
                        'manageMeeting-panelTab' +
                        (this.props.activePanel === globals.manageMeeting.panelNames.INTERESTED ?
                        ' active' : '')
                    }
                    role="tab"
                    aria-selected={this.props.activePanel === globals.manageMeeting.panelNames.INTERESTED}
                    onClick={this.togglePanel.bind(this, globals.manageMeeting.panelNames.INTERESTED)}
                    id="interested-panel-tab"
                    type="button"
                    tabIndex="0"
                >
                <strong>{this.props.content.interestedTab} {breakType} </strong>&nbsp;{interestedFamiliesText}
                </button>
            </div>
        );
    }

    renderFatalError() {
        let errorHeader, errorDescription;

        switch(this.state.errorStatusCode) {
            case 403:
                errorHeader = this.props.content.errorUnauthorized;
                errorDescription = this.props.content.errorUnauthorizedHelp;
                break;
            case 404:
                errorHeader = this.props.content.errorMeetingNotFound;
                errorDescription = this.props.content.errorMeetingNotFoundHelp;
                break;
            case 500:
            default:
                errorHeader = this.props.content.errorInternal;
                errorDescription = this.props.content.errorInternalHelp;
                break;
        }

        return (
            <FatalErrorLayout fatalError={this.state.fatalError}>
                <Helmet>
                    <title>{this.props.content.pageTitle}</title>
                    <meta property="og:title" content={this.props.content.pageTitle} />
                </Helmet>
                <h1>{errorHeader}</h1>
                <p>{errorDescription}</p>
            </FatalErrorLayout>
        );
    }

    togglePanel(panelName) {
        this.props.switchActivePanel(panelName);
    }
    
    static mapStateToProps(state) {
        return {
            activePanel: state.manageMeeting.activePanel,
            editableMeeting: state.manageMeeting.editableMeeting,
            attendingFamilyCount: getAttendingFamilyCount(state.manageMeeting),
            interestedReplyCount: getInterestedReplyCount(state.manageMeeting),
            content: objectPath.get(state, 'product.content.manageMeeting')
        };
    }
}

ManageMeeting.propTypes = {
    attendingFamilyCount: PropTypes.number,
    interestedReplyCount: PropTypes.number,
    groupTripId: PropTypes.string,
    meetingId: PropTypes.string,
    content: PropTypes.shape({
        pageTitle: PropTypes.string.isRequired,
        inviteTab: PropTypes.string.isRequired,
        attendingTab: PropTypes.string.isRequired,
        interestedTab: PropTypes.string.isRequired,
        view: PropTypes.string.isRequired,
        views: PropTypes.string.isRequired,
        noViews: PropTypes.string.isRequired,
        family: PropTypes.string.isRequired,
        families: PropTypes.string.isRequired,
        noOne: PropTypes.string.isRequired,
        errorUnauthorized: PropTypes.string.isRequired,
        errorUnauthorizedHelp: PropTypes.string.isRequired,
        errorMeetingNotFound: PropTypes.string.isRequired,
        errorMeetingNotFoundHelp: PropTypes.string.isRequired,
        errorInternal: PropTypes.string.isRequired,
        errorInternalHelp: PropTypes.string.isRequired
    })
};

const mapDispatchToProps = {
    checkAuthentication,
    switchActivePanel,
    loadEditableMeeting
};

export default connect(ManageMeeting.mapStateToProps, mapDispatchToProps)(ManageMeeting);
