import { actions } from './view-all-actions.js';

const initialState = {
    meetings: []
};

const viewAllReducer = (state, action) => {
    if (typeof state === 'undefined') {
        return initialState;
    }

    let newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case actions.LOAD_ALL_MEETINGS_SUCCESS:
            sortMeetingsByStartDate(action.meetings);
            newState.meetings = action.meetings;
            return newState;
        default:
            return newState;
    }
};

export const getNumberAttending = (meeting) => {
    return meeting.replies
        .filter(reply => reply.isAttending)
        .reduce((totalAttending, reply) => (totalAttending + reply.numOfAttendees), 0);
};

export const sortMeetingsByStartDate = (meetings) => {
    meetings.sort((o1, o2) => {
        return new Date(o1.startDate) - new Date(o2.startDate);
    });

    return meetings;
};

export default viewAllReducer;