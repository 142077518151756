import React from 'react';
import PropTypes from 'prop-types';

import './PageLoadingSpinner.scss';
import mainSpinner from '../../../../static/img/rsvp-spinner.gif';

export default class PageLoadingSpinner extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div
                className="rsvp-main-spinner-container"
                style={
                    (this.props.containerHeight ?
                        {height: this.props.containerHeight} : {}
                    )
                }
            >
                <div className="rsvp-main-spinner-cell">
                    <img className="rsvp-main-spinner"
                         style={{width: this.props.spinnerWidth || 'initial'}}
                         src={mainSpinner}
                    />
                </div>
            </div>
        );
    }
}

PageLoadingSpinner.propTypes = {
    containerHeight: PropTypes.string,
    spinnerWidth: PropTypes.string
};