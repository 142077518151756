import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import objectPath from 'object-path';
import { Formik } from 'formik';

import './CollectionForm.scss';
import xSvg from '../../static/img/x_B0B0A1.svg';

import { submitReply } from './public-invite-actions.js';
import { defineSchema } from './reply-schema.js';
import { browserHistory } from './../main/main-store.js';
import Modal from '../globals/components/modal/Modal.jsx';
import CollectionFields from './CollectionFields.jsx';

class CollectionForm extends React.Component {
    constructor(props) {
        super(props);

        this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
        this.getInitialValues = this.getInitialValues.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleCloseButtonClick() {
        //eslint-disable-next-line
        dataLayer.push({ 'modalClose': (this.props.isAttending ? "attending" : "interested"), 'event': 'modalClose' });

        this.props.onRequestClose();
    }

    getInitialValues() {
        return {
            parentFirstName: '',
            parentLastName: '',
            contactEmail: '',
            contactPhone: '',
            studentFirstName: '',
            studentLastName: '',
            studentEmail: '',
            studentPhone: '',
            numOfAttendees: this.props.isAttending ? 1 : 0
        };
    }

    handleSubmit(values, { setSubmitting }) {
        setSubmitting(true);

        let meetingID = this.props.meetingID;

        // Include general reply fields used by all products
        let reply = {
            studentFirstName: values.studentFirstName,
            studentLastName: values.studentLastName,
            contactEmail: values.contactEmail,
            contactPhone: values.contactPhone,
            numOfAttendees: values.numOfAttendees,
            isAttending: this.props.isAttending
        };

        // Add product-specific fields, only if they exist
        if (values.parentFirstName && values.parentFirstName !== '') {
            reply.parentFirstName = values.parentFirstName;
        }

        if (values.parentLastName && values.parentLastName !== '') {
            reply.parentLastName = values.parentLastName;
        }

        if (values.studentEmail && values.studentEmail !== '') {
            reply.studentEmail = values.studentEmail;
        }

        if (values.studentPhone && values.studentPhone !== '') {
            reply.studentPhone = values.studentPhone;
        }

        this.props.submitReply(meetingID, reply).response
            .then(() => {
                let thankYouUrl = '/' + this.props.meetingID + '/thank-you';

                //eslint-disable-next-line
                dataLayer.push({
                    'validSubmit': this.props.isAttending ? 'attending' : 'interested',
                    'event': 'validSubmit'
                });

                if (this.props.isAttending) {
                    thankYouUrl += '/?isAttending=true';
                }

                setSubmitting(false);

                if (this.props.status.isActive) {
                    browserHistory.push(thankYouUrl);
                }
                else {
                    this.props.updateSubmit();
                    this.props.onRequestClose();
                }
            });
    }

    render() {

        const renderCollectionForm = () => {
            let coppaDescriptionCopy = () => {
                if (this.props.content.displayValues.shouldHideCoppa) {
                    return '';
                } else if (!this.props.content.displayValues.shouldHideCoppa &&
                    this.props.isAttending) {
                    return this.props.content.yesCoppaDescription;
                } else if (!this.props.content.displayValues.shouldHideCoppa &&
                    !this.props.isAttending) {
                    return this.props.content.noCoppaDescription;
                } else {
                    return '';
                }
            };

            return (
                <Modal
                    isOpen={this.props.isCollectionModalOpen}
                    shouldCloseOnOverlayClick={false}
                    timeout={300}
                    classNames={'rsvp-modalForm collectionForm'}
                    onRequestClose={this.props.onRequestClose}
                    shouldHideXButton={true}
                    modalID={this.props.isAttending ? "attending" : "interested"}
                    unmountOnExit
                    header={
                        (this.props.isAttending ?
                            this.props.content.yesAttendingTitle :
                            this.props.content.notAttendingTitle)
                    }
                    description={
                        (this.props.isAttending ?
                            this.props.content.yesAttendingDescription.replace('^organizerName^',
                                this.props.organizer) :
                            this.props.content.notAttendingDescription)
                    }
                    coppaDescription={
                        coppaDescriptionCopy()
                    }
                >
                    <Formik
                        initialValues={this.getInitialValues()}
                        onSubmit={this.handleSubmit}
                        validationSchema={defineSchema(this.props.siteCode, this.props.content)}
                        validateOnChange={false}
                        validateOnBlur={true}
                    >
                        {(formikBag) => renderInnerForm(formikBag)}
                    </Formik>
                </Modal>
            );
        };

        const renderInnerForm = (formikBag) => {
            return (
                <form noValidate={true}>
                    <CollectionFields
                        formikBag={formikBag}
                    />
                    <fieldset className="rsvp-modalForm-submit">
                        <button
                            type="submit"
                            className={
                                'rsvp-modalForm-submitButton' +
                                (formikBag.isSubmitting ? ' submitting' : '')
                            }
                            onClick={formikBag.handleSubmit}
                            disabled={formikBag.isSubmitting}
                            tabIndex="0"
                        >
                            {!formikBag.isSubmitting ? this.props.content.submit : ''}&nbsp;
                        </button>
                    </fieldset>
                    <fieldset className="rsvp-modalForm-buttons">
                        <button
                            onClick={this.handleCloseButtonClick}
                            className="rsvp-modalForm-cancelButton"
                            type="button"
                            tabIndex="0"
                            aria-label={this.props.content.cancel}
                        >
                            <span className="rsvp-modalForm-cancel--mobile">
                                {this.props.content.cancel}
                            </span>
                            <img className="rsvp-modalForm-cancel--desktop" src={xSvg} alt="cancel" />
                        </button>
                        <button
                            type="submit"
                            className="rsvp-modalForm-doneButton"
                            onClick={formikBag.handleSubmit}
                            disabled={formikBag.isSubmitting}
                            tabIndex="-1"
                            aria-label={this.props.content.submit}
                        >
                            <span>{this.props.content.submit}</span>
                        </button>
                    </fieldset>
                </form>
            );
        };

        return renderCollectionForm();
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.publicInvite.rsvpForm'),
            isAttending: objectPath.get(state, 'publicInvite.isAttending'),
            status: objectPath.get(state, 'publicInvite.publicMeeting.status'),
            siteCode: objectPath.get(state, 'product.siteCode'),
            organizer: objectPath.get(state, 'publicInvite.publicMeeting.organizer')
        };
    }
}

const mapDispatchToProps = {
    submitReply
};

CollectionForm.propTypes = {
    content: PropTypes.shape({
        yesAttendingTitle: PropTypes.string.isRequired,
        yesAttendingDescription: PropTypes.string.isRequired,
        notAttendingTitle: PropTypes.string.isRequired,
        notAttendingDescription: PropTypes.string.isRequired,
        yesCoppaDescription: PropTypes.string.isRequired,
        noCoppaDescription: PropTypes.string.isRequired,
        submit: PropTypes.string.isRequired,
        cancel: PropTypes.string.isRequired,
        displayValues: PropTypes.shape({
            shouldHideCoppa: PropTypes.bool
        })
    }),
    meetingID: PropTypes.string.isRequired,
    isAttending: PropTypes.bool,
    onRequestClose: PropTypes.func,
    isCollectionModalOpen: PropTypes.bool,
    updateSubmit: PropTypes.func,
    siteCode: PropTypes.string,
    organizerName: PropTypes.string
};

export default connect(CollectionForm.mapStateToProps, mapDispatchToProps)(CollectionForm);