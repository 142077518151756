import * as yup from 'yup';

import regex from '../globals/utils/regular-expressions.js';

export const defineSchema = (siteCode, content) => {
    switch (siteCode) {
        case 'CST':
            return yup.object().shape({
                studentFirstName: yup.string().required(content.requiredErrorLabel),
                studentLastName: yup.string().required(content.requiredErrorLabel),
                contactEmail: yup.string().matches(regex.EMAIL, content.invalidErrorLabel)
                    .required(content.requiredErrorLabel),
                contactPhone: yup.string(),
                numOfAttendees: yup.number().required(content.requiredErrorLabel)
            });
        default:
            return yup.object().shape({
                studentFirstName: yup.string().required(content.requiredErrorLabel),
                studentLastName: yup.string().required(content.requiredErrorLabel),
                studentEmail: yup.string().matches(regex.EMAIL, content.invalidErrorLabel),
                studentPhone: yup.string(),
                parentFirstName: yup.string().required(content.requiredErrorLabel),
                parentLastName: yup.string().required(content.requiredErrorLabel),
                contactEmail: yup.string().matches(regex.EMAIL, content.invalidErrorLabel)
                    .required(content.requiredErrorLabel),
                contactPhone: yup.string(),
                numOfAttendees: yup.number().required(content.requiredErrorLabel)
            });
    }
};