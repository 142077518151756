import React from 'react';
import { connect } from 'react-redux';
import Favicon from 'react-favicon';

import './FatalErrorPage.scss';

import RollbarInit from './RollbarScript.js';
import WindowEventListeners from './WindowEventListeners.jsx';
import ErrorBoundary from './../globals/components/ErrorBoundary.jsx';
import MainFooter from './MainFooter.jsx';
import MainPanel from './../globals/components/main-panel/MainPanel.jsx';
import MeetingImage from './../globals/components/meeting-image/MeetingImage.jsx';
import ErrorModal from './ErrorModal.jsx';

import { scrollWindow, resizeWindow } from './../globals/services/window/window-actions.js';

class FatalErrorLayout extends React.Component {
    UNSAFE_componentWillMount() {
        RollbarInit();
    }

    render() {
        return (
            <React.Fragment>
                <WindowEventListeners />
                <Favicon url="https://www.eftours.com/favicon.ico" />
                <div className="rsvp-main">
                    <ErrorBoundary name="Layout Error Boundary">
                        <MeetingImage />
                        <MainPanel className="rsvp-mediumContainer fatalError-panel">
                            { this.props.children }
                        </MainPanel>
                    </ErrorBoundary>
                </div>

                <MainFooter />

                <ErrorModal />
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {
    scrollWindow,
    resizeWindow
};

export default connect(null, mapDispatchToProps)(FatalErrorLayout);