import { actions } from './public-invite-actions.js';
import { actions as shortLinkActions } from '../globals/services/shortLink/shortLink-actions.js';

const initialState = {
    publicMeeting: {}
};

const publicInviteReducer = (state, action) => {
    if (typeof state === 'undefined') {
        return initialState;
    }

    let newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case actions.LOAD_PUBLIC_MEETING_SUCCESS:
            newState.publicMeeting = action.publicMeeting;
            return newState;

        case actions.SET_IS_ATTENDING:
            newState.isAttending = action.isAttending;
            return newState;

        case shortLinkActions.SHORTEN_LINK_SUCCESS:
            newState.publicMeeting.shortLink = {
                id: action.id,
                link: action.link
            };
            return newState;

        default:
            return state;
    }
};

export default publicInviteReducer;