import React from 'react';
import objectPath from 'object-path';
import PropTypes from 'prop-types';

import './PagingTable.scss';

export default class PagingTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activePageIndex: 0,
            dataPages: [[]]
        };

        this.initTable = this.initTable.bind(this);
        this.changeActivePageIndex = this.changeActivePageIndex.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.initTable(this.props);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.initTable(newProps);
    }

    initTable(props) {
        let dataPages;

        if (props.data.length > 0) {
            dataPages = [];

            for (let i = 0; i < props.data.length; i += props.numberPerPage) {
                let dataPage = props.data.slice(i, i + props.numberPerPage);
                dataPages = dataPages.concat([dataPage]);
            }
        } else {
            // In case no data is passed, provide a blank page
            dataPages = [[]];
        }

        this.setState(Object.assign({}, this.state, {
            dataPages
        }));
    }

    changeActivePageIndex(index) {
        this.setState(Object.assign({}, this.state, {
            activePageIndex: index
        }));
    }

    render() {
        const renderTable = () => {
            return (
                <React.Fragment>
                    <div className="pagingTable-wrapper">
                        <table className={'pagingTable ' + (this.props.className || '')}>
                            {
                                renderTableHead()
                            }
                            {
                                this.state.dataPages.map((page, index) => {
                                    return renderTableBody(page, index)
                                })
                            }
                        </table>
                    </div>
                    {
                        renderTableControls()
                    }
                </React.Fragment>
            );
        };

        const renderTableHead = () => {
            return (
                <thead>
                    <tr>
                        {
                            this.props.columnConfig.map((column, index) => {
                                return <th key={'tableHeader-' + index}>
                                    {column.label}
                                </th>
                            })
                        }
                    </tr>
                </thead>
            )
        };

        const renderTableBody = (page, index) => {
            return (
                <tbody
                    key={'tableBody-' + index}
                    className={
                        this.state.activePageIndex !== index ? 'pagingTable-hidden' : ''
                    }
                >
                {
                    page.map((row, index) => {
                        // TODO: if reply is actually marked as "attending"
                        if (row) {
                            return renderTableRow(row, index);
                        }
                    })
                }
                </tbody>
            );
        };

        const renderTableRow = (row, index) => {
            return (
                <tr key={'tableRow-' + index}>
                    {
                        this.props.columnConfig.map((column, index) => {
                            return (
                                <td
                                    key={'tableData-' + index}
                                    className={(column.isBold ? 'pagingTable-column--bold' : '')}
                                >
                                    {objectPath.get(row, column.key)}
                                </td>
                            );
                        })
                    }
                </tr>
            )
        };

        const renderTableControls = () => {
            return this.state.dataPages.length > 1 ? (
                <div className="pagingTable-controls">
                    {
                        this.state.dataPages.map((page, index) => {
                            return <button
                                onClick={() => {this.changeActivePageIndex(index)}}
                                key={'table-pagingButton-' + index}
                                className={
                                    'pagingTable-button' +
                                    (this.state.activePageIndex === index ?
                                        ' pagingTable-button--active' : '')
                                }
                                type="button"
                            >
                                {(index + 1)}
                            </button>
                        })
                    }
                </div>
            ) : null
        };

        return renderTable();
    }
}

PagingTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    columnConfig: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        key: PropTypes.string,
        isBold: PropTypes.bool
    })).isRequired,
    numberPerPage: PropTypes.number.isRequired
};