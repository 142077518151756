import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import PropTypes from 'prop-types';

import './MainFooter.scss';

class MainFooter extends React.Component {
    render() {
        const copyrightText = this.insertCopyrightYear(this.props.content.copyright);
        return (
            <footer className="rsvp-mainFooter" role="contentinfo">
                <div className="rsvp-mainFooter-container">
                    <span className="rsvp-mainFooter-footerLeft"
                          dangerouslySetInnerHTML={{__html:copyrightText}}
                    />
                    <span className="rsvp-mainFooter-footerRight">
                        <a href="https://careers.ef.com" target="_blank" rel="noreferrer">
                            {this.props.content.careersLink}
                        </a>
                        &nbsp;|&nbsp;
                        <span dangerouslySetInnerHTML={{__html: this.props.content.legalLink}}>
                        </span>
                    </span>
                </div>
            </footer>
        );
    }

    insertCopyrightYear(copyright) {
        const year = (new Date()).getFullYear();
        return copyright.replace('^year^', year);
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.footer')
        };
    }
}

MainFooter.propTypes = {
    content: PropTypes.shape({
        copyright: PropTypes.string.isRequired,
        careersLink: PropTypes.string.isRequired,
        legalLink: PropTypes.string.isRequired
    })
};

export default connect(MainFooter.mapStateToProps)(MainFooter);