import { createSelector } from 'reselect';
import objectPath from 'object-path';

import globals from './../globals/utils/global-constants.js';
import { actions } from './manage-meeting-actions.js';
import { actions as shortLinkActions } from '../globals/services/shortLink/shortLink-actions.js';

const initialState = {
    editableMeeting: {},
    activePanel: globals.manageMeeting.panelNames.INVITE,
    isInviteFormDirty: false,
    isInviteEmailPromptShown: false,
    isInviteUrlPromptShown: false

};

const manageMeetingReducer = (state, action) => {
    if (typeof state === 'undefined') {
        return initialState;
    }

    let newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case actions.LOAD_EDITABLE_MEETING_SUCCESS:
            newState.editableMeeting = action.editableMeeting;
            newState.EmailReplyList = getRepliesByPanel(newState);
            return newState;
        case actions.SWITCH_ACTIVE_PANEL:
            newState.activePanel = action.newPanelName;
            newState.EmailReplyList = getRepliesByPanel(newState);
            return newState;
        case actions.UPDATE_EDITABLE_MEETING_SUCCESS:
            newState.editableMeeting = action.updatedEditableMeeting;
            return newState;
        case actions.CANCEL_MEETING_SUCCESS:
            newState.editableMeeting.status = action.status;
            return newState;
        case actions.UPDATE_MEETING_PREFERENCES_SUCCESS:
            newState.editableMeeting.meetingPreferences = action.meetingPreferences;
            return newState;
        case actions.INVITE_PANEL_FORM_SOILED:
            newState.isInviteFormDirty = true;
            return newState;
        case actions.INVITE_PANEL_FORM_CLEANED:
            newState.isInviteFormDirty = false;
            return newState;
        case actions.INVITE_PANEL_EMAIL_PROMPT_SHOWN:
            newState.isInviteEmailPromptShown = true;
            return newState;
        case actions.INVITE_PANEL_EMAIL_PROMPT_HIDDEN:
            newState.isInviteEmailPromptShown = false;
            return newState;
        case actions.INVITE_PANEL_URL_PROMPT_SHOWN:
            newState.isInviteUrlPromptShown = true;
            return newState;
        case actions.INVITE_PANEL_URL_PROMPT_HIDDEN:
            newState.isInviteUrlPromptShown = false;
            return newState;
        case actions.UPDATED_MEETING_EMAIL_LIST:
            newState.emailList = getRecipientEmails(state, action.parentFlag, action.studentFlag);
            return newState;

        case shortLinkActions.SHORTEN_LINK_SUCCESS:
            newState.editableMeeting.shortLink = {
                id: action.id,
                link: action.link
            };
            return newState;

        default:
            return state;
    }
};

const getReplies = state => objectPath.get(state, 'editableMeeting.replies', []);
const getStoreReplies = state => objectPath.get(state, 'EmailReplyList', []);

export const getRepliesByPanel = (state) => {
    let replies;
    switch (state.activePanel) {
        case 'attending-panel':
            replies = getAttendingReplies(state);
            break;

        case 'interested-panel':
            replies = getInterestedReplies(state);
            break;

        default:
            replies = getReplies(state);
            break;
    }
    return replies;
};

export const getRecipientEmails = (state, parentFlag, studentFlag) => {
    // need a factory to be able to use selector with multiple params
    const selectorFactory = createSelector(
        getStoreReplies,
        (replies) => {
            let tempArray = [];
            
            if (parentFlag) {
                tempArray = tempArray.concat(replies.map((reply) => reply.contactEmail))
            }
            if (studentFlag) {
                tempArray = tempArray.concat(replies.map((reply) => reply.studentEmail))
            }
            return tempArray;
        });

    return selectorFactory(state);
};

export const getContactEmails = createSelector(
    getStoreReplies,
    (replies) =>
        replies.map((reply) => reply.contactEmail)
);

export const getAttendingReplies = createSelector(
    getReplies,
    (replies) =>
        replies.filter((reply) => {
            return !!reply.isAttending;
        })
            .map((reply) => {
                return {
                    studentName: reply.studentFirstName + ' ' + reply.studentLastName,
                    studentPhone: reply.studentPhone,
                    studentEmail: reply.studentEmail,
                    parentName: reply.parentName,
                    contactPhone: reply.contactPhone,
                    contactEmail: reply.contactEmail,
                    numOfAttendees: reply.numOfAttendees
                }
            })
);

export const getInterestedReplies = createSelector(
    getReplies,
    (replies) =>
        replies.filter((reply) => {
            return !reply.isAttending;
        })
            .map((reply) => {
                return {
                    studentName: reply.studentFirstName + ' ' + reply.studentLastName,
                    studentPhone: reply.studentPhone,
                    studentEmail: reply.studentEmail,
                    parentName: reply.parentName,
                    contactPhone: reply.contactPhone,
                    contactEmail: reply.contactEmail
                }
            })
);

export const getAttendingFamilyCount = createSelector(
    getAttendingReplies,
    (attendingReplies) => attendingReplies.length
);

export const getAttendingIndividualCount = createSelector(
    getAttendingReplies,
    (attendingReplies) => attendingReplies.reduce(
        (totalAttending, reply) => (totalAttending + reply.numOfAttendees),
        0
    )
);

export const getAttendingContactEmails = createSelector(
    getAttendingReplies,
    (attendingReplies) => attendingReplies.map((reply) => reply.contactEmail)
);

export const getInterestedReplyCount = createSelector(
    getInterestedReplies,
    (interestedReplies) => interestedReplies.length
);

export const getInterestedContactEmails = createSelector(
    getInterestedReplies,
    (interestedReplies) => interestedReplies.map((reply) => reply.contactEmail)
);

export const getAllContactEmails = createSelector(
    getReplies,
    (replies) => replies.map((reply) => reply.contactEmail)
);

export default manageMeetingReducer;