import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import PropTypes from 'prop-types';

import './UserHeader.scss';

import { logoutEfuser, getIsAuthenticated } from '../globals/services/authentication/authentication-actions';
import { getSecureSiteOrigin } from '../globals/utils/url-util.js';

export class UserHeader extends React.Component {
    constructor(props) {
        super(props);

        this.logOut = this.logOut.bind(this);

        this.state = {
            brochureUrl: '',
            profileUrl: ''
        };
    }

    componentDidMount() {
        let secureSiteOrigin = getSecureSiteOrigin();

        let brochureUrl = secureSiteOrigin,
            profileUrl = secureSiteOrigin + '/personal/profile',
            isAuthenticated = getIsAuthenticated();

        this.setState({
            brochureUrl,
            profileUrl,
            isAuthenticated
        });
    }

    logOut() {
        this.props.logoutEfuser();
    }

    render() {
        let logoPath = this.props.windowWidth >= 768 ? this.props.content.logoDesktop : this.props.content.logoMobile;
        return (
            <div className="userHeader-container">
                <div className="rsvp-wideContainer">
                    <header className="userHeader" role="banner">
                        <a href={this.state.brochureUrl}>
                            <img
                                src={logoPath}
                                id="userHeaderLogo"
                                className="userHeader-logo"
                            />
                        </a>
                        {
                            this.state.isAuthenticated &&
                            <div className="userHeader-nav-wrapper">
                                <button
                                    className="userHeader-nav-openButton"
                                    type="button"
                                    tabIndex="0"
                                >
                                    {this.props.user.username}
                                </button>
                                <nav
                                    className="userHeader-nav"
                                    onClick={(event) => event.stopPropagation()}
                                    role="navigation"
                                >
                                    {!this.props.user.isAdmin &&
                                        <a href={this.state.profileUrl}>{this.props.content.myProfile}</a>
                                    }
                                    <a onClick={this.logOut}>{this.props.content.logOut}</a>
                                </nav>
                            </div>
                        }
                    </header>
                </div>
            </div>
        );
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.manageMeeting.userHeader'),
            user: objectPath.get(state, 'user'),
            windowWidth: state.window.width
        }
    }
}

const mapDispatchToProps = {
    logoutEfuser
};

export default connect(UserHeader.mapStateToProps, mapDispatchToProps)(UserHeader);

UserHeader.propTypes = {
    content: PropTypes.shape({
        logoMobile: PropTypes.string.isRequired,
        logoDesktop: PropTypes.string.isRequired,
        myProfile: PropTypes.string.isRequired,
        logOut: PropTypes.string.isRequired
    })
};