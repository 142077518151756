import React from 'react';
import { connect } from 'react-redux';
import Favicon from 'react-favicon';
import { Helmet } from 'react-helmet';
import objectPath from 'object-path';

import RollbarInit from './RollbarScript.js';
import WindowEventListeners from './WindowEventListeners.jsx';
import ErrorBoundary from '../globals/components/ErrorBoundary.jsx';
import ErrorModal from './ErrorModal.jsx';
import MainFooter from './MainFooter.jsx';
import { scrollWindow, resizeWindow } from '../globals/services/window/window-actions.js';
import rsvpGlobal from '../globals/utils/global-constants.js';

class MainLayout extends React.Component {
    UNSAFE_componentWillMount() {
        if (rsvpGlobal.ENVIRONMENT !== 'dev') {
            RollbarInit();
        }
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta property="og:locale" content={this.props.locale} />
                </Helmet>

                <WindowEventListeners />
                <Favicon url="https://www.eftours.com/favicon.ico" />
                <div className={'rsvp-main ' + (this.props.className || '')}>
                    <ErrorBoundary name="Layout Error Boundary">
                        { this.props.children }
                    </ErrorBoundary>
                </div>

                <MainFooter />

                <ErrorModal />
            </React.Fragment>
        );
    }

    static mapStateToProps(state) {
        return {
            locale: objectPath.get(state, 'product.locale', 'en-us'),
        };
    }
}

const mapDispatchToProps = {
    scrollWindow,
    resizeWindow
};

export default connect(MainLayout.mapStateToProps, mapDispatchToProps)(MainLayout);