/**
 * Action Types
 */
export const windowActions = {
    WINDOW_SCROLL: 'WINDOW_SCROLL',
    WINDOW_RESIZE: 'WINDOW_RESIZE',
    WINDOW_CLICK: 'WINDOW_CLICK'
};

/**
 * Action Creators
 */
export function scrollWindow(newScrollPosition) {
    return {
        type: windowActions.WINDOW_SCROLL,
        newScrollPosition
    };
}

export function resizeWindow(newWidth, newHeight) {
    return {
        type: windowActions.WINDOW_RESIZE,
        newWidth,
        newHeight
    };
}