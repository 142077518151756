import React from 'react';
import objectPath from 'object-path';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './MeetingCard.scss';

import { formatMeetingCardDate, formatMeetingCardDateShort, formatMeetingTime } from '../globals/utils/format-util';
import { getNumberAttending } from './view-all-reducer';


class MeetingCard extends React.Component {

    render() {
        const renderMeetingCard = () => {
            return (
                <div
                    className={'meetingCard' +
                        (objectPath.get(this.props, 'meeting.status.isActive') ? ' meetingCard--active': '')
                    }
                >
                    <a
                        className="meetingCard-link"
                        href={'/manage-meeting/' + this.props.groupTripId + '/' + this.props.meeting.meetingID}
                        key={this.props.meeting.meetingID}
                    >
                        {renderMeetingType()}
                        {renderMeetingDateTime()}
                        <hr />
                        {renderNumberAttending()}
                        {renderMeetingStatus()}
                    </a>
                </div>
            );
        };

        const renderMeetingType = () => {
            let className, label;

            switch(this.props.meeting.purpose){
                case 'other':
                    className = 'meetingCard-icon-preDeparture';
                    label = this.props.content.other;
                    break;
                case 'recruitment':
                default:
                    className = 'meetingCard-icon-recruitment';
                    label = this.props.content.enrollment;
                    break;
            }

            return (
                <div className="meetingCard-type">
                    <i className={className} />
                    <span className="meetingCard-type-label">{ label }</span>
                </div>
            );
        };

        const renderMeetingDateTime = () => {
            return (
                <React.Fragment>
                    <div className="meetingCard-dateTime">
                        {this.props.meeting.startDate ?
                            (
                                <React.Fragment>
                                    <p className="meetingCard-meetingDate">
                                        {this.props.isMobile ?
                                            formatMeetingCardDateShort(this.props.meeting.startDate) :
                                            formatMeetingCardDate(this.props.meeting.startDate)
                                        }
                                    </p>
                                    <p className="meetingCard-meetingTime">
                                        {formatMeetingTime(this.props.meeting.startDate, this.props.meeting.endDate)}
                                    </p>
                                </React.Fragment>
                            ) :
                            (
                                <p className="meetingCard-tbdDate">
                                    { this.props.content.dateTBD }
                                </p>
                            )
                        }
                    </div>
                </React.Fragment>
            );
        };

        const renderNumberAttending = () => {
            return (
                <p className="meetingCard-numberAttending">
                    { getNumberAttending(this.props.meeting) }
                    &nbsp;
                    { this.props.content.attending }
                </p>
            );
        };

        const renderMeetingStatus = () => {
            let statusText, statusClass;

            if (this.props.meeting.status.isCancelled) {
                statusText = this.props.content.cancelled;
                statusClass = ' cancelled';
            }
            else if (this.props.meeting.status.isPast) {
                statusText = this.props.content.past;
                statusClass = ' past';
            }
            else {
                statusText = this.props.content.active;
                statusClass = '';
            }

            return (
                <p className={'meetingCard-meetingStatus' + statusClass}>
                    {statusText}
                </p>
            );
        };

        return renderMeetingCard();
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.viewAll.meetingCard'),
            isMobile: objectPath.get(state, 'window.width', 0) < 768
        };
    }
}

MeetingCard.propTypes = {
    groupTripId: PropTypes.string,
    content: PropTypes.shape({
        enrollment: PropTypes.string.isRequired,
        other: PropTypes.string.isRequired,
        dateTBD: PropTypes.string.isRequired,
        attending: PropTypes.string.isRequired,
        active: PropTypes.string.isRequired,
        cancelled: PropTypes.string.isRequired,
        past: PropTypes.string.isRequired
    })
};

export default connect(MeetingCard.mapStateToProps)(MeetingCard);