import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import moment from 'moment';

const EN_US_CUSTOMIZATIONS = {
    longDateFormat: {
        '[meetingDate]': 'ddd, MMMM Do, YYYY',
        '[meetingCardDate]': 'LL',
        '[meetingCardDateShort]': 'MMM. DD, YYYY',
        '[meetingTime]': 'h:mm a'
    }
};

const EN_CA_CUSTOMIZATIONS = {
    longDateFormat: {
        '[meetingDate]': 'ddd, MMMM Do, YYYY',
        '[meetingCardDate]': 'LL',
        '[meetingCardDateShort]': 'MMM. DD, YYYY',
        '[meetingTime]': 'H:mm'
    }
};

const FR_CA_CUSTOMIZATIONS = {
    longDateFormat: {
        '[meetingDate]': '[Le] dddd LL',
        '[meetingCardDate]': 'LL',
        '[meetingCardDateShort]': 'DD MMM YYYY',
        '[meetingTime]': 'H:mm'
    }
};

class MomentConfig extends React.Component {

    UNSAFE_componentWillMount(){
        moment.updateLocale('en-us', EN_US_CUSTOMIZATIONS);
        moment.updateLocale('en-ca', EN_CA_CUSTOMIZATIONS);
        moment.updateLocale('fr-ca', FR_CA_CUSTOMIZATIONS);

        moment.locale(this.props.locale);
    }

    componentDidUpdate(prevProps){
        if (prevProps.locale !== this.props.locale){
            moment.locale(this.props.locale);
        }
    }

    render() {
        return null;
    }

    static mapStateToProps(state){
        return {
            locale: objectPath.get(state, 'product.locale', 'en-us'),
        };
    }
}

export default connect(MomentConfig.mapStateToProps)(MomentConfig);