import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import objectPath from 'object-path';
import PropTypes from 'prop-types';
import Clipboard from 'react-clipboard.js';
import './ShareOptions.scss';
import {
    FacebookShareButton, FacebookIcon,
    TwitterShareButton, TwitterIcon,
    EmailShareButton, EmailIcon
} from 'react-share';
import PageLoadingSpinner from '../page-loading-spinner/PageLoadingSpinner.jsx';
import { shortenLink } from '../../services/shortLink/shortLink-actions.js';

class ShareOptions extends React.Component {
    constructor(props) {
        super(props);

        this.getShareUrl = this.getShareUrl.bind(this);
        this.getShareDisplayUrl = this.getShareDisplayUrl.bind(this);
        this.onClipboardCopySuccess = this.onClipboardCopySuccess.bind(this);

        this.state = {
            publicShareUrl: '',
            showCopyTooltip: false
        };
    }

    componentDidMount() {
        if (!this.props.shortLinkService.hasLoadFailure && !this.props.shortLink) {
            this.props.shortenLink(this.props.meetingID, this.getShareUrl());
        }
    }

    getShareUrl() {
        return this.props.shortLink ?
            this.props.shortLink : window.location.origin + '/' + this.props.meetingID;
    }

    getShareDisplayUrl() {
        return this.props.shortLink ?
            this.props.shortLink : window.location.hostname + '/' + this.props.meetingID;
    }

    onClipboardCopySuccess() {
        //eslint-disable-next-line
        dataLayer.push({
            'share': this.props.sharedBy,
            'event': 'share'
        });

        if (!this.state.showCopyTooltip) {
            this.setState({
                showCopyTooltip: true
            });

            setTimeout(() => {
                this.setState({
                    showCopyTooltip: false
                });
            }, 1500);
        }
    }

    render() {
        let iconSize = this.props.showIconsOnly ? 40 : 75;
        let iconClass = this.props.showIconsOnly ? ' shareOptions-option--iconOnly': '';
        return (
            <React.Fragment>
            {
                this.props.shortLinkService.isLoading ?
                <PageLoadingSpinner
                    containerHeight={'initial'}
                    spinnerWidth="100px"
                /> :
                <React.Fragment>
                    { /* TODO: real copy for pre-filled share text */ }
                    <FacebookShareButton
                        className={'shareOptions-option' + iconClass}
                        url={this.getShareUrl()}
                        quote=""
                        hashtag=""
                    >
                        <FacebookIcon size={iconSize} round={true} />
                        <div>{this.props.content.facebookButton}</div>
                    </FacebookShareButton>
                    <TwitterShareButton
                        className={'shareOptions-option' + iconClass}
                        url={this.getShareUrl()}
                        title=""
                        via=""
                        hashtags={[]}
                    >
                        <TwitterIcon  size={iconSize} round={true} />
                        <div>{this.props.content.twitterButton}</div>
                    </TwitterShareButton>
                    <EmailShareButton
                        className={'shareOptions-option' + iconClass}
                        url={this.getShareUrl()}
                        subject={this.props.meetingTitle}
                        body=""
                    >
                        <EmailIcon  size={iconSize} round={true} />
                        <div>{this.props.content.emailButton}</div>
                    </EmailShareButton>
                    <div className="shareOptions-copyLink">
                        <span className="shareOptions-copyLink-text">
                            {this.getShareDisplayUrl()}
                        </span>
                        <Clipboard
                            onSuccess={this.onClipboardCopySuccess}
                            data-clipboard-text={this.getShareUrl()}
                            className="shareOptions-copyLink-button">
                            {this.props.content.copyUrlButton}
                        </Clipboard>
                        <CSSTransition
                            in={this.state.showCopyTooltip}
                            timeout={300}
                            classNames="shareOptions-copyLink-tooltip"
                            unmountOnExit
                        >
                            <div className="shareOptions-copyLink-tooltip" role="alert">
                                {this.props.content.copyUrlTooltip}
                            </div>
                        </CSSTransition>
                    </div>
                </React.Fragment>
            }
            </React.Fragment>
        );
    }

    static mapStateToProps(state) {
        return {
            content: objectPath.get(state, 'product.content.globals.shareOptions'),
            shortLinkService: state.shortLinkService
        };
    }
}

const mapDispatchToProps = {
    shortenLink
};

ShareOptions.propTypes = {
    meetingID: PropTypes.string.isRequired,
    meetingTitle: PropTypes.string.isRequired,
    sharedBy: PropTypes.string.isRequired,
    showIconsOnly: PropTypes.bool,
    content: PropTypes.shape({
        facebookButton: PropTypes.string.isRequired,
        twitterButton: PropTypes.string.isRequired,
        emailButton: PropTypes.string.isRequired,
        copyUrlButton: PropTypes.string.isRequired,
        copyUrlTooltip: PropTypes.string.isRequired
    }),
    shortenLink: PropTypes.func.isRequired,
    shortLink: PropTypes.string,
    shortLinkService : PropTypes.shape({
        isLoading: PropTypes.bool.isRequired,
        hasLoadFailure: PropTypes.bool.isRequired
    })
};

export default connect(ShareOptions.mapStateToProps, mapDispatchToProps)(ShareOptions);