import moment from 'moment';

export const formatMeetingDate = (startDate) => {
    return moment.utc(startDate).format('[meetingDate]');
};

export const formatMeetingCardDate = (startDate) => {
    return moment.utc(startDate).format('[meetingCardDate]');
};

export const formatMeetingCardDateShort = (startDate) => {
    return moment.utc(startDate).format('[meetingCardDateShort]');
};

export const formatMeetingTime = (startDate, endDate) => {
    let meetingStartTime, meetingEndTime, meetingTimeRange;

    meetingStartTime = moment.utc(startDate).format('[meetingTime]');
    meetingEndTime = moment.utc(endDate).format('[meetingTime]');

    meetingTimeRange = meetingStartTime + ' - ' + meetingEndTime;

    return meetingTimeRange;
};