import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import { CSVLink } from 'react-csv';

import './InterestedPanel.scss';
import printSvg from '../../static/img/print_1B9DDE.svg';
import excelSvg from '../../static/img/excel_1B9DDE.svg';
import emailSvg from '../../static/img/email_1B9DDE.svg';

import PagingTable from '../globals/components/paging-table/PagingTable.jsx';
import { getInterestedReplies, getInterestedReplyCount, getInterestedContactEmails }
    from './manage-meeting-reducer.js';
import EmailAll from '../globals/components/email-all/EmailAll.jsx'

class InterestedPanel extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const renderPrintStyles = () => {
            return (
                <style dangerouslySetInnerHTML={{__html: `
                  @media print {
                    @page {
                        size: landscape;
                        margin: 1.5cm 1cm;
                    }
                    html,
                    body {
                        min-width: 100%;
                        max-width: 100%;
                        width: 100%;
                        margin: 0;
                        padding: 0;
                    }
                    .rsvp-mainPanel {
                        width: 100%;
                        padding: 0;
                        margin: 0;
                    }
                    .interestedPanel::before {
                        content: 'Declined Responses – ` + this.props.meetingTitle + `';
                        display: block;
                        margin-bottom: 12pt;
                        font-size: 9pt;
                    }
                    .interestedPanel {
                        width: 100%;
                        margin: 0;
                        padding: 0;
                    }
                    .interestedPanel-topBar {
                        display: none;
                    }
                    thead > tr,
                    tr:nth-child(even) {
                        background-color: #F8F8F8;
                    }
                    .pagingTable-wrapper {
                        width: 100%;
                        overflow-x: visible;
                    }
                    .pagingTable {
                        width: 100%;
                        margin: 0;
                    }
                    .pagingTable thead {
                        display: table-header-group;
                    }
                    .pagingTable-controls {
                        display: none;
                    }
                    .pagingTable .pagingTable-hidden {
                        position: relative;
                        visibility: visible;
                    }
                    .pagingTable td,
                    .pagingTable th {
                        padding: 0.1cm 0.2cm;
                        font-size: 7pt;
                    }
                    .pagingTable td:first-child,
                    .pagingTable th:first-child {
                        padding-left: 16pt;
                    }
                    .pagingTable td:first-child::before {
                        content: '';
                        display: block;
                        width: 8pt;
                        height: 8pt;
                        margin: none;
                        padding: none;
                        top: 0.1cm;
                        left: 3pt;
                        background-color: white;
                        border: 1px solid #AAA;
                    }
                  }
                `}}/>
            );
        };

        const renderInterestedPanel = () => {
            return (
                <section
                    className="interestedPanel"
                    role="tabpanel"
                    aria-labelledby="interested-panel-tab"
                >
                    {
                        renderPrintStyles()
                    }
                    {
                        renderTopBar()
                    }
                    {
                        this.props.interestedReplies.length > 0 &&
                        renderTable()
                    }
                </section>
            );
        };

        const renderTopBar = () => {
            return (
                <div className="interestedPanel-topBar">
                    <header>
                        <h2 className="interestedPanel-topBar-total">
                            {this.props.content.totalInterested}&nbsp;
                            {this.props.interestedReplyCount}
                        </h2>
                    </header>
                    {
                        this.props.interestedReplies.length > 0 &&
                        <menu className="interestedPanel-topBar-menu">
                            <ReactToPrint
                                trigger={() =>
                                    <button
                                        type="button"
                                        id="interestedGroupPrintButton"
                                        className="groupActionButton"
                                        tabIndex="0"
                                    >
                                        <img src={printSvg}/>&nbsp;
                                        <span>{this.props.content.printButton}</span>
                                    </button>
                                }
                                content={() => this}
                            />
                            <CSVLink
                                id="interestedGroupDownload"
                                className="groupActionButton"
                                data={this.props.interestedReplies}
                                filename={'tour-meeting-declined-responses.csv'}
                                headers={this.props.content.tableColumns}
                                target="_blank"
                            >
                                <img src={excelSvg}/>&nbsp;
                                <span>{this.props.content.downloadTableButton}</span>
                            </CSVLink>
                            <EmailAll className="groupActionButton">
                                <img src={emailSvg}/>&nbsp;
                                {this.props.content.emailButton}
                            </EmailAll>
                        </menu>
                    }
                </div>
            );
        };

        const renderTable = () => {
            return (
                <PagingTable
                    className="interestedPanel-table"
                    data={this.props.interestedReplies}
                    columnConfig={this.props.content.tableColumns}
                    numberPerPage={20}
                />
            );
        };

        return renderInterestedPanel();
    }
    static mapStateToProps(state) {
        return {
            interestedReplies: getInterestedReplies(state.manageMeeting),
            interestedReplyCount: getInterestedReplyCount(state.manageMeeting),
            interestedContactEmails: getInterestedContactEmails(state.manageMeeting),
            content: objectPath.get(state, 'product.content.manageMeeting.interestedPanel')
        };
    }
}

InterestedPanel.propTypes = {
    meetingTitle: PropTypes.string,
    interestedReplies: PropTypes.arrayOf(PropTypes.shape({
        studentName: PropTypes.string,
        studentPhone: PropTypes.string,
        studentEmail: PropTypes.string,
        parentName: PropTypes.string,
        contactPhone: PropTypes.string,
        contactEmail: PropTypes.string
    })),
    interestedReplyCount: PropTypes.number,
    interestedContactEmails: PropTypes.arrayOf(PropTypes.string),
    content: PropTypes.shape({
        totalInterested: PropTypes.string.isRequired,
        printButton: PropTypes.string.isRequired,
        downloadTableButton: PropTypes.string.isRequired,
        emailButton: PropTypes.string.isRequired,
        tableColumns: PropTypes.arrayOf(PropTypes.object).isRequired
    })
};

export default connect(InterestedPanel.mapStateToProps)(InterestedPanel);